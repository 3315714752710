import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { removeTeamAccessRequest, getTeamAccessRequestsCsv } from '../../../services/team';
import { toast } from 'react-toastify';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import TableComponent from '../../../components/common/TableComponent';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.organizationTeams.total_pages,
      search: '',
      teams: [], // props.organizationTeams.entries,
      teamsAccessRequestsCount: 0, // props.organizationTeams.total_count,
      isCsvActionInProgress: false,
      selection: [],
    };
    this.handleOnClickRemoveTeamAccessRequest = this.handleOnClickRemoveTeamAccessRequest.bind(this);
    this.handleTeamAccessRequestSearch = this.handleTeamAccessRequestSearch.bind(this);
    this.handleTeamAccessRequestsFilterFetch = this.handleTeamAccessRequestsFilterFetch.bind(this);
    this.handleGetPaginationTeamAccessRequests = this.handleGetPaginationTeamAccessRequests.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
  }

  debounceSearch = debounce(input => this.handleTeamAccessRequestsFilterFetch(input), 500);

  handleTeamAccessRequestSearch = event => {
    event && event.preventDefault();
    const { search } = this.state;
    const input = event?.target?.value;

    // Note: validate no previous "search" value exists, to enable clear search
    if (!input && !search) {
      return;
    }

    this.debounceSearch(input);
  }

  handleTeamAccessRequestsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredTeams = await this.props.fetchTeamAccessRequests({ search: input });
    if (filteredTeams) {
      this.setState({ teams: filteredTeams.entries || [], search: input });
    }
  }

  handleGetPaginationTeamAccessRequests = async (page, pageSize, sorted) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchTeamAccessRequests({
      page: page + 1,
      pageSize,
      search,
      sort: sorted,
    });
    this.setState({ loading: false });
  }

  handleConfirmDelete = async (request, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveTeamAccessRequest}
            subtitle="You want to delete this access request?"
            itemToRemove={request}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveTeamAccessRequest = async (request, event) => {
    const { currentUser } = this.props;
    const response = await removeTeamAccessRequest({
      organization_id: currentUser?.organization_id,
      team_access_request_id: request.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginationTeamAccessRequests(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Team access request removed successfully.', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the team access request. Please try again.', toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getTeamAccessRequestsCsv({ organization_id: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  handleGetTableProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleGetTbodyProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleOnFetchData({ state }) {
    this.setState({ loading: true });
    this.handleGetPaginationTeamAccessRequests(state.page, state.pageSize, state.sorted);
  }

  handleSelection({ selection }) {
    this.setState({ selection });
  }

  render() {
    let {
      currentLocale,
      currentUser,
      isEducationOrganizationPersona,
      organizationTeams,
      pages,
      permittedRoles,
      teamAccessRequests,
      teamsAccessRequestsCount,
      isAccountAdmin,
    } = this.props;

    if (!isAccountAdmin) {
      return;
    }

    const { isCsvActionInProgress } = this.state;

    const columns = [{
      accessor: 'email',
      Header: 'Requester',
    }, {
      accessor: 'team_name',
      Header: 'Team requested',
    },  {
      accessor: 'workspace_name',
      Header: 'Team workspace',
    }, {
      id: 'created_at',
      Header: 'Created at',
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    },
    {
      id: 'action',
      Header: 'Grant access',
      sortable: false,
      accessor: w => (
        <a className='edit-cell' target="_blank" href={`/#/${currentLocale}/settings/access_management/teams/${w.team_id}`}>
          <i className="bi bi-box-arrow-up-right"></i>
        </a>
      )
    },
    {
      id: 'action2',
      Header: 'Reject access',
      sortable: false,
      accessor: w => (
        <div>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, w)}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }];

    return (
      <div className="mt-5" ref={ref => this.el = ref}>
        <div className="row mb-4 resource-metadata">
          <div className="col-md-4 column-width">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="h5">Team access requests</div>
                  <div className="d-flex">
                    <i className="bi bi-building me-1"></i>
                    <div className="card-count">{teamsAccessRequestsCount}</div>
                  </div>
                </div>
                <div>
                  Requests to join teams
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-actions-and-export d-flex justify-content-between">
          <div className="search">
            <input
              type="text"
              className="form-control search-input"
              onChange={this.handleTeamAccessRequestSearch}
              placeholder="Search team access requests" />
          </div>
          <div className="ms-2 button-actions">
            {isCsvActionInProgress ? (
              <button className="export export-csv teams" type="button" disabled>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                <span>Exporting...</span>
              </button>
            ) : (
              <Button
                handleClick={() => {
                  this.setState({ isCsvActionInProgress: true });
                  this.handleCsvExport();
                }}
                label="Export CSV"
                classes="export export-csv"
              />
            )}
          </div>
        </div>
        <div className="container-fluid table-details-section">
          <TableComponent
            columns={columns}
            data={teamAccessRequests}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            loading={this.state.loading}
            pages={pages}
            handleOnFetchData={this.handleOnFetchData}
            handleSelection={this.handleSelection}
            handleGetTableProps={this.handleGetTableProps}
            handleGetTbodyProps={this.handleGetTbodyProps}
            rowSelectionEnabled={false}
          />
        </div>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
