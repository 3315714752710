import {
  RECEIVE_AUTHENTICATED_USER,
  LOGOUT_CURRENT_USER,
} from '../actions/session_actions';

const sessionReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_AUTHENTICATED_USER:
      return Object.assign({}, {id: action.payload.user.id});
    case LOGOUT_CURRENT_USER:
      return Object.assign({}, {id: null});
    default:
      return state;
  };
};

export default sessionReducer;
