import React from 'react';

import FormFieldsContainer from './form_fields/form_fields_container';

const stringEllipis = string => string && string.length > 27
  ? string.substring(0, 24) + '...'
  : string;

class ChannelMetadata extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newChannelName: '',
      currentChannelId: '',
    };
  }

  componentDidMount() {}

  render() {
    const { currentUser, channel } = this.props;
    const key = channel && channel.id;

    return (
      <div className="channel-metadata-container">
        <div className='channel-metadata-container-all-channels'>
          <div className="channel-metadata-header py-3">Request details</div>
        </div>
        <FormFieldsContainer key={key} currentUser={currentUser} channel={channel} />
      </div>
    );
  }
}

export default ChannelMetadata;
