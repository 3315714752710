import React from 'react';
import { closeModal } from '../../actions/modal_actions';
import { connect } from 'react-redux';
import PublicChannelFormContainer from '../channel_form/public_channel_form_container';
import PrivateChannelFormContainer from '../channel_form/private_channel_form_container';
import JoinChannelFormContainer from '../channel_form/join_channel_form_container';
import WorkspaceFormContainer from '../workspace_form/workspace_form_container';

function Modal({ modal, closeModal }) {
  if (!modal) {
    return null;
  }

  const { name, props } = modal || {};
  let component;
  switch (name) {
    case 'new_public_channel':
      component = <PublicChannelFormContainer onClose={closeModal} {...props} />;
      break;
    case 'new_private_channel':
      component = <PrivateChannelFormContainer onClose={closeModal} {...props} />;
      break;
    case 'join_channel':
      component = <JoinChannelFormContainer onClose={closeModal} {...props} />;
      break;
    case 'new_workspace':
      component = <WorkspaceFormContainer onClose={closeModal} {...props} />;
      break;
    default:
      return null;
  }
  return (
    <div className="react-confirm-alert-overlay">
      <div className="react-confirm-alert" onClick={e => e.stopPropagation()}>
        {component}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    modal: state.ui.modal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => dispatch(closeModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
