import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Field } from 'react-final-form';
import { sleep } from '../../components/util/helpers';
import { upsertConfiguration, updateConfiguration } from '../../services/sso';
import { toast } from 'react-toastify';
import { toastifyConfiguration } from '../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

class SsoConfigurationPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      ssoConfiguration,
      currentUser,
      onPaneClose,
    } = this.props;
    const {
      idpSsoTargetUrl, idpEntityId, assertionBindingRadio,
      assertionConsumerServiceBindingDefault, assertionConsumerServiceBindingCustom,
      idpCertFingerprintAlgorithm, idpCertFingerprint, idpCert,
      idpCertRadio, nameIdentifierFormatRadio, nameIdentifierFormatDefault,
      nameIdentifierFormatCustom,
    } = values;

    const id =  ssoConfiguration && ssoConfiguration.id;
    const reqBody = {
      idp_sso_target_url: idpSsoTargetUrl,
      idp_entity_id: idpEntityId,
      assertion_consumer_service_binding: assertionBindingRadio === 'default'
        ? assertionConsumerServiceBindingDefault
        : assertionConsumerServiceBindingCustom,
      name_identifier_format: nameIdentifierFormatRadio === 'default'
        ? nameIdentifierFormatDefault
        : nameIdentifierFormatCustom,
      idp_cert_fingerprint: idpCertRadio === 'fingerprint'
        ? idpCertFingerprint : undefined,
      idp_cert_fingerprint_algorithm: idpCertRadio === 'fingerprint'
        ? idpCertFingerprintAlgorithm === 'sha-256'
          ? 'http://www.w3.org/2000/09/xmldsig#sha256'
          : 'http://www.w3.org/2000/09/xmldsig#sha1'
        : undefined,
      idp_cert: idpCertRadio === 'fingerprint' ? undefined : idpCert,
      organization_id: currentUser?.organization_id,
      id,
    }

    const configuration = ssoConfiguration && ssoConfiguration.is_not_setup
      ? await upsertConfiguration(reqBody)
      : await updateConfiguration(reqBody);

    if (configuration && !(configuration || {}).error) {
      onPaneClose();
      // 'true' will force the page to reload from the server
      // 'false', the default, will reload from cache
      location.reload(true);
    } else {
      toast.error(`Error ${id ? 'updating' : 'creating'} SSO configuration. Please try again.`, toastifyConfiguration({}));
    }
  }

  render() {
    const { ssoConfiguration } = this.props;
    const isSsoSetup = !ssoConfiguration.is_not_setup;
    const assertionConsumerServiceBindingDV = 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST';
    const nameIdentifierFormatDV = 'urn:oasis:names:tc:SAML:2.0:nameid-format:transient';
    const idpCertFingerprintAlgorithmSha256 = 'http://www.w3.org/2000/09/xmldsig#sha256';
    const idpCertFingerprintAlgorithmSha1 = 'http://www.w3.org/2000/09/xmldsig#sha1';

    const selectCustomOrDefaultRadioButtons = (fieldName, firstValue, secondValue) => (
      <div className="radio-group">
        <label>
          <Field name={fieldName} type="radio" value={firstValue} component="input" />{firstValue}
        </label>
        <label>
          <Field name={fieldName} type="radio" value={secondValue} component="input" />{secondValue}
        </label>
      </div>
    )
    return (
      <div className="sso-configuration-pane">
        <Form
          onSubmit={this.handleOnSubmit}
          initialValues={{
            assertionBindingRadio:
              ssoConfiguration &&
              ssoConfiguration.assertion_consumer_service_binding !==  assertionConsumerServiceBindingDV
                ? 'custom' : 'default',
            nameIdentifierFormatRadio:
              ssoConfiguration &&
              ssoConfiguration.name_identifier_format !== nameIdentifierFormatDV
                ? 'custom' : 'default',
            idpCertRadio: ssoConfiguration && ssoConfiguration.idp_cert ? 'x.509' : 'fingerprint',
            idpCertFingerprintAlgorithm:
              ssoConfiguration &&
              ssoConfiguration.idp_cert_fingerprint_algorithm === idpCertFingerprintAlgorithmSha1
                ? 'sha-1' : 'sha-256',
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <label className="field-label">Assertion Consumer Service Binding</label>
              {selectCustomOrDefaultRadioButtons('assertionBindingRadio', 'default', 'custom')}
              {values.assertionBindingRadio === 'default' ? (
                <Field name="assertionConsumerServiceBindingDefault" initialValue={assertionConsumerServiceBindingDV}>
                  {({ input, meta }) => (
                    <p className="field assertion-consumer-service-binding">
                      <input {...input} type="text" disabled />
                    </p>
                  )}
                </Field>
              ) : (
                <Field name="assertionConsumerServiceBindingCustom">
                  {({ input, meta }) => (
                    <p className="field assertion-consumer-service-binding">
                      <input {...input} type="text" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </p>
                  )}
                </Field>
              )}
              <label className="field-label">Name Identifier Format</label>
              {selectCustomOrDefaultRadioButtons('nameIdentifierFormatRadio', 'default', 'custom')}
              {values.nameIdentifierFormatRadio === 'default' ? (
                <Field name="nameIdentifierFormatDefault" initialValue={nameIdentifierFormatDV}>
                  {({ input, meta }) => (
                    <p className="field name-identifier-format">
                      <input {...input} type="text" disabled />
                    </p>
                  )}
                </Field>
              ) : (
                <Field name="nameIdentifierFormatCustom">
                  {({ input, meta }) => (
                    <p className="field name-identifier-format">
                      <input {...input} type="text" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </p>
                  )}
                </Field>
              )}
              <label className="field-label">IdP Entity Id</label>
              <Field
                name="idpEntityId"
                validate={required}
                initialValue={isSsoSetup ? ssoConfiguration.idp_entity_id : undefined}
              >
                {({ input, meta }) => (
                  <p className="field idp-entity-id">
                    <input {...input} type="text" />
                    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                  </p>
                )}
              </Field>
              <label className="field-label">IdP SSO Target Url</label>
              <Field
                name="idpSsoTargetUrl"
                validate={required}
                initialValue={isSsoSetup ? ssoConfiguration.idp_sso_target_url : undefined}
              >
                {({ input, meta }) => (
                  <p className="field idp-sso-target-url">
                    <input {...input} type="text" />
                    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                  </p>
                )}
              </Field>
              <label className="field-label section">Choose IdP Cert Fingerprint or X.509 Certificate</label>
              <div className="radio-group">
                <label>
                  <Field name="idpCertRadio" type="radio" value="fingerprint" component="input" />IdP Cert Fingerprint
                </label>
                <label>
                  <Field name="idpCertRadio" type="radio" value="x.509" component="input" />X.509 Certificate
                </label>
              </div>
              {values.idpCertRadio === 'fingerprint' ? (
                <Fragment>
                  <label className="field-label">IdP Cert Fingerprint</label>
                  <Field
                    name="idpCertFingerprint"
                    validate={required}
                    initialValue={isSsoSetup ? ssoConfiguration.idp_cert_fingerprint : undefined}
                  >
                    {({ input, meta }) => (
                      <p className="field idp-cert-fingerprint">
                        <input {...input} type="text" placeholder="idp cert fingerprint" />
                        {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                      </p>
                    )}
                  </Field>
                  <label className="field-label">IdP Cert Fingerprint Algorithm</label>
                  {selectCustomOrDefaultRadioButtons('idpCertFingerprintAlgorithm', 'sha-256', 'sha-1')}

                </Fragment>
              ) : (
                <Fragment>
                  <label className="field-label">IdP Cert</label>
                  <Field
                    name="idpCert"
                    type="textarea"
                    validate={required}
                    initialValue={isSsoSetup ? ssoConfiguration.idp_cert : undefined}
                  >
                    {({ input, meta }) => (
                      <p className="field idp-cert">
                        <textarea {...input}
                          type="text"
                          placeholder={`-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----`}
                        />
                        {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                      </p>
                    )}
                  </Field>
                </Fragment>
              )}
              <div className="submit">
                <input type="submit" name="commit" value="Submit" disabled={submitting} />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

SsoConfigurationPane.propTypes = {
};

export default SsoConfigurationPane;

const required = value => value ? undefined : 'Required';
