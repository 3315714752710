import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { removeWorkspaceAccessRequest, getWorkspaceAccessRequestsCsv } from '../../../services/workspace';
import { toast } from 'react-toastify';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import TableComponent from '../../../components/common/TableComponent';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.organizationWorkspaces.total_pages,
      search: '',
      workspaces: [], // props.organizationWorkspaces.entries,
      workspacesAccessRequestsCount: 0, // props.organizationWorkspaces.total_count,
      isCsvActionInProgress: false,
      selection: [],
    };
    this.handleOnClickRemoveWorkspaceAccessRequest = this.handleOnClickRemoveWorkspaceAccessRequest.bind(this);
    this.handleWorkspaceAccessRequestSearch = this.handleWorkspaceAccessRequestSearch.bind(this);
    this.handleWorkspaceAccessRequestsFilterFetch = this.handleWorkspaceAccessRequestsFilterFetch.bind(this);
    this.handleGetPaginationWorkspaceAccessRequests = this.handleGetPaginationWorkspaceAccessRequests.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
  }

  debounceSearch = debounce(input => this.handleWorkspaceAccessRequestsFilterFetch(input), 500);

  handleWorkspaceAccessRequestSearch = event => {
    event && event.preventDefault();
    const { search } = this.state;
    const input = event?.target?.value;

    // Note: validate no previous "search" value exists, to enable clear search
    if (!input && !search) {
      return;
    }

    this.debounceSearch(input);
  }

  handleWorkspaceAccessRequestsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredWorkspaces = await this.props.fetchWorkspaceAccessRequests({ search: input });
    if (filteredWorkspaces) {
      this.setState({ workspaces: filteredWorkspaces.entries || [], search: input });
    }
  }

  handleGetPaginationWorkspaceAccessRequests = async (page, pageSize, sorted) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchWorkspaceAccessRequests({
      page: page + 1,
      pageSize,
      search,
      sort: sorted,
    });
    this.setState({ loading: false });
  }

  handleConfirmDelete = async (request, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveWorkspaceAccessRequest}
            subtitle="You want to delete this access request?"
            itemToRemove={request}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveWorkspaceAccessRequest = async (request, event) => {
    const { currentUser } = this.props;
    const response = await removeWorkspaceAccessRequest({
      organization_id: currentUser?.organization_id,
      workspace_access_request_id: request.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginationWorkspaceAccessRequests(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Workspace access request removed successfully.', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the workspace access request. Please try again.', toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getWorkspaceAccessRequestsCsv({ organization_id: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  handleGetTableProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleGetTbodyProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleOnFetchData({ state }) {
    this.setState({ loading: true });
    this.handleGetPaginationWorkspaceAccessRequests(state.page, state.pageSize, state.sorted);
  }

  handleSelection({ selection }) {
    this.setState({ selection });
  }

  render() {
    let {
      currentLocale,
      currentUser,
      isEducationOrganizationPersona,
      organizationWorkspaces,
      pages,
      permittedRoles,
      workspaceAccessRequests,
      workspacesAccessRequestsCount,
      isAccountAdmin,
    } = this.props;

    if (!isAccountAdmin) {
      return;
    }

    const { isCsvActionInProgress } = this.state;

    const columns = [{
      accessor: 'email',
      Header: 'Requester',
    }, {
      accessor: 'workspace_name',
      Header: 'Workspace requested',
    }, {
      id: 'created_at',
      Header: 'Created at',
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    },
    {
      id: 'action',
      Header: 'Grant access',
      sortable: false,
      accessor: w => (
        <a className='edit-cell' target="_blank" href={`/#/${currentLocale}/settings/access_management/workspaces/${w.workspace_id}`}>
          <i className="bi bi-box-arrow-up-right"></i>
        </a>
      )
    },
    {
      id: 'action2',
      Header: 'Reject access',
      sortable: false,
      accessor: w => (
        <div>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, w)}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }];

    return (
      <div className="mt-5" ref={ref => this.el = ref}>
        <div className="row mb-4 resource-metadata">
          <div className="col-md-4 column-width">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="h5">Workspace access requests</div>
                  <div className="d-flex">
                    <i className="bi bi-building me-1"></i>
                    <div className="card-count">{workspacesAccessRequestsCount}</div>
                  </div>
                </div>
                <div>
                  Requests to join workspaces
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-actions-and-export d-flex justify-content-between">
          <div className="search">
            <input
              type="text"
              className="form-control search-input"
              onChange={this.handleWorkspaceAccessRequestSearch}
              placeholder="Search workspace access requests" />
          </div>
          <div className="ms-2 button-actions">
            {isCsvActionInProgress ? (
              <button className="export export-csv workspaces" type="button" disabled>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                <span>Exporting...</span>
              </button>
            ) : (
              <Button
                handleClick={() => {
                  this.setState({ isCsvActionInProgress: true });
                  this.handleCsvExport();
                }}
                label="Export CSV"
                classes="export export-csv"
              />
            )}
          </div>
        </div>
        <div className="container-fluid table-details-section">
          <TableComponent
            columns={columns}
            data={workspaceAccessRequests}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            loading={this.state.loading}
            pages={pages}
            handleOnFetchData={this.handleOnFetchData}
            handleSelection={this.handleSelection}
            handleGetTableProps={this.handleGetTableProps}
            handleGetTbodyProps={this.handleGetTbodyProps}
            rowSelectionEnabled={false}
          />
        </div>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
