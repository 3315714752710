import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import SlackLogo from 'images/slack_logo.png';
import 'react-toastify/dist/ReactToastify.css';

import { WhenFieldChanges } from '../../components/common/WhenFieldChanges';
import {
  sleep,
  composeValidators,
  isUrlValid,
  toastifyConfiguration,
  areArraysEqualSets,
} from '../../components/util/helpers';
import { upsertEndpoint, updateEndpoint } from '../../services/webhook';
import { toast } from 'react-toastify';

toast.configure();

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;
const EVENT_TYPES = [
  'user_mentioned',
];

const NATIVE_WEBHOOK_INTEGRATION_TYPE = 'native_webhook';
const SLACK_INTEGRATION_TYPE = 'slack';
const MICROSOFT_TEAMS_INTEGRATION_TYPE = 'microsoft_teams';

const INTEGRATION_TYPE_OPTIONS = [
  {
    value: SLACK_INTEGRATION_TYPE,
    label: 'Slack',
  },
  // {
  //   value: MICROSOFT_TEAMS_INTEGRATION_TYPE,
  //   label: 'Microsoft Teams',
  // },
  {
    value: NATIVE_WEBHOOK_INTEGRATION_TYPE,
    label: 'Native Webhook',
  },
];

class AddEndpointPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxValidationError: '',
      isSubmitting: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      currentUser,
      isEditMode,
      selectedEndpoint,
      onAddEndpointPaneClose,
      handleGetPaginatedEndpoints,
    } = this.props;
    const { targetUrl, events, integrationType } = values;

    if (!(events || []).length) {
      this.setState({ checkboxValidationError: 'At least one event required' });
      return;
    }

    const endpoint = isEditMode
      ? await updateEndpoint({
          organization_id: currentUser?.organization_id,
          id: selectedEndpoint.id,
          ...targetUrl && { target_url: targetUrl },
          ...events && { events },
          ...integrationType && { integration_type: integrationType },
        })
      : await upsertEndpoint({
          organization_id: currentUser?.organization_id,
          target_url: targetUrl,
          events,
          integration_type: integrationType,
        })

    if (!(endpoint || {}).error) {
      onAddEndpointPaneClose();
      await handleGetPaginatedEndpoints(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      const action = isEditMode ? 'updated' : 'added';
      toast.success(`Endpoint ${action} successfully!`, toastifyConfiguration({}));
    } else {
      const action = isEditMode ? 'updating' : 'adding';
      toast.error(`Error ${action} endpoint. Please try again.`, toastifyConfiguration({}));
    }
  }

  render() {
    const { isEditMode, selectedEndpoint } = this.props;
    const { checkboxValidationError, isSubmitting } = this.state;

    const { events } = selectedEndpoint;
    const checkAllInitialValue = events && areArraysEqualSets(events, EVENT_TYPES)
      ? [true]
      : undefined;
    const eventsInitialValue = events || undefined;
    const defaultIntegrationType =
      selectedEndpoint && selectedEndpoint.integration_type || NATIVE_WEBHOOK_INTEGRATION_TYPE;

    return (
      <div className="add-endpoint-pane">
        <Form
          onSubmit={(values) => {
            this.setState({ isSubmitting: true });
            this.handleOnSubmit(values)
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <label className="h5 mb-3">Integration Type</label>
              <div className="integration-types-container mb-5">
                {INTEGRATION_TYPE_OPTIONS.map(option => (
                  <Fragment key={option.value}>
                    <Field
                      name="integrationType"
                      component="input"
                      type="radio"
                      value={option.value}
                      className="custom-control-input"
                      id={option.value}
                      checked={
                        values.integrationType
                          ? values.integrationType === option.value
                          : defaultIntegrationType === option.value
                      }
                    />
                    <label
                      className="custom-control-label ms-2 me-4"
                      htmlFor={option.value}
                    >
                      {option.value === SLACK_INTEGRATION_TYPE
                        ? (
                          <div>
                            <img
                              className="me-2"
                              src={SlackLogo}
                              alt="Slack Logo"
                            />
                            <span>{option.label}</span>
                          </div>
                        ) : option.label}
                    </label>
                  </Fragment>
                ))}
              </div>
              {values.integrationType === SLACK_INTEGRATION_TYPE
                ? (
                  <div className="integration-instructions slack">
                    <div>Setup Instructions</div>
                    <ul>
                      <li>
                        <div>Slack Apps (<b>Slack's Recommended Webhook Option</b>)</div>
                        <div>https://api.slack.com/messaging/webhooks</div>
                        <div>This is the recommended integration option by Slack.</div>
                        <div>
                          However, this option does not support customizable channels,
                          so a user cannot elect to override the channel, in order
                          receive notifications directly for less noise and faster
                          response
                        </div>

                      </li>
                      <li>
                        <div>Slack's Incoming Webhooks (<b>More Customizable</b>)</div>
                        <div>https://slack.com/apps/A0F7XDUAZ-incoming-webhooks</div>
                        <div>This is the legacy integration option by Slack.</div>
                        <div>
                          It offers more personal customization options. A user
                          can elect to override the default channel, forwarding
                          their mentions directly to a channel of their choosing,
                          for less noise and faster response.
                        </div>
                        <div>
                          While this option is still supported be Slack, we
                          see it as a good practical means each user to stay
                          informed for the benefit of your customers and your
                          organization.
                        </div>
                      </li>
                    </ul>
                  </div>
                ) : null
              }
              <WhenFieldChanges
                field="checkAll"
                becomes={[true]}
                set="events"
                to={EVENT_TYPES}
              />
              <WhenFieldChanges
                field="checkAll"
                becomes={[]}
                set="events"
                to={undefined}
              />
              <Field
                name="targetUrl"
                validate={composeValidators(required, mustBeValidUrl)}
                initialValue={isEditMode ? selectedEndpoint.target_url : undefined}
              >
                {({ input, meta }) => (
                  <p className="field target-url">
                    <input {...input} type="text" className="form-control-input" placeholder="endpoint" />
                    {meta.error && meta.touched && (
                      <span className="field-error">{meta.error}</span>
                    )}
                  </p>
                )}
              </Field>
              <div className="checkbox-group">
                <div className="header">
                  <label>Select Events</label>
                  {checkboxValidationError && (
                    <span className="field-error">{checkboxValidationError}</span>
                  )}
                </div>
                <div className="options">
                  {/*
                    <label className="check-all">
                      <Field
                        name="checkAll"
                        component="input"
                        type="checkbox"
                        initialValue={checkAllInitialValue}
                        value={true}
                      />{' '}
                      All Events
                    </label>
                  */}
                  {EVENT_TYPES.map(event =>(
                    <label key={event}>
                      <Field
                        name="events"
                        component="input"
                        type="checkbox"
                        initialValue={eventsInitialValue}
                        validate={this.validate}
                        value={event}
                        />{' '}
                      {event}
                    </label>
                  ))}

                </div>
              </div>
              <div className="submit">
                {isSubmitting ? (
                  <div className="submit-button submitting d-flex align-items-center justify-content-center">
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Submitting...</span>
                  </div>
                ) : (
                  <input type="submit" name="commit" value="Submit" disabled={submitting} />
                )}
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

AddEndpointPane.propTypes = {
  handleGetPaginatedEndpoints: PropTypes.func,
};

export default AddEndpointPane;

// private

const required = value => value ? undefined : 'Required';
const mustBeValidUrl = value => isUrlValid(value) ? undefined : 'Must be a valid url';
