import React, { Fragment } from "react";
import PropTypes from "prop-types";


class FieldSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, required, selectHandler, options, customClass, multiple, disabled, value } = this.props;
    const className = `field-select ${customClass}`

    return (
      <div className={className}>
        {label && <label>{label}</label>}
        <select value={value} required={required} onChange={selectHandler} multiple={!!multiple} disabled={!!disabled}>
          {(options || []).map(option =>
            <option key={option.value} value={option.value} defaultValue={option.isDefaultValue}>{option.label}</option>
          )}
        </select>
      </div>
    );
  }
}

FieldSelect.propTypes = {
};

export default FieldSelect;
