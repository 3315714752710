import React from 'react';

import Container from 'react-bootstrap/Container';

import NoResultImage from 'images/no_result.png';

import ChannelListContainer from './channel_list/channel_list_container';
import ChannelContainer from './channel/channel_container';
import HorizontalNavigation from './horizontal_navigation/horizontal_navigation_container';

import withRouter from '../../util/with_router';

import { channelsFiltersQs, channelsTagsFiltersQs } from '../util/helpers';

class Main extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isChannelsLoading: true,
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    const channelsFilter = channelsFiltersQs(this.props.location.search);
    const channelsTagsFilter = channelsTagsFiltersQs(this.props.location.search);
    const channelsFetchParams = {
      ...(channelsFilter && { channelsFilter }),
      ...(channelsTagsFilter && { channelsTagsFilter }),
    }
    await this.props.fetchChannels(channelsFetchParams);

    if (this._isMounted) {
      this.setState({
        isChannelsLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentUser,
      workspaceId,
      currentLocale,
      actionCabelUrl,
      workspaces,
    } = this.props;
    const { isChannelsLoading } = this.state;

    const workspace = workspaces.filter(workspace => workspace.id === workspaceId)[0];

    if (!isChannelsLoading && !workspace) {
      return (
        <div className="main contactimmed-main vh-100 no-workspaces-found">
          <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale} />
          <Container fluid className="no-result vh-100 py-5 d-flex justify-content-center mt-4">
            <div className="workspace-not-found d-flex flex-column justify-content-center align-items-center">
              <div className="image-container mb-4">
                <img src={NoResultImage} width={100} />
              </div>
              <div className="header">Workspace Not Found</div>
              <div className="subheader text-center pt-3 mb-3">
                This workspace doesn't exist, or you haven't been granted access
                by your administrator.
              </div>
              <div className="subheader text-center pt-3 mb-3">
                If this is an access issue, please contact your adminstrator
                for support. In the mean time, you can view the workspace(s)
                that you currently have access to from the navigation menu.
              </div>
              <a
                href={`/#/${currentLocale}`}
                target="_blank"
                className="view-demo-dashboard-button mt-4 px-4 py-3"
              >
                Go To Dashboard
              </a>
            </div>
          </Container>
        </div>
      );
    }

    return (
      <div className="main contactimmed-main">
        <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale} />
        <div className="channels-section container-fluid px-0">
          <ChannelListContainer
            key={`channel-list-${workspaceId}`}
            currentUser={currentUser}
            currentLocale={currentLocale}
          />
          <ChannelContainer
            key={`channel-${workspaceId}`}
            currentUser={currentUser}
            isChannelsLoading={isChannelsLoading}
            currentLocale={currentLocale}
            actionCabelUrl={actionCabelUrl}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Main);
