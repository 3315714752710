import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import SlackLogo from 'images/slack_logo.png';
import 'react-table/react-table.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddEndpointPane from '../../../components/pane/AddEndpointPane';
import { getEndpoints, getEndpointsCsv, removeEndpoint } from '../../../services/webhook';
import { toast } from 'react-toastify';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import AboutPageDrawer from '../../AboutDrawers/WebhooksSettingsPage/AboutPageDrawer';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;
const NATIVE_WEBHOOK_INTEGRATION_TYPE = 'native_webhook';
const SLACK_INTEGRATION_TYPE = 'slack';

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.webhookEndpoints.total_pages,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedEndpoint: {},
      search: '',
      endpoints: [], // props.webhookEndpoints.entries,
      endpointsCount: 0, // props.webhookEndpoints.total_count,
      isAboutPageDrawerOpen: false,
      isCsvActionInProgress: false,
    };
    this.handleOnClickAddEndpoint = this.handleOnClickAddEndpoint.bind(this);
    this.onAddEndpointPaneClose = this.onAddEndpointPaneClose.bind(this);
    this.handleOnClickEditEndpoint = this.handleOnClickEditEndpoint.bind(this);
    this.handleWebhookEndpointsSearch = this.handleWebhookEndpointsSearch.bind(this);
    this.handleEndpointsFilterFetch = this.handleEndpointsFilterFetch.bind(this);
    this.handleGetPaginatedEndpoints = this.handleGetPaginatedEndpoints.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnClickEditEndpoint = this.handleOnClickEditEndpoint.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  handleOnClickOpenAboutPageDrawer = () => {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose = () => {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  handleWebhookEndpointsSearch = event => {
    const input = event.target.value
    this.handleEndpointsFilterFetch(input);
  }

  handleEndpointsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredEndpoints = await getEndpoints({ search: input, organization_id: currentUser?.organization_id });
    if (filteredEndpoints) {
      this.setState({ endpoints: filteredEndpoints.entries || [], search: input });
    }
  }

  handleGetPaginatedEndpoints = async (page, pageSize) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const { search } = this.state;
    const endpoints = await getEndpoints({ page: page + 1, page_size: pageSize, search, organization_id: currentUser?.organization_id });
    endpoints
      ? this.setState({ endpoints: endpoints.entries, pages: endpoints.total_pages, endpointsCount: endpoints.total_count, loading: false })
      : this.setState({ endpoints: [], pages: 0, loading: false });
  }

  handleOnClickAddEndpoint = () => {
    this.setState({ isPaneOpen: true, isEditMode: false });
  }

  onAddEndpointPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditEndpoint = endpoint => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedEndpoint: endpoint });
  }

  handleConfirmDelete = async (endpoint, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveEndpoint}
            subtitle="You want to delete this webhook endpoint?"
            itemToRemove={endpoint}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveEndpoint = async (endpoint, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const response = await removeEndpoint({
      organization_id: currentUser?.organization_id,
      id: endpoint.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedEndpoints(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Endpoint removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the endpoint. Please try again.', toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getEndpointsCsv({ organization_id: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  render() {
    let { currentUser } = this.props;
    let {
      endpoints,
      endpointsCount,
      isAddMode,
      isCsvActionInProgress,
      isEditMode,
      selectedEndpoint,
    } = this.state;

    const columns = [{
      Header: 'Endpoints',
      accessor: 'target_url',
      sortable: false,
    }, {
      Header: 'Workspace',
      accessor: 'workspace_name',
      sortable: false,
    }, {
      id: 'events',
      Header: 'Events',
      sortable: false,
      accessor: hook => <span className='webhook-events'>{hook.events.join(', ')}</span> // Custom cell components
    }, {
      id: 'integration',
      Header: 'Integration',
      sortable: false,
      accessor: hook =>
        <span className='webhook-integration'>
          {hook.integration_type === SLACK_INTEGRATION_TYPE
            ? (
              <div>
                <img
                  height={20}
                  width={20}
                  className="me-2"
                  src={SlackLogo}
                  alt="Slack Logo"
                />
                <span>Slack</span>
              </div>
            ) : hook.integration_type
          }
        </span>
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: hook => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditEndpoint.bind(this, hook)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, hook)}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }]

    return (
      <div className="settings webhook-endpoints container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Access</div>
          <div className="subheader">
            <div className="page">Webhook Integrations</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
            <div
              className="pro-tip-button"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                <div>{pluralize('Total Endpoint', endpointsCount, false)}</div>
                <div className="count-container">{endpointsCount}</div>
              </div>
            </div>
            <div className="summary-actions">
              <div className="search">
                <input
                  type="text"
                  className="form-control search-input"
                  onChange={this.handleWebhookEndpointsSearch}
                  placeholder="search endpoints" />
              </div>
              <div className="ms-2 button-actions">
                <Button handleClick={this.handleOnClickAddEndpoint} label="Add New" classes="primary small add-new" />
                {isCsvActionInProgress ? (
                  <button className="export small export-csv" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Exporting...</span>
                  </button>
                ) : (
                  <Button
                    handleClick={() => {
                      this.setState({ isCsvActionInProgress: true });
                      this.handleCsvExport();
                    }}
                    label="Export CSV"
                    classes="export small export-csv"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={endpoints}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={this.state.pages}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedEndpoints(state.page, state.pageSize);
              }}
            />
            <SlidingPane
              className='add-endpoint-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isPaneOpen }
              title={isEditMode
                ? 'Edit Endpoint - Webhook Integrations'
                : 'Add New Endpoint - Webhook Integrations'
              }
              width='60%'
              subtitle=''
              onRequestClose={this.onAddEndpointPaneClose}>
                <AddEndpointPane
                  handleGetPaginatedEndpoints={this.handleGetPaginatedEndpoints}
                  currentUser={currentUser}
                  onAddEndpointPaneClose={this.onAddEndpointPaneClose}
                  isEditMode={isEditMode}
                  selectedEndpoint={selectedEndpoint}
                />
            </SlidingPane>
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings webhooks-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
