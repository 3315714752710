import {connect} from 'react-redux';
import Channel from './channel';
import withRouter from '../../../util/with_router';

const msp = (state, ownProps) => {
  return {
    // currentUser: state.entities.currentUser,
    channelId: ownProps.match.params.channelId,
    location: ownProps.location,
    workspaceId: ownProps.match.params.workspaceId,
  };
};

const mdp = (dispatch) => {
  return {};
};

export default withRouter(connect(msp, mdp)(Channel));
