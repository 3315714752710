import React from 'react';
import PropTypes from 'prop-types';

class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { breadcrumbItems } = this.props;

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbItems?.map(item =>
            item.active ? (
              <li
                key={item.label}
                className="breadcrumb-item active"
                aria-current="page"
              >
                {item.label}
              </li>
            ) : (
              <li key={item.label}  className="breadcrumb-item">
                <a href={item.path}>
                  {item.label}
                </a>
              </li>
          ))}
        </ol>
      </nav>
    );
  }
}

Breadcrumb.propTypes = {};

Breadcrumb.defaultProps = {};

export default Breadcrumb;
