import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import {
  fetchTwoFactorSettingsData,
  enableTwoFactorAuth,
} from '../../../services/two_factor_settings_api';

const msp = (state) => {
  return {
    currentUser: state.entities.currentUser,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchTwoFactorSettingsData: () => fetchTwoFactorSettingsData(),
    enableTwoFactorAuth: ({ code, password }) =>
      enableTwoFactorAuth({ code, password}),
  };
};

export default withRouter(connect(msp, mdp)(Content));
