import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  composeValidators,
  generateRandomId,
  getReactFinalFormSelectValues,
  isEmailValid,
  sleep,
  toastifyConfiguration,
} from '../../components/util/helpers';
import Button from '../../components/common/Button';
import {
  upsertUser,
  updateUser,
  assignWorkspaceToUser,
  unassignWorkspaceFromUser,
  updateUserWorkspaceRole,
} from '../../services/organization';
import { getUserRoles } from '../../services/user';

toast.configure();

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

const WORKSPACE_MANAGER_VALUE = 'workspace_manager';
const WORKSPACE_MANAGER_LABEL = 'Manager';
const WORKSPACE_USER_VALUE = 'workspace_user';
const WORKSPACE_USER_LABEL = 'User';
const ACCOUNT_ADMIN_VALUE = 'account_admin';
const ACCOUNT_ADMIN_LABEL = 'Account Admin';
const ACCOUNT_USER_VALUE = 'account_user';
const ACCOUNT_USER_LABEL = 'Account User';

const PERMITTED_WORKSPACE_ROLES = [
  {
    value: WORKSPACE_MANAGER_VALUE,
    label: WORKSPACE_MANAGER_LABEL,
  },
  {
    value: WORKSPACE_USER_VALUE,
    label: WORKSPACE_USER_LABEL,
  },
];

const PERMITTED_ACCOUNT_LEVEL_ROLES = [
  {
    value: ACCOUNT_ADMIN_VALUE,
    label: ACCOUNT_ADMIN_LABEL,
  },
  {
    value: ACCOUNT_USER_VALUE,
    label: ACCOUNT_USER_LABEL,
  },
];

class AddUserPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: [],
      roleSelectedForWorkspace: {},
      isSubmitting: false,
      userAddedSuccessfully: false,
      showWorkspaceManagerAfterNewuserAdded: false,
      newUser: {},
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.setWorkspaceRoleById = this.setWorkspaceRoleById.bind(this);
    this.handleAddUserToWorkspace = this.handleAddUserToWorkspace.bind(this);
    this.handleUpdateWorkspaceRole = this.handleUpdateWorkspaceRole.bind(this);
    this.handleRemoveUserFromWorkspace = this.handleRemoveUserFromWorkspace.bind(this);
    this.handleGettingUserRoles = this.handleGettingUserRoles.bind(this);
    this.handleShowWorkspaceManagerAfterNewUserAdded = this.handleShowWorkspaceManagerAfterNewUserAdded.bind(this);
  }

  async componentDidMount() {
    await this.handleGettingUserRoles({});
  }

  handleGettingUserRoles = async ({ newUser }) => {
    const { currentUser, selectedUser } = this.props;
    const { organization_id } = currentUser;
    const { id } = newUser || selectedUser || {};

    const roles = id
      ? await getUserRoles({ organization_id, user_id: id })
      : undefined;
    if (roles && !(roles || {}).error) {
      this.setState({ userRoles: roles.entries });
    }
  }

  setWorkspaceRoleById = async (selectedOption, workspace) => {
    const { id } = workspace;
    const { userRoles, newUser } = this.state;
    this.setState(prevState => ({
      roleSelectedForWorkspace: {
        ...prevState.roleSelectedForWorkspace,
        [id]: selectedOption ? selectedOption.value : WORKSPACE_USER_VALUE,
      }
    }));

    if (
      doesUserBelongToWorkspace({ workspace, userRoles }) || newUser
    ) {
      await sleep(200);
      await this.handleUpdateWorkspaceRole(workspace.id);
    }
  }

  handleAddUserToWorkspace = async workspaceId => {
    const { currentUser, selectedUser } = this.props;
    const { roleSelectedForWorkspace, newUser } = this.state;
    const role = roleSelectedForWorkspace[workspaceId] || WORKSPACE_USER_VALUE;

    const { organization_id } = currentUser;

    const response = await assignWorkspaceToUser({
      organization_id,
      user_id: selectedUser?.id || newUser?.id,
      workspace_id: workspaceId,
      role,
    });

    if (!(response || {}).error) {
      this.setState({ userRoles: response.entries });
      toast.success(`User added to workspace successfully`, toastifyConfiguration({}));
    }

  }

  handleUpdateWorkspaceRole = async workspaceId => {
    const { currentUser, selectedUser } = this.props;
    const { roleSelectedForWorkspace, newUser } = this.state;
    const role = roleSelectedForWorkspace[workspaceId] || WORKSPACE_USER_VALUE;

    const response = await updateUserWorkspaceRole({
      organization_id: currentUser?.organization_id,
      user_id: selectedUser?.id || newUser?.id,
      workspace_id: workspaceId,
      role,
    });

    if (!(response || {}).error) {
      this.setState({ userRoles: response.entries });
      toast.success(`User workspace role updated successfully`, toastifyConfiguration({}));
    }
  }

  handleRemoveUserFromWorkspace = async workspaceId => {
    const { currentUser, selectedUser } = this.props;
    const { newUser } = this.state;

    const response = await unassignWorkspaceFromUser({
      organization_id: currentUser?.organization_id,
      user_id: selectedUser?.id || newUser?.id,
      workspace_id: workspaceId,
    });

    if (!(response || {}).error) {
      this.setState({ userRoles: response.entries });
      toast.success(`User removed from workspace successfully`, toastifyConfiguration({}));
    }

  }

  handleShowWorkspaceManagerAfterNewUserAdded = () => {
    this.setState({ showWorkspaceManagerAfterNewuserAdded: true });
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      currentUser,
      isEditMode,
      selectedUser,
      onAddUserPaneClose,
      handleGetPaginatedUsers,
    } = this.props;
    const { firstName, lastName, email, jobTitle, roles, teams } = values;
    const user = isEditMode
      ? await updateUser({
          organization_id: currentUser?.organization_id,
          user_id: selectedUser.id,
          ...firstName && { first_name: firstName },
          ...lastName && { last_name: lastName },
          ...email && { email: email },
          ...roles && { roles: [roles.value] },
          ...jobTitle && { job_title: jobTitle },
        })
      : await upsertUser({
          organization_id: currentUser?.organization_id,
          ...firstName && { first_name: firstName },
          ...lastName && { last_name: lastName },
          ...jobTitle && { job_title: jobTitle },
          email,
          roles: [roles?.value],
        })

    const userObject = user || {};
    if (userObject?.user_already_exists && userObject?.in_organization) {
      onAddUserPaneClose();
      toast.success(
        `User, ${user?.email}, already exists in your organization.`, toastifyConfiguration({})
      );
    } else if (userObject?.user_already_exists) {
      onAddUserPaneClose();
      toast.success(
        `User, ${user.email}, already exists on another account. A notification
        has been sent to them asking if they would like to switch to your organization`, toastifyConfiguration({})
      );
    } else if (userObject && !userObject?.error) {
      if (isEditMode) {
        onAddUserPaneClose();
      } else {
        await this.handleGettingUserRoles({ newUser: userObject });
        this.setState({ userAddedSuccessfully: true, newUser: userObject });
        this.setState({ isSubmitting: false });
      }
      await handleGetPaginatedUsers(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      const action = isEditMode ? 'updated' : 'added';
      toast.success(`User, ${user.email}, ${action} successfully.`, toastifyConfiguration({}));
    } else {
      const action = isEditMode ? 'updating' : 'adding';
      toast.error(`Error ${action} user, ${email}. Please try again.`, toastifyConfiguration({}));
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      organizationTeams,
      permittedRoles,
      isEditMode,
      selectedUser,
      workspacesAvailable,
      currentUser,
      canAddWorkspaces,
      showWorskpaceManagerInUserPane,
      showUserInfoInUserPane,
    } = this.props;

    const {
      isSubmitting,
      roleSelectedForWorkspace,
      userAddedSuccessfully,
      userRoles,
      showWorkspaceManagerAfterNewuserAdded,
    } = this.state;

    const { roles: authUserRoles } = currentUser;

    // const doTeamsExist = !!organizationTeams.length;
    const initialRolesValue = isEditMode
      ? selectedUser.roles.filter(role =>
        PERMITTED_ACCOUNT_LEVEL_ROLES.some(orgRole => orgRole.value === role.name)
      ).map(role => ({
        value: role.name, label: (PERMITTED_ACCOUNT_LEVEL_ROLES.filter(orgRole => orgRole.value === role.name).shift() || {}).label
      }))[0]
      : { value: ACCOUNT_USER_VALUE, label: ACCOUNT_USER_LABEL };

    // const initialEditTeamsValue =
    //   isEditMode &&
    //   !!(selectedUser.teams || []).length &&
    //   selectedUser.teams.map(team => ({
    //     value: team.id, label: team.name
    //   }));

    const userPrivilegesClass =
      showUserInfoInUserPane && showWorskpaceManagerInUserPane
        ? 'user-privileges-section partial-width'
        : 'user-privileges-section full-width';

    const userInfoClass =
      showUserInfoInUserPane && showWorskpaceManagerInUserPane
        ? 'user-info-section partial-width'
        : 'user-info-section full-width';

    return (
      <div className="add-user-pane">
        <Form
          onSubmit={(values) => {
            this.setState({ isSubmitting: true });
            this.handleOnSubmit(values);
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-fields-container">
                {showUserInfoInUserPane && !showWorkspaceManagerAfterNewuserAdded && (
                  <Fragment>
                    <div className={userInfoClass}>
                      <div className="header">User Info</div>
                      <div className="fields">
                        <div className="field-container">
                          <label>First name</label>
                          <Field name="firstName" initialValue={isEditMode ? selectedUser.first_name : undefined}>
                            {({ input, meta }) => (
                              <p className="field first-name">
                                <input {...input} type="text" placeholder="first name" />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                              </p>
                            )}
                          </Field>
                        </div>
                        <div className="field-container">
                          <label>Last name</label>
                          <Field name="lastName" initialValue={isEditMode ? selectedUser.last_name : undefined}>
                            {({ input, meta }) => (
                              <p className="field last-name">
                                <input {...input} type="text" placeholder="last name" />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                              </p>
                            )}
                          </Field>
                        </div>
                        <div className="field-container">
                          <label>Email</label>
                          <Field
                            name="email"
                            validate={composeValidators(required, mustBeValidEmail)}
                            initialValue={isEditMode ? selectedUser.email : undefined}
                          >
                            {({ input, meta }) => (
                              <p className="field email">
                                <input {...input} type="text" placeholder="email" />
                                {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                              </p>
                            )}
                          </Field>
                        </div>
                        <div className="field-container">
                          <label>Job title</label>
                          <Field name="jobTitle">
                            {({ input, meta }) => (
                              <div className="field jobTitle">
                                <input {...input} />
                                {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                              </div>
                            )}
                          </Field>
                        </div>
                        {isUserAccountAdmin({ userRoles: authUserRoles }) && (
                          <div className="field-container">
                            <label>Account role</label>
                            <Field
                              name="roles"
                              validate={requiredSelect}
                              initialValue={initialRolesValue}
                              component={ReactSelectAdapter}
                              options={PERMITTED_ACCOUNT_LEVEL_ROLES.map(role => ({
                                value: role.value, label: role.label
                              }))}
                            />
                          </div>
                        )}
                        {/* doTeamsExist ? <label>select a team (optional)</label> : <label>No Team Currently Exists</label> \*/}
                        {/*
                          <Field
                            name="teams"
                            component={ReactSelectAdapter}
                            disabled={!doTeamsExist}
                            initialValue={initialEditTeamsValue}
                            options={teamOptions(organizationTeams)}
                          />
                        */}
                      </div>
                      <div className="submit">
                        {isSubmitting ? (
                          <div className="submit-button submitting d-flex align-items-center justify-content-center">
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            <span>Submitting...</span>
                          </div>
                        ) : (
                          <input type="submit" name="commit" value="Submit" disabled={submitting} />
                        )}
                      </div>
                    </div>
                    {!showWorskpaceManagerInUserPane && userAddedSuccessfully && (
                      <div className="ms-4">
                        <span>
                          The user is added successfully. You can manage their
                          workspace access and permissions{' '}
                        </span>
                        <span
                          className="open-workspace-manager-after-user-added inline-link"
                          onClick={this.handleShowWorkspaceManagerAfterNewUserAdded}
                        >
                          here{' '}
                        </span>
                        <span>or via the "workspaces" column.</span>
                      </div>
                    )}
                  </Fragment>
                )}
                {(showWorskpaceManagerInUserPane || showWorkspaceManagerAfterNewuserAdded) && (
                  <Fragment>
                    {(isEditMode || userAddedSuccessfully) ? (
                      <div className={userPrivilegesClass}>
                        <div className="header">
                          Manage this user’s workspace role
                        </div>
                        {isUserAccountAdmin({ userRoles }) ? (
                          <div>
                            This user is an account admin, thus already has
                            full access
                          </div>
                        ) : (
                          <div className="fields">
                            <div className="column-labels-container container ps-0">
                              <div className="column-header col workspace">Workspace</div>
                              <div className="column-header col role">Role</div>
                              {canAddWorkspaces && (
                                <div className="column-header col action">Action</div>
                              )}
                            </div>
                            <div className="workspaces field-rows-container container ps-0">
                              {workspacesAvailable?.map(workspace => (
                                <div className="field-row" key={workspace.id}>
                                  <div className="field col workspace me-2">
                                    <div className="workspace-icon">
                                      <div className="workspace-initial">
                                        {workspace.name.charAt(0)}
                                      </div>
                                    </div>
                                    <div className="workspace-title">
                                      <div className="title">{workspace.name}</div>
                                      <div className="description">{workspace.description}</div>
                                    </div>
                                  </div>
                                  <div className="field col workspace-role">
                                    <Select
                                      value={getRoleSelected({ roleSelectedForWorkspace, workspace }) || initialWorkspaceRoleValue({ workspace, userRoles })}
                                      onChange={(option) => this.setWorkspaceRoleById(option, workspace)}
                                      options={PERMITTED_WORKSPACE_ROLES.map(role => ({
                                        value: role.value, label: role.label
                                      }))}
                                    />
                                  </div>
                                  {canAddWorkspaces && (
                                    <>
                                      {doesUserBelongToWorkspace({ workspace, userRoles })
                                        ? (
                                          <div className="field col action-container">
                                            <Button
                                              type="button"
                                              handleClick={() =>this.handleRemoveUserFromWorkspace(workspace.id)}
                                              label="Remove"
                                              classes="field action primary small remove-workspace"
                                            />
                                          </div>
                                        ) : (
                                          <div className="field col action-container">
                                            <Button
                                              type="button"
                                              handleClick={() =>this.handleAddUserToWorkspace(workspace.id)}
                                              label="Submit"
                                              classes="field action primary small add-workspace"
                                            />
                                          </div>
                                        )
                                      }
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ): (
                      <div className={userPrivilegesClass}>
                        <div className="header">
                          Manage this user’s workspace role{' '}
                        </div>
                        <div>
                          After the user is added successfully, you'll be able to
                          manage workspace permissions here.
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

AddUserPane.propTypes = {
  handleGetPaginatedUsers: PropTypes.func,
};

AddUserPane.defaultProps = {
  showUserInfoInUserPane: true,
  showWorskpaceManagerInUserPane: true,
};

export default AddUserPane;

// private

const ReactSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select">
    <Select {...input} {...rest} />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
)

const required = value => value ? undefined : 'Required';
const requiredSelect = value => value && value.value ? undefined : 'Required';
const mustBeValidEmail = value => isEmailValid(value) ? undefined : 'Must be a valid email';

const teamOptions = organizationTeams =>
  !!organizationTeams.length
    ? organizationTeams.map(team => ({ value: team.id, label: team.name }))
    : [];

const doesUserBelongToWorkspace = ({ workspace, userRoles }) => {
  const roleWorkspaceIds = userRoles.map(role => role.entity_id);
  return roleWorkspaceIds.indexOf(workspace.id) > -1;
};

const isUserAccountAdmin = ({ userRoles }) => {
  const roleWorkspaceNames = (userRoles || []).map(role => role.name);
  return roleWorkspaceNames.indexOf(ACCOUNT_ADMIN_VALUE) > -1 ;
};

const getRoleSelected = ({ roleSelectedForWorkspace, workspace }) => {
  const selectedRole = roleSelectedForWorkspace[workspace.id];
  if (selectedRole) {
    return PERMITTED_WORKSPACE_ROLES.filter(role => role.value === selectedRole)[0];
  }
};

const initialWorkspaceRoleValue = ({ workspace, userRoles }) => {
  const userRole = userRoles.find(role => role.entity_id === workspace.id);

  return userRole
    ? PERMITTED_WORKSPACE_ROLES.filter(role => role.value === userRole.name)[0]
    : { value: WORKSPACE_USER_VALUE, label: WORKSPACE_USER_LABEL };
};
