import React from 'react';
import NumberFormat from 'react-number-format';

// just under 100 billion :)
// and only because it's the limit for BigInt definition in the db
const MAX_VAL = 99000000000;

const OpportunityValueInput = ({
  onChange,
  value,
  defaultValue,
  name,
  className,
  placeholder,
}) => {
  return (
    <NumberFormat
      name={name}
      thousandSeparator={true}
      defaultValue={defaultValue}
      value={value}
      onValueChange={onChange}
      className={className}
      placeholder={placeholder}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (!floatValue) {
          return true;
        }
        return floatValue <= MAX_VAL;
      }}
    />
  );
};

export default OpportunityValueInput;
