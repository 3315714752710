import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import MultiSelector from '../../common/selectors/multi_selector';

// import { getTagsForChannels } from '../../../services/channel_api';
import { deliverCustomerFeedbackToContactImmed } from '../../../services/channel_api';

// import { statusOptions }  from '../../util/common';
import { toastifyConfiguration } from '../../util/helpers';

toast.configure();

class ReportAnIssueModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentDidMount() {
  }

  handleOnSubmit = async values => {
    const { name, existingFeaturePath, description } = values
    const { currentUser, onClose } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = await deliverCustomerFeedbackToContactImmed({
      organizationId,
      name,
      existingFeaturePath,
      description,
    });

    if (response && !response?.error) {
      onClose();
      toast.success(`Feedback submitted to ContactImmed successfully.`, toastifyConfiguration({}));
    } else {
      this.setState({ isSubmitting: false });
      toast.success(`Error submitting feedback.`, toastifyConfiguration({}));
    }

  }

  // handleGetTags = async () => {
  //   event.preventDefault();
  //   const { currentUser, workspaceId } = this.props;
  //   const organizationId = currentUser?.organization_id;
  //   const response = await getTagsForChannels({
  //     organizationId,
  //     workspaceId,
  //   });
  //
  //   this.setState({ tags: response?.entries || [] });
  // }

  render() {
    const { currentUser, location, onClose, onClick, channels } = this.props;
    const { isSubmitting } = this.state;

    const { search } = location;

    return (
      <div className="report-an-issue-modal custom-ui">
        <div className="header mb-4">
          Report an issue <span className="emphasis">or an opportunity</span>
        </div>
        <div className="filters-container d-flex row text-align-center mb-5">
        <Form
          onSubmit={(values) => {
            this.setState({ isSubmitting: true });
            this.handleOnSubmit(values);
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-fields-container">
                <div className="workspace-info-section">
                  <div className="fields">
                    <div className="field-container d-flex justify-content-center mb-3">
                      <Field
                        name="name"
                        validate={required}
                      >
                        {({ input, meta }) => (
                          <p className="field name">
                            <input {...input} type="text" placeholder="Subject" />
                            {meta.error && meta.touched && <div className="field-error text-start">{meta.error}</div>}
                          </p>
                        )}
                      </Field>
                    </div>
                    <div className="field-container d-flex justify-content-center mb-3">
                      <Field name="existingFeaturePath">
                        {({ input, meta }) => (
                          <p className="field url">
                            <input {...input} type="text" placeholder="Url where issue was encountered (optional)" />
                            {meta.error && meta.touched && <div className="field-error text-start">{meta.error}</div>}
                          </p>
                        )}
                      </Field>
                    </div>
                    <div className="field-container d-flex justify-content-center">
                      <Field name="description" validate={required}>
                        {({ input, meta }) => (
                          <p className="field description">
                            <textarea {...input} placeholder="Description" />
                            {meta.error && meta.touched && <div className="field-error text-start">{meta.error}</div>}
                          </p>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
                    <div className="submit">
                      {isSubmitting ? (
                        <div className="submit-button submitting d-flex align-items-center justify-content-center">
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          <span>Submitting...</span>
                        </div>
                      ) : (
                        <input type="submit" className="btn py-3 mb-4 submit-filters-button" name="commit" value="Submit" disabled={submitting} />
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn py-3 close-button"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
        </div>
      </div>
    );
  }
}

ReportAnIssueModal.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default ReportAnIssueModal;

// private

const required = value => value ? undefined : 'Required';
