import { combineReducers } from 'redux';
import usersReducer from './users_reducer';
import organizationReducer from './organization_reducer';
import channelsReducer from './channels_reducer';
import messagesReducer from './messages_reducer';
import userChannelsReducer from './user_channels_reducer';
import channelsSummaryReducer from './channels_summary_reducer';
import channelFavoritesReducer from './channel_favorites_reducer';
import workspacesReducer from './workspaces_reducer';
import currentUserReducer from './current_user_reducer';

const entitiesReducer = combineReducers({
  users: usersReducer,
  organization: organizationReducer,
  workspaces: workspacesReducer,
  channels: channelsReducer,
  channelsSummary: channelsSummaryReducer,
  channelFavorites: channelFavoritesReducer,
  messages: messagesReducer,
  userChannels: userChannelsReducer,
  currentUser: currentUserReducer,
});

export default entitiesReducer;
