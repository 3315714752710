import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Button from '../../components/common/Button';
import SsoConfigurationPane from './SsoConfigurationPane';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

class SsoConfigurationButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.onPaneClose = this.onPaneClose.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  handleOnClick = () => {
    this.setState({ isPaneOpen: true });
  }

  onPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  render() {
    const { isPaneOpen } = this.state;
    const { currentUser, ssoConfiguration } = this.props;
    const isSsoSetup = !ssoConfiguration.is_not_setup;
    return (
      <div className="sso-configuration-button" ref={ref => this.el = ref}>
        <Button
          handleClick={this.handleOnClick}
          label={isSsoSetup ? 'Edit Configuration' : 'Add Configuration'}
          classes="medium open-configuration"
        />
        <SlidingPane
          className='sso-configuration-pane'
          overlayClassName='sliding-pane-overlay'
          isOpen={isPaneOpen}
          title={isSsoSetup ? 'Edit Configuration' : 'Add Configuration'}
          width='60%'
          subtitle=''
          onRequestClose={this.onPaneClose}>
            <SsoConfigurationPane
              ssoConfiguration={ssoConfiguration}
              currentUser={currentUser}
              onPaneClose={this.onPaneClose}
              isEditMode={isSsoSetup}
            />
        </SlidingPane>
      </div>
    );
  }
}

SsoConfigurationButton.propTypes = {
};

export default SsoConfigurationButton;
