import React from 'react';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';
import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class Workspace extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUser, permittedWorkspaceRoles, currentLocale } = this.props;

    return (
      <div className='main contactimmed-main'>
        <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale} />
        <ContentContainer
          currentUser={currentUser}
          organizationWorkspaces={[]}
          permittedWorkspaceRoles={permittedWorkspaceRoles}
          currentLocale={currentLocale}
        />
      </div>
    );
  }
}

export default withRouter(Workspace);
