import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class AboutPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.props;
    return (
      <div className="about-page-drawer channels">
        <div className="content-container">
          <div className="header">
            About this page
          </div>
          <div className="content">
            <div className="section">
              <div className="subheader">Purpose:</div>
              <div className="section-content">
                View audit logs. Narrow down your search by utilizing
                the filter options. Results include the time of the change,
                user email and IP address, and the action taken. 
              </div>
            </div>
            <div className="section">
              <div className="subheader">To get started:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Filter options
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      User
                      <ul>
                        <li className="list-item first-sublist-item">
                          Select "Any" or the email of a user.
                        </li>
                        <li className="list-item first-sublist-item">
                          Type a user's email to refine your search.
                        </li>
                      </ul>
                    </li>
                    <li className="list-item">
                      Type
                      <ul>
                        <li className="list-item first-sublist-item">
                          CRUD action taken
                        </li>
                      </ul>
                    </li>
                    <li className="list-item">
                      Item
                      <ul>
                        <li className="list-item first-sublist-item">
                          The resource or model that was altered.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutPageDrawer;
