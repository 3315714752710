import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class ConfirmChooseEnterprisePlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleChoosePlan, onClose } = this.props;

    return (
      <div className="confirm-modal choose-plan custom-ui">
        <div className="mb-5">
          <i className="bi bi-gem"></i>
        </div>
        <div className="header mb-4">
          Exlore Our Enterprise Plan
        </div>
        <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
          <div className="subtitle cancel-enterprise-plan">
            <div className="mb-3">Interested in our Enterprise Plan?</div>
            <div>
              Click on <span className="contact-us-emphasis">Contact Us</span> for a personalized discussion.
              We'll tailor the plan to suit your unique needs. Let's collaborate
              for a solution that exceeds your expectations!
            </div>

          </div>
        </div>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-contact-us-button"
            onClick={() => {
              handleChoosePlan();
              onClose();
            }}
          >
            Contact Us
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            Back to plans
          </button>
        </div>
      </div>
    );
  }
}

ConfirmChooseEnterprisePlanModal.propTypes = {
  handleChoosePlan: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmChooseEnterprisePlanModal;
