import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from 'moment';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import { updateOrganizationInvitation } from '../../../services/organization_api';
import { toastifyConfiguration } from '../../../components/util/helpers';
import TableComponent from '../../../components/common/TableComponent';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

const tabPath = (locale, status) =>
  status
    ? `/#/${locale}/settings/access_management?access_tab=invitations&status=${status}`
    : `/#/${locale}/settings/access_management?access_tab=invitations`;

const isActiveClass = (paramValue, status) =>
  paramValue?.toLowerCase() === status ? 'active' : '';

const unclickableCellClass = isUnclickable =>
  isUnclickable ? 'unclickable-cell' : '';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      invitations: [],
      invitationsCount: 0,
      approved: undefined,
      revoked: undefined,
      selection: [],
    };
    this.handleOnClickAcceptInvitation = this.handleOnClickAcceptInvitation.bind(this);
    this.handleGetPaginatedInvitations = this.handleGetPaginatedInvitations.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmAction = this.handleConfirmAction.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  handleGetPaginatedInvitations = async (page, pageSize) => {
    const { currentUser, invitationStatus } = this.props;
    const { search } = this.state;
    await this.props.fetchInvitations({
      page: page + 1,
      pageSize: pageSize,
      status: invitationStatus,
      search,
    });
    this.setState({ loading: false });
  }

  handleConfirmAction = async (invitation, acceptStatus, event) => {
    event && event.preventDefault();
    const { approved, revoked, returnVoid } = acceptStatus;
    if (returnVoid) {
      return;
    }
    const action = approved ? 'approve' : 'revoke';
    this.setState({ approved, revoked });

    confirmAlert({
      customUI: ({ onClose }) => {``
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickAcceptInvitation}
            subtitle={`You want to ${action} this invitation?`}
            itemToRemove={invitation}
            confirmButtonText={`Yes, ${action} it!`}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickAcceptInvitation = async (invitation, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const { approved, revoked } = this.state;

    const action = approved ? 'approve' : revoked ? 'revoke' : 'update';

    const response = await updateOrganizationInvitation({
      organizationId: currentUser?.organization_id,
      id: invitation.id,
      approved,
      revoked,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedInvitations(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success(`Invitation ${action}d successfully!`, toastifyConfiguration({}));
    } else {
      toast.error(`Error ${action}ing the invitation. Please try again.`, toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getUsersCsv({ organization_id: currentUser?.organization_id });
  }

  handleOnFetchData({ state }) {
    this.setState({ loading: true });
    this.handleGetPaginatedInvitations(state.page, state.pageSize);
  }

  handleSelection({ selection }) {
    this.setState({ selection });
  }

  render() {
    let {
      currentUser,
      invitations,
      currentLocale,
      invitationsCount,
      pages,
      invitationStatus,
    } = this.props;

    const columns = [{
      accessor: 'email',
      Header: 'User',
      sortable: false,
    }, {
      accessor: 'inviter_email',
      Header: 'Invited by',
      sortable: false,
    }, {
      id: 'created_at',
      Header: 'Invited on',
      sortable: false,
      accessor: invitation => <span>{moment(invitation.created_at).utc().format('YYYY-MM-DD')}</span>
    },  {
      id: 'accept',
      Header: 'Approve invitation',
      sortable: false,
      accessor: invitation => (
        <div>
          <span
            className={`edit-cell accept-invitation-cell ${unclickableCellClass(!invitation.accepted || invitation.revoked || invitation.approved)}`}
            onClick={this.handleConfirmAction.bind(this, invitation, { approved: true, returnVoid: !invitation.accepted || invitation.approved || invitation.revoked })}
          >
            {invitation.approved
              ? 'Already approved'
              : invitation.accepted
              ? 'Approve User'
              : invitation.revoked
              ? 'Already revoked'
              : invitation.accepted === 'false'
              ? 'User declined'
              : 'Pending user acceptance'}
          </span>
        </div>
      )
    }, {
      id: 'approved_by',
      accessor: invitation => invitation.approved_by || 'N/A',
      Header: 'Approved by',
      sortable: false,
    }, {
      id: 'reject',
      Header: 'Revoke invitation',
      sortable: false,
      accessor: invitation => (
        <div>
          <span
            className={`remove-cell ${unclickableCellClass(invitation.approved || invitation.revoked)}`}
            onClick={this.handleConfirmAction.bind(this, invitation, { revoked: true, returnVoid: invitation.approved || invitation.revoked })}
          >
            {invitation.approved
              ? 'User already approved'
              : invitation.revoked
              ? 'Already revoked'
              : 'Revoke'}
          </span>
        </div>
      )
    }, {
      id: 'revoked_by',
      accessor: invitation => invitation.revoked_by || 'N/A',
      Header: 'Revoked by',
      sortable: false,
    }]

    return (
      <div className="mt-5" ref={ref => this.el = ref}>
        <div className="row mb-4 resource-metadata">
          <div className="col-md-4 column-width user-invitations">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="h5">Invitations</div>
                  <div className="d-flex">
                    <i className="bi bi-envelope me-1"></i>
                    <div className="card-count">{invitationsCount}</div>
                  </div>
                </div>
                <div>
                  Invitations to join your account from another account
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ul className="nav nav-tabs user-invitation-tabs mt-4">
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus)}`} aria-current="page" href={tabPath(currentLocale)}>
                All
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'pending')}`} aria-current="page" href={tabPath(currentLocale, 'pending')}>
                Pending
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'accepted')}`} href={tabPath(currentLocale, 'accepted')}>
                Awaiting your final approval
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'rejected')}`} href={tabPath(currentLocale, 'rejected')}>
                Rejected by invitee
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'approved')}`} href={tabPath(currentLocale, 'approved')}>
                Approved
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'revoked')}`} href={tabPath(currentLocale, 'revoked')}>
                Revoked
              </a>
            </li>
          </ul>
          <div className="container-fluid table-details-section">
            <TableComponent
              columns={columns}
              data={invitations}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={pages}
              handleOnFetchData={this.handleOnFetchData}
              handleSelection={this.handleSelection}
            />
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;
