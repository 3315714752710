import { RECEIVE_AUTHENTICATED_USER } from '../actions/session_actions';
import { RECEIVE_USERS } from '../actions/user_actions';

const usersReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_AUTHENTICATED_USER:
      return Object.assign({}, state, {[action.payload.user.id]: action.payload.user});
    case RECEIVE_USERS:
      return action.users;
    default:
      return state;
  };
};

export default usersReducer;
