import React from 'react';
import { withStyles, makeStyles} from '@material-ui/core/styles';
import MaterialUiTooltip, { tooltipClasses } from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

// const useStyles = makeStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#fff',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(14),
//     border: 'initial',
//     boxShadow: `0 ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(75)} rgba(0, 0, 0, 0.23)`,
//     fontWeight: 400,
//   },
// }));
//
// function HtmlTooltip(props) {
//   const classes = useStyles();
//
//   return <MaterialUiTooltip classes={classes} {...props} />;
// }

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: props => '#fff',
    color: props => props.color || 'rgba(0, 0, 0, 0.87)',
    maxWidth: props => props.maxwidth || 220,
    fontSize: props => theme.typography.pxToRem(props.fontSize || 14),
    border: 'initial',
    boxShadow: `0 ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(75)} rgba(0, 0, 0, 0.23)`,
    fontWeight: 400,
    borderRadius: theme.typography.pxToRem(8),
  },
}))(MaterialUiTooltip);

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      titleContent,
      children,
      maxwidth,
      fontSize,
      color
    } = this.props;

    return (
      <HtmlTooltip
        title={titleContent}
        maxwidth={maxwidth}
        fontSize={fontSize}
        color={color}
        // open={true}
      >
        <span className="tooltip-child-container">{children}</span>
      </HtmlTooltip>
    );
  }
}

Tooltip.propTypes = {};

Tooltip.defaultProps = {
 titleContent: 'Tooltip'
};

export default Tooltip;
