import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import {
  fetchOrganizationTeams,
  fetchOrganizationTeamAccessRequests,
} from '../../../actions/organization_actions';
import {
  isEducationOrganizationPersona,
} from '../../../reducers/selectors';

const msp = (state, ownProps) => {
  const { entities } = state;
  return {
    currentUser: entities.currentUser,
    teams: entities.organization.teams,
    teamAccessRequests: entities.organization.teamAccessRequests,
    pages: entities.organization.pages || 1,
    teamsAccessRequestsCount: entities.organization.teamAccessRequestsCount || 0,
    permittedTeamRoles: ownProps.permittedTeamRoles,
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const teamId = ownProps.match.params.teamId;

  return {
    fetchTeams: ({ page, pageSize, search, sort }) =>
      dispatch(fetchOrganizationTeams({
        organizationId,
        page,
        pageSize,
        search,
        sort,
      })
    ),
    fetchTeamAccessRequests: ({ page, pageSize, search, sort }) =>
      dispatch(fetchOrganizationTeamAccessRequests({
        organizationId,
        page,
        pageSize,
        search,
        sort,
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
