import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class NoDataAvailableWidgetState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { currentLocale, message } = this.props;

    return (
      <Fragment>
        <div className="no-data-available">
          {message || 'No data available for this widget'}
        </div>
      </Fragment>
    );
  }
}

NoDataAvailableWidgetState.propTypes = {};

export default NoDataAvailableWidgetState;
