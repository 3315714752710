import React from 'react';
import BaseCreatableMultiSelector from '../base_creatable_multi_selector';
import BaseMultiSelector from '../base_multi_selector';

const TagsSelector = ({
  onChange,
  onInputChange,
  selectedOption,
  defaultValue,
  options,
  name,
  className,
  placeholder,
  styleOptions,
  isCreatable = true,
}) => {
  if (isCreatable) {
    return (
      <BaseCreatableMultiSelector
        name={name}
        options={options}
        defaultValue={defaultValue}
        value={selectedOption}
        onChange={onChange}
        onInputChange={onInputChange}
        className={className}
        placeholder={placeholder}
        styleOptions={styleOptions}
      />
    );
  }

  return (
    <BaseMultiSelector
      name={name}
      options={options}
      defaultValue={defaultValue}
      value={selectedOption}
      onChange={onChange}
      onInputChange={onInputChange}
      className={className}
      placeholder={placeholder}
      styleOptions={styleOptions}
    />
  );

};

export default TagsSelector;
