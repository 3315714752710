import * as messageAPI from '../services/message_api';

export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const receiveMessages = (messages) => {
  return {
    type: RECEIVE_MESSAGES,
    messages
  };
};

export const receiveMessage = (message) => ({
  type: RECEIVE_MESSAGE,
  message
});

export const removeMessage = (message) => ({
  type: REMOVE_MESSAGE,
  message
});

export const fetchMessages = ({
  organizationId,
  workspaceId,
  channelId,
  pageSize,
}) => (dispatch) => {
  return messageAPI.fetchMessages({
    organizationId,
    workspaceId,
    channelId,
    pageSize,
  }).then(res => dispatch(receiveMessages(res)));
};

export const fetchMessage = ({
  organizationId,
  workspaceId,
  id,
}) => (dispatch) => {
  return messageAPI.fetchMessages({
    organizationId,
    workspaceId,
    id,
  }).then(res => dispatch(receiveMessage(res)));
};

export const createMessage = ({
  organizationId,
  workspaceId,
  message,
}) => (dispatch) => {
  return messageAPI.createMessage({
    organizationId,
    workspaceId,
    message,
  }).then(res => dispatch(receiveMessage(res)));
};

export const deleteMessage = ({
  organizationId,
  workspaceId,
  channelId,
  messageId,
}) => (dispatch) => {
  return messageAPI.removeMessage({
    organizationId,
    workspaceId,
    channelId,
    messageId,
  }).then(res => dispatch(removeMessage(res)));
};
