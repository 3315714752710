import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class AboutPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.props;
    return (
      <div className="about-page-drawer channels">
        <div className="content-container">
          <div className="header">
            About this page
          </div>
          <div className="content">
            <div className="section">
              <div className="subheader">Purpose:</div>
              <div className="section-content">
                Manage teams. Teams are a great way to group coworkers by
                area of responsibility and ownership to mirror your
                organizational structure. Requests can be assigned to teams
                in order to surface the customer voice quickly to the team(s)
                most equipped to collaborate.
              </div>
            </div>
            <div className="section">
              <div className="subheader">To get started:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Create teams
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Add team information
                    </li>
                    <li className="list-item">
                      Select workspace(s) the team can colloborate in
                    </li>
                    <li className="list-item">
                      Add tags that are associated with the team.
                      <ul>
                        <li className="list-item first-sublist-item">
                          Tags can be used to assign teams to Customer Requests
                        </li>
                        <li className="list-item first-sublist-item">
                          New tags can be created by typing them in the
                          input field
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div className="underline subsection-header mb-2">
                  Add members to teams
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Click into the team
                    </li>
                    <li className="list-item">
                      Enter the email of team member to add them as a member.
                      <ul>
                        <li className="list-item first-sublist-item">
                          Team member must already have a user account. Be
                          sure to add new team members in user settings.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutPageDrawer;
