// export async function getUserChannels({ organizationId }) {
//   const url = `/api/organizations/${organizationId}/user_channels`
//
//   const response = await fetch(url, {
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json',
//       'X-CSRF-Token': Rails.csrfToken()
//     },
//   }).then((response) => {
//     if (response.ok) {
//       return response.json();
//     }
//   })
//   .then((data) => {
//     return data;
//   });
//   return response;
// }
//
// export async function getUserChannel({ organizationId, userChannel }) {
//   const url = `/api/organizations/${organizationId}/user_channels`
//
//   const response = await fetch(url, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json',
//       'X-CSRF-Token': Rails.csrfToken()
//     },
//     body: JSON.stringify({ userChannel }),
//   }).then((response) => {
//     if (response.ok) {
//       return response.json();
//     }
//   })
//   .then((data) => {
//     return data;
//   });
//   return response;
// }



export const fetchUserChannelsApi = ({ organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/user_channels?workspace_id=${workspaceId}`
  });
};

export const createUserChannelApi = ({ userChannel, organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/user_channels/`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: { userChannel, workspace_id: workspaceId }
  });
};
