export const RECEIVE_ORGANIZATION = 'RECEIVE_ORGANIZATION';
export const RECEIVE_ORGANIZATION_USERS = 'RECEIVE_ORGANIZATION_USERS';
export const RECEIVE_ORGANIZATION_TEAMS = 'RECEIVE_ORGANIZATION_TEAMS';
export const RECEIVE_ORGANIZATION_TEAM = 'RECEIVE_ORGANIZATION_TEAM';
export const RECEIVE_ORGANIZATION_WORKSPACES = 'RECEIVE_ORGANIZATION_WORKSPACES';
export const RECEIVE_ORGANIZATION_WORKSPACE_ACCESS_REQUESTS = 'RECEIVE_ORGANIZATION_WORKSPACE_ACCESS_REQUESTS';
export const RECEIVE_ORGANIZATION_TEAM_ACCESS_REQUESTS = 'RECEIVE_ORGANIZATION_TEAM_ACCESS_REQUESTS';
export const RECEIVE_ORGANIZATION_TEAMS_WITHIN_USER_WORKSPACES = 'RECEIVE_ORGANIZATION_TEAMS_WITHIN_USER_WORKSPACES';
export const RECEIVE_ORGANIZATION_WORKSPACE = 'RECEIVE_ORGANIZATION_WORKSPACE';
export const RECEIVE_ORGANIZATION_TAGS_BY_TYPE = 'RECEIVE_ORGANIZATION_TAGS_BY_TYPE';
export const RECEIVE_ORGANIZATION_CUSTOMER_TAGS = 'RECEIVE_ORGANIZATION_CUSTOMER_TAGS';
export const RECEIVE_ORGANIZATION_USER_MENTIONS = 'RECEIVE_ORGANIZATION_USER_MENTIONS';
export const RECEIVE_ORGANIZATION_WORKSPACE_WEBHOOKS = 'RECEIVE_ORGANIZATION_WORKSPACE_WEBHOOKS';
export const RECEIVE_ORGANIZATION_INVITATIONS = 'RECEIVE_ORGANIZATION_INVITATIONS';
export const RECEIVE_ORGANIZATION_REQUESTS = 'RECEIVE_ORGANIZATION_REQUESTS';

import * as organizationAPI from '../services/organization_api';

export const receiveOrganization = (organization) => {
  return {
    type: RECEIVE_ORGANIZATION,
    organization
  };
};

export const receiveOrganizationUsers = (organizationUsers) => {
  return {
    type: RECEIVE_ORGANIZATION_USERS,
    organizationUsers,
  };
};

export const receiveOrganizationTeams = (organizationTeams) => {
  return {
    type: RECEIVE_ORGANIZATION_TEAMS,
    organizationTeams,
  };
};

export const receiveOrganizationTeam = (organizationTeam) => {
  return {
    type: RECEIVE_ORGANIZATION_TEAM,
    organizationTeam,
  };
};

export const receiveOrganizationWorkspaces = (organizationWorkspaces) => {
  return {
    type: RECEIVE_ORGANIZATION_WORKSPACES,
    organizationWorkspaces,
  };
};

export const receiveOrganizationWorkspaceAccessRequests = (
  organizationWorkspaceAccessRequests,
) => {
  return {
    type: RECEIVE_ORGANIZATION_WORKSPACE_ACCESS_REQUESTS,
    organizationWorkspaceAccessRequests,
  };
};

export const receiveOrganizationTeamAccessRequests = (
  organizationTeamAccessRequests,
) => {
  return {
    type: RECEIVE_ORGANIZATION_TEAM_ACCESS_REQUESTS,
    organizationTeamAccessRequests,
  };
};

export const receiveOrganizationTeamsWithinUserWorkspaces = (
  organizationTeamsWithinUserWorkspaces,
) => {
  return {
    type: RECEIVE_ORGANIZATION_TEAMS_WITHIN_USER_WORKSPACES,
    organizationTeamsWithinUserWorkspaces,
  };
};

export const receiveOrganizationWorkspace = (organizationWorkspace) => {
  return {
    type: RECEIVE_ORGANIZATION_WORKSPACE,
    organizationWorkspace,
  };
};

export const receiveOrganizationTagsByType = (organizationTags, tagType) => {
  return {
    type: RECEIVE_ORGANIZATION_TAGS_BY_TYPE,
    organizationTags,
    tagType,
  };
};

export const receiveOrganizationCustomerTags = (organizationCustomerTags) => {
  return {
    type: RECEIVE_ORGANIZATION_CUSTOMER_TAGS,
    organizationCustomerTags,
  };
};

export const receiveOrganizationUserMentions = (organizationUserMentions) => {
  return {
    type: RECEIVE_ORGANIZATION_USER_MENTIONS,
    organizationUserMentions,
  };
};

export const receiveOrganizationWorkspaceWebhooks = (organizationWebhooks) => {
  return {
    type: RECEIVE_ORGANIZATION_WORKSPACE_WEBHOOKS,
    organizationWebhooks,
  };
};

export const receiveOrganizationInvitations = invitations => {
  return {
    type: RECEIVE_ORGANIZATION_INVITATIONS,
    invitations,
  };
};

export const receiveOrganizationRequests = organizationRequests => {
  return {
    type: RECEIVE_ORGANIZATION_REQUESTS,
    organizationRequests,
  };
};

export const fetchOrganizationUsers = ({
  organizationId,
  page,
  pageSize,
  search,
  sort,
}) => (dispatch) => {
  return organizationAPI.fetchUsers({ organizationId, page, pageSize, search, sort }).then(
    res => dispatch(receiveOrganizationUsers(res))
  );
};

export const fetchOrganizationTeams = ({
  organizationId,
  page,
  pageSize,
  search,
  sort,
}) => (dispatch) => {
  return organizationAPI.fetchTeams({ organizationId, page, pageSize, search, sort }).then(
    res => dispatch(receiveOrganizationTeams(res))
  );
};

export const fetchOrganizationTeam = ({
  organizationId,
  teamId,
}) => (dispatch) => {
  return organizationAPI.fetchTeam({ organizationId, teamId }).then(
    res => dispatch(receiveOrganizationTeam(res))
  );
};

export const fetchOrganizationWorkspaces = ({
  organizationId,
  page,
  pageSize,
  search,
  sort,
}) => (dispatch) => {
  return organizationAPI.fetchWorkspaces({ organizationId, page, pageSize, search, sort }).then(
    res => dispatch(receiveOrganizationWorkspaces(res))
  );
};

export const fetchOrganizationWorkspaceAccessRequests = ({
  organizationId,
  page,
  pageSize,
  search,
  sort,
}) => (dispatch) => {
  return organizationAPI.fetchWorkspaceAccessRequests({ organizationId, page, pageSize, search, sort }).then(
    res => dispatch(receiveOrganizationWorkspaceAccessRequests(res))
  );
};

export const fetchOrganizationTeamAccessRequests = ({
  organizationId,
  page,
  pageSize,
  search,
  sort,
}) => (dispatch) => {
  return organizationAPI.fetchTeamAccessRequests({ organizationId, page, pageSize, search, sort }).then(
    res => dispatch(receiveOrganizationTeamAccessRequests(res))
  );
};

export const fetchOrganizationTeamsWithinUserWorkspaces = ({
  organizationId,
  page,
  pageSize,
  search,
  sort,
}) => (dispatch) => {
  return organizationAPI.fetchTeamsWithinUserWorkspaces({ organizationId, page, pageSize, search, sort }).then(
    res => dispatch(receiveOrganizationTeamsWithinUserWorkspaces(res))
  );
};

export const fetchOrganizationWorkspace = ({
  organizationId,
  workspaceId,
}) => (dispatch) => {
  return organizationAPI.fetchWorkspace({ organizationId, workspaceId }).then(
    res => dispatch(receiveOrganizationWorkspace(res))
  );
};

export const fetchOrganizationWorkspaceTags = ({
  organizationId,
  workspaceId,
  search,
  tagType,
}) => (dispatch) => {
  return organizationAPI.fetchOrganizationWorkspaceTags({
    organizationId, workspaceId, search, tagType,
  }).then(
    res => dispatch(receiveOrganizationTagsByType(res, tagType))
  );
};

export const fetchOrganizationCustomerTags = ({
  organizationId,
  page,
  pageSize,
  search,
  sort,
}) => (dispatch) => {
  return organizationAPI.fetchOrganizationCustomerTags({
    organizationId, page, pageSize, search, sort
  }).then(
    res => dispatch(receiveOrganizationCustomerTags(res))
  );
};

export const fetchOrganizationUserMentions = ({
  organizationId,
  search,
}) => (dispatch) => {
  return organizationAPI.fetchUserMentions({ organizationId, search }).then(
    res => dispatch(receiveOrganizationUserMentions(res))
  );
};

export const fetchOrganizationWorkspaceWebhooks = ({
  organizationId,
  workspaceId,
  page,
  pageSize,
  search,
}) => (dispatch) => {
  return organizationAPI.fetchEndpoints({ organizationId, workspaceId, page, pageSize, search }).then(
    res => dispatch(receiveOrganizationWorkspaceWebhooks(res))
  );
};

export const fetchOrganizationInvitations = ({ organizationId, status, page, pageSize, search }) => (dispatch) => {
  return organizationAPI.getOrganizationInvitations({ organizationId, status, page, pageSize, search }).then(res =>
    dispatch(receiveOrganizationInvitations(res)),
  );
};

export const fetchOrganizationRequests = ({
  filters,
  organizationId,
  page,
  pageSize,
  search,
  sort,
  workspaceId,
}) => (dispatch) => {
  return organizationAPI.fetchOrganizationRequests({
    filters, organizationId, page, pageSize, search, sort, workspaceId,
  }).then(
    res => dispatch(receiveOrganizationRequests(res))
  );
};
