import { connect } from 'react-redux';
import Main from './main';
import withRouter from '../../util/with_router';
import { fetchChannels } from '../../actions/channel_actions';

const msp = (state, ownProps) => {

  const { entities } = state;
  return {
    currentUser: entities.users[state.session.id],
    channels: Object.values(entities.channels),
    workspaceId: ownProps.match.params.workspaceId,
    workspaces: entities.workspaces,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchChannels: ({ channelsFilter, channelsTagsFilter }) =>
      dispatch(fetchChannels({ organizationId, workspaceId, channelsFilter, channelsTagsFilter })),
  };
};

export default withRouter(connect(msp, mdp)(Main));
