import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import NoResultImage from 'images/no_result.png';

import currencyCodesHash from '../../../../components/util/currency_codes_hash.json';
import withRouter from '../../../../util/with_router';
import {
  ellipsis,
  parseQueryString,
  sleep,
  statusBadge,
  statusMapping,
} from '../../../util/helpers';

// client-side pagination
const PAGE_SIZE = 25;

class FilteredList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      sliceStart: 0,
      sliceEnd: PAGE_SIZE,
      currentPage: 1,
    };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleClearCriteria = this.handleClearCriteria.bind(this);
  }

  async componentDidMount() {
    const { location } = this.props;

    if (location?.search) {
      const filters = parseQueryString(location.search);
      await this.props.fetchChannels({ filters });
    }

    // NOTE: Await here b/c the fetch is really fast, thus isLoading
    // immediately is reset
    await sleep(300);
    this.setState({ isLoading: false });
  }

  handleItemClick(id) {
    const { workspaceId } = this.props;
    this.props.navigate(`/workspaces/${workspaceId}/requests/${id}`);
  }

  handleClearCriteria() {
    const { workspaceId } = this.props;
    this.props.navigate(`/workspaces/${workspaceId}/requests/filter?requests=all`)
  }

  render() {
    const { isLoading, sliceStart, sliceEnd, currentPage } = this.state;
    const {
      channels,
      isEducationOrganizationPersona,
      totalCount,
    } = this.props;

    const count = channels?.length || 0

    if (isLoading) {
      return (
        <div className="summary-list-view filtered-channels-list-view">
          <div className="loading-spinner-container">
            <Spinner animation="border" role="status" variant="secondary" />
          </div>
        </div>
      );
    }

    return (
      <div className="summary-list-view filtered-channels-list-view pt-4 mb-5">
        <div className="header d-flex justify-content-between align-items-center">
          <div className="filter-result-title d-flex align-items-center">
            <div className="title me-2" style={{ fontSize: 24, fontWeight: 700 }}>Filter result</div>
            <div
              className="count filter-count badge rounded-pill"
              style={{
                backgroundColor: '#ffeedf',
                color: '#e59b55',
                fontWeight: 700,
                fontSize: 18,
              }}>
              {count}
            </div>
          </div>
        </div>
        {channels?.slice(sliceStart, sliceEnd)?.map(list => (
          <div
            className="thread-card-container container px-4 py-4"
            key={list.id}
            onClick={() => this.handleItemClick(list.id)}
          >
            <div className="item-header row">
              <div className="col-5">
                <div className="thread-col-header mb-1">Request</div>
                <div className="request-name">
                  {ellipsis({ string: list.name, maxLength: 80 })}
                </div>
              </div>
              {!isEducationOrganizationPersona && (
                <div className="col-4">
                  <div className="thread-col-header mb-1">Customers</div>
                  <div className="request-customers">
                    {tagsCount({ channel: list, type: 'customer' })}
                  </div>
                </div>
              )}
              {isEducationOrganizationPersona && (
                <div className="col-4">
                  <div className="thread-col-header mb-1">Safety Issue</div>
                  <div className="request-customers">
                    {list.safety_issue ? 'Yes' : 'No' }
                  </div>
                </div>
              )}
              <div className="request-teams col-3">
                <div className="thread-col-header mb-1"># of teams assigned</div>
                <div className="request-teams">
                  {tagsCount({ channel: list, type: 'team' })}
                </div>
              </div>
            </div>
            <div className="item-content row">
              {!isEducationOrganizationPersona && (
                <div className="request-metadata col-5">
                  <div className="thread-col-header mb-1">Deal value</div>
                  <div className={list.opportunity_value ? 'deal-value' : ''}>
                    {handleDealValue(list)}
                  </div>
                </div>
              )}
              {isEducationOrganizationPersona && (
                <div className="request-metadata col-5">
                  <div className="thread-col-header mb-1">Alert</div>
                  <div className="d-flex gap-2 align-items-center">
                    {list.alert ? 'Yes' : 'No' }
                  </div>
                </div>
              )}
              <div className="request-metadata col-4">
                <div className="thread-col-header mb-1">Created by</div>
                <div className="word-wrap-break-word">
                  {ellipsis({ string: list.author_email, maxLength: 40 })}
                </div>
              </div>
              <div className="request-metadata col-3">
                <div className="thread-col-header mb-1">Status</div>
                {/*
                  <div className={`status pill badge ${statusBadge(list.status)} px-4 py-2`}>
                    {statusMapping(list.status)}
                  </div>
                */}
                <div className="d-flex gap-2 align-items-center">
                  <div>{statusMapping(list.status)}</div>
                  <div className={`status pill rounded-pill badge ${statusBadge(list.status)}`} style={{ height: 20, width: 20 }}>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {!channels?.length > 0 && (
          <div className="thread-container no-results container-fluid d-flex flex-column h-75 align-items-center justify-content-center">
            <div className="image-container mb-2">
              <img src={NoResultImage} width={100} />
            </div>
            <div className="no-results-copy d-flex flex-column align-items-center">
              <div className="mb-1">Sorry we didn't find any results for your filter criteria.</div>
              <div className="clear-criteria" onClick={this.handleClearCriteria}>
                Clear criteria
              </div>
            </div>
          </div>
        )}
        {totalCount > PAGE_SIZE && (
          <div className="mt-4 d-flex justify-content-center">
            <nav aria-label="filter results navigation">
              <ul className="pagination">
                <li className={previousTabClassNames({ currentPage })}>
                  <span
                    className="page-link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.setState({
                      currentPage: currentPage - 1,
                      sliceStart: handleSliceStart({ number: currentPage - 2 }),
                      sliceEnd: handleSliceEnd({ currentPage: currentPage - 1 }),
                    })}
                  >
                    Previous
                  </span>
                </li>
                {handlePaginationNumbersArray({ totalCount }).map(number => (
                  <li className="page-item" key={number}>
                    <span
                      className={pagedTabClassNames({ currentPage, number })}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({
                        currentPage: number + 1,
                        sliceStart: handleSliceStart({ number }),
                        sliceEnd: handleSliceEnd({ currentPage: number + 1 }),
                      })}
                    >
                      {number + 1}
                    </span>
                  </li>
                ))}
                <li className={nextTabClassNames({ currentPage, totalCount })}>
                  <span
                    className="page-link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.setState({
                      currentPage: currentPage + 1,
                      sliceStart: handleSliceStart({ number: currentPage }),
                      sliceEnd: handleSliceEnd({ currentPage: currentPage + 1 }),
                    })}
                  >
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(FilteredList);

const pagedTabClassNames = ({ currentPage, number }) =>
  currentPage === number + 1 ? 'page-link active' : 'page-link';

const previousTabClassNames = ({ currentPage }) =>
  currentPage === 1 ? 'page-item disabled' : 'page-item';

const nextTabClassNames = ({ currentPage, totalCount }) => {
  const pagesCount = numberOfPages({ totalCount });
  return currentPage === pagesCount ? 'page-item disabled' : 'page-item';
};

const numberOfPages = ({ totalCount }) => Math.ceil(totalCount / PAGE_SIZE);

const handlePaginationNumbersArray = ({ totalCount }) => {
  const pagesCount = numberOfPages({ totalCount });
  const arrayOfNumbers = [...Array(pagesCount).keys()];
  return arrayOfNumbers;
};

const handleSliceStart = ({ number }) => {
  return PAGE_SIZE * number;
};

const handleSliceEnd = ({ currentPage }) => {
  return PAGE_SIZE * currentPage;
};

const handleDealValue = channel => {
  const opportunityValue = channel?.opportunity_value;
  const opportunityValueCurrency = channel?.opportunity_value_currency;
  const currencySymbol = opportunityValue && currencyCodesHash[opportunityValueCurrency];
  return opportunityValue
    ? `${currencySymbol || ''} ${opportunityValue.toLocaleString()}`
    : 'N/A';
};

const tagsCount = ({ channel, type }) => {
  const { tags } = channel;
  const tagsByType = tags?.filter(tag => tag.tag_type === type);
  return tagsByType?.length || 0;
};
