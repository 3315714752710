export const RECEIVE_WORKSPACES = 'RECEIVE_WORKSPACES';
export const RECEIVE_WORKSPACE = 'RECEIVE_WORKSPACE';
import * as workspaceAPI from '../services/workspace_api';

export const receiveWorkspaces = (workspaces) => {
  return {
    type: RECEIVE_WORKSPACES,
    workspaces,
  };
};

export const receiveWorkspace = (payload) => ({
  type: RECEIVE_WORKSPACE,
  payload,
});

export const fetchWorkspaces = ({ organizationId }) => (dispatch) => {
  return workspaceAPI.fetchWorkspaces({ organizationId }).then(res => dispatch(receiveWorkspaces(res)));
};

export const fetchWorkspace = ({ organizationId, workspaceId }) => (dispatch) => {
  return workspaceAPI.fetchWorkspace({ organizationId, workspaceId }).then(res => dispatch(receiveWorkspace(res)));
};

export const createWorkspace = ({ organizationId, workspace }) => (dispatch) => {
  return workspaceAPI.createWorkspace({ organizationId, workspace }).then(res => dispatch(receiveWorkspace(res)));
};

export const updateChannel = ({ organizationId, id, workspace }) => (dispatch) => {
  return workspaceAPI.updateWorkspace({ organizationId, id, workspace }).then(res => dispatch(receiveWorkspace(res)));
};
