import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class WizardUserCreationStepHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isEducationOrganizationPersona } = this.props;

    if (isEducationOrganizationPersona) {
      // Add info about Clever
      return (
        <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
          Add a user to this workspace and start collaborating quickly within
          your school district.
        </div>
      );
    }

    return (
      <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
        Add a user to this workspace and quickly start collaborating to address
        real customer problems and enhance your enterprise value. Users should
        include sales, customer success, delivery teams, and leadership.
      </div>
    );
  }
}

WizardUserCreationStepHeader.propTypes = {
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
};

export default WizardUserCreationStepHeader;
