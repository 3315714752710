import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import  withRouter from './with_router';
import { fetchUserChannels } from '../actions/user_channel_actions';
import { getJoinedChannels } from '../reducers/selectors';

class Protected extends React.Component {

  componentDidMount() {
    this.props.fetchUserChannels();
  }

  constructor(props) {
    super(props);
    this.ProtectedChannel = this.ProtectedChannel.bind(this);
  }

  ProtectedLogin({ children, component: path, exact }) {
    return children;
  }

  ProtectedChannel({ children, component: path, exact, match }, channelIds) {
    return (channelIds.includes(match.params.channelId) ?
      children
    : (
      <Navigate to={`/requests/${channelIds[0]}`} replace />
    ));
  }

  render() {
    if (!this.props.joinedChannels || this.props.joinedChannels.length === 0 || !this.props.loggedIn) {
      return this.ProtectedLogin(this.props);
    }

    const channelIds = this.props.joinedChannels.map(channel => channel.id.toString());
    return this.ProtectedChannel(this.props, channelIds);
  };
};

const mapStateToProps = state => {
  return {
    joinedChannels: getJoinedChannels(state),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps.children.props;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchUserChannels: () =>  workspaceId && dispatch(fetchUserChannels({ organizationId, workspaceId })),
  };
};

export const ProtectedRoute = withRouter(connect(mapStateToProps, mdp)(Protected));
