import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchOrganizationInvitations } from '../../../actions/organization_actions';
import { queryParamValue } from '../../../components/util/helpers';

const msp = (state, ownProps) => {
  const { search } = ownProps.location;
  const status = queryParamValue({ search, key: 'status' });

  return {
    currentUser: state.entities.currentUser,
    invitations: state.entities.organization.invitations.entries || [],
    invitationStatus: status,
    pages: state.entities.organization.invitations.pages || 1,
    invitationsCount: state.entities.organization.invitations.count || 0,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const { id } = currentUser;
  const organizationId = currentUser?.organization_id;

  return {
    fetchInvitations: ({ page, pageSize, search, status }) =>
      dispatch(fetchOrganizationInvitations({
        organizationId,
        status,
        page,
        pageSize
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
