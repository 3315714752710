import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@material-ui/core';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import { numToLocaleString } from '../../../components/util/helpers';
import { fetchWorkspaceRequestStatuses } from '../../../services/stats_api';
import { requestsStatusSummaryDemoData } from '../util/demo_data';

class RequestsStatusSummary extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      statuses: {},
    };
    this.handleStatusClick = this.handleStatusClick.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    const response = useDemoData
      ? requestsStatusSummaryDemoData({ persona: 'tech' })
      : await fetchWorkspaceRequestStatuses({
          workspaceId,
          organizationId,
        });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, statuses: response })
      } else {
        this.setState({ isLoading: false, isError: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleStatusClick({ status }) {
    const { currentLocale, workspaceId, useDemoData } = this.props;

    if (!status || useDemoData) {
      return;
    }

    status === 'all'
      ? window.open(`${window.location.origin}/#/${currentLocale}/workspaces/${workspaceId}/requests/filter?requests=all`)
      : window.open(`${window.location.origin}/#/${currentLocale}/workspaces/${workspaceId}/requests/filter?status=${status}`);
  }

  render() {
    const {
      workspaceId,
      defaultChannelId,
      currentLocale,
      currentUser,
      handleGeneralUpgradePlanModal,
      isEducationOrganizationPersona,
    } = this.props;

    const WIDGET_TITLE = isEducationOrganizationPersona
      ? 'Requests progress'
      : 'Customer requests progress';

    const { isLoading, statuses } = this.state;

    const isEmptyState = Object.keys(statuses).length === 0;

    if (isLoading) {
      return (
        <Card className="requests-status-summary-card card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="requests-status-summary-card card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState
                message="No active requests available"
                currentLocale={currentLocale}
              />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="requests-status-summary-card card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content">
            <div className="d-flex gap-5 justify-content-center align-items-center requests-status-summary-items-container">
              <div className="p5 d-flex row mb-0 text-center justify-content-center align-items-center total-requests-summary-item" onClick={() => this.handleStatusClick({ status: 'all' })}>
                <div className="requests-summary-item-count">{numToLocaleString(statuses.total || 0)}</div>
                <div className="requests-summary-item-desc">All active requests</div>
              </div>
              <div className="p5 d-flex row mb-0 text-center justify-content-center align-items-center requests-in-progress-summary-item" onClick={() => this.handleStatusClick({ status: 'in_progress' })}>
                <div className="requests-summary-item-count">{numToLocaleString(statuses.in_progress || 0)}</div>
                <div className="requests-summary-item-desc">In progress</div>
              </div>
              <div className="p5 d-flex row mb-0 text-center justify-content-center align-items-center requests-completed-summary-item" onClick={() => this.handleStatusClick({ status: 'done' })}>
                <div className="requests-summary-item-count">{numToLocaleString(statuses.completed || 0)}</div>
                <div className="requests-summary-item-desc">Completed</div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

RequestsStatusSummary.propTypes = {};

export default RequestsStatusSummary;

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Requests progress:
    </p>
    <p className="tooltip-paragraph">
      Quickly digest the current statuses of your requests. Align requests
      with your roadmap items to mark them as "in progress" or "done".
    </p>
    <p className="tooltip-paragraph">
      Roadmap tasks can be 1:1 alignments or indirect resolutions. The intent
      isn't for your organization to become a prisoner of requests in a myopic sense.
      But, rather, for requests to inform your roadmap to optimize product outcomes.
    </p>
    <p className="tooltip-paragraph">
      To filter requests by status, click the respective card.
    </p>
  </div>
);
