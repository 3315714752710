import React from 'react';
import BaseSelector from '../base_selector';
import currencyCodes from '../../../../../../../components/util/currency_codes_array.json';

const options = currencyCodes.map(object => ({
  label: object.code,
  value: object.code,
}));

const OpportunityValueCurrencySelector = ({
  onChange,
  selectedOption,
  defaultValue,
  name,
  className,
  styleOptions,
}) => {
  return (
    <BaseSelector
      name={name}
      options={options}
      defaultValue={defaultValue}
      value={selectedOption}
      onChange={onChange}
      className={className}
      styleOptions={styleOptions}
    />
  );
};

export default OpportunityValueCurrencySelector;
