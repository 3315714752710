import React from 'react';
import BaseSelector from '../base_selector';

import { statusOptions }  from '../../../../../../../components/util/common';

const StatusSelector = ({
  onChange,
  selectedOption,
  defaultValue,
  name,
  className,
  styleOptions,
  organizationPersona,
}) => {
  return (
    <BaseSelector
      name={name}
      options={statusOptions({ organizationPersona })}
      defaultValue={defaultValue}
      value={selectedOption}
      onChange={onChange}
      className={className}
      styleOptions={styleOptions}
    />
  );
};

export default StatusSelector;
