export const RECEIVE_CHANNELS = 'RECEIVE_CHANNELS';
export const RECEIVE_CHANNELS_SUMMARY = 'RECEIVE_CHANNELS_SUMMARY';
export const RECEIVE_CHANNEL = 'RECEIVE_CHANNEL';
export const RECEIVE_CHANNEL_FAVORITES = 'RECEIVE_CHANNEL_FAVORITES';

import * as channelAPI from '../services/channel_api';

export const receiveChannels = (channels) => {
  return {
    type: RECEIVE_CHANNELS,
    channels
  };
};

export const receiveChannelsSummary = (payload) => {
  return {
    type: RECEIVE_CHANNELS_SUMMARY,
    payload
  };
};

export const receiveChannel = (payload) => ({
  type: RECEIVE_CHANNEL,
  payload
});

export const receiveChannelFavorites = (payload) => ({
  type: RECEIVE_CHANNEL_FAVORITES,
  payload
});

export const fetchChannels = ({ organizationId, workspaceId, filters }) => (dispatch) => {
  return channelAPI.fetchChannels({ organizationId, workspaceId, filters }).then(res => dispatch(receiveChannels(res)));
};

export const fetchChannel = ({ organizationId, workspaceId, id }) => (dispatch) => {
  return channelAPI.fetchChannel({ organizationId, workspaceId, id }).then(res => dispatch(receiveChannel(res)));
};

export const createChannel = ({ organizationId, workspaceId, channel }) => (dispatch) => {
  return channelAPI.createChannel({ organizationId, workspaceId, channel }).then(res => {
    dispatch(receiveChannel(res));
    return res?.channel?.id;
  });
};

export const updateChannel = ({ organizationId, workspaceId, id, channel }) => (dispatch) => {
  return channelAPI.updateChannel({ organizationId, workspaceId, id, channel }).then(res => dispatch(receiveChannel(res)));
};

export const fetchChannelsSummary = ({ organizationId, workspaceId }) => (dispatch) => {
  return channelAPI.fetchChannelsSummary({ organizationId, workspaceId }).then(res => dispatch(receiveChannelsSummary(res)));
};

export const fetchChannelFavorites = ({ organizationId, workspaceId, id }) => (dispatch) => {
  return channelAPI.fetchChannelFavorites({ organizationId, workspaceId, id }).then(res => dispatch(receiveChannelFavorites(res)));
};

export const favoriteChannel = ({ organizationId, workspaceId, channelId }) => (dispatch) => {
  return channelAPI.favoriteChannel({ organizationId, workspaceId, channelId }).then(res => dispatch(receiveChannelFavorites(res)));
};

export const unfavoriteChannel = ({ organizationId, channelId, favoriteId }) => (dispatch) => {
  return channelAPI.unfavoriteChannel({ organizationId, channelId, favoriteId }).then(res => dispatch(receiveChannelFavorites(res)));
};
