import {connect} from 'react-redux';
import ChannelMetadata from './channel_metadata';
import withRouter from '../../../../util/with_router';
import {fetchChannel} from '../../../../actions/channel_actions';
import {fetchUsers} from '../../../../actions/user_actions';

const msp = (state, ownProps) => {
  return {
    currentUser: state.entities.currentUser,
    channel: state.entities.channels[ownProps.match.params.channelId],
  };
};

const mdp = (dispatch, ownProps) => {
  // const { currentUser } = ownProps;
  // const organizationId = currentUser?.organization_id;

  return {
    // fetchChannel: (id) => dispatch(fetchChannel({ organizationId, id })),
  };
};

export default withRouter(connect(msp, mdp)(ChannelMetadata));
