import { paramsToQueryString } from '../services/util/helpers';

export async function getEndpoints({
  search,
  page_size,
  page,
  organization_id,
}) {
  const params = { search, page, page_size };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organization_id}/webhooks.json`
    : `/organizations/${organization_id}/webhooks.json?${paramsToQueryString(params)}`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getEndpointsCsv({
  organization_id,
}) {
  const url = `/organizations/${organization_id}/webhooks/download`

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organization_id}/webhooks.csv`;
  });
  return;
}

export async function upsertEndpoint({
  organization_id,
  target_url,
  events,
  integration_type,
  is_override_supported,
}) {
  const response = await fetch(`/organizations/${organization_id}/webhooks`, {
    method: 'post',
    body: JSON.stringify({
      organization_id,
      target_url,
      events,
      integration_type,
      is_override_supported,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateEndpoint({
  organization_id,
  id,
  target_url,
  events,
  integration_type,
  is_override_supported,
}) {
  const response = await fetch(`/organizations/${organization_id}/webhooks/${id}`, {
    method: 'put',
    body: JSON.stringify({
      target_url,
      events,
      integration_type,
      is_override_supported,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeEndpoint({
  organization_id,
  id,
}) {
  const response = await fetch(`/organizations/${organization_id}/webhooks/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}
