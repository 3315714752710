import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import {
  ellipsis,
  statusBadge,
  statusMapping,
} from '../../../components/util/helpers';
import { fetchWorkspaceRequestsWatchedByMe } from '../../../services/stats_api';

const handleStatusUpdatedAt = (statusUpdatedAtTimeAgo) => {
  if (!statusUpdatedAtTimeAgo) {
    return 'not yet updated';
  }

  return statusUpdatedAtTimeAgo === 'today'
    ? 'today'
    : `${statusUpdatedAtTimeAgo} ago`;
};

class RequestsWatchedByMe extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      items: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    const response = await fetchWorkspaceRequestsWatchedByMe({
      workspaceId,
      organizationId,
    });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, items: response.entries })
      } else {
        this.setState({ isLoading: false, isError: true });
      }

    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentLocale,
      currentUser,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      workspaceId,
    } = this.props;

    const WIDGET_TITLE = 'Oldest 10 requests watched by me';
    const { items, isLoading } = this.state;
    const data = items;
    const isEmptyState = items && !items.length;

    if (isLoading) {
      return (
        <Card className="opportunity-value-by-size-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="opportunity-value-by-size-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState
                message="No active requests currently being watched"
                currentLocale={currentLocale}
              />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="opportunity-value-by-size-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="column-header ps-4 pt-4">
                      Request
                    </TableCell>
                    <TableCell className="column-header ps-4 pt-4">
                      Status
                    </TableCell>
                    <TableCell className="column-header pe-4 pt-4">
                      Created
                    </TableCell>
                    <TableCell className="column-header pe-4 pt-4">
                      Status updated at
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        className="dashboard-table-cell channel-path ps-4 py-3"
                      >
                        <a
                          href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${row.id}`}
                          target="_blank"
                        >
                          <abbr title={row.name}>
                            {
                              row.name &&
                              `${ellipsis({ string: row.name, maxLength: 100 })}`
                            }
                          </abbr>
                        </a>
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-status ps-3 py-3">
                        <div className={`status pill badge ${statusBadge(row.status)} d-flex justify-content-center align-items-center`}>
                          {statusMapping(row.status)}
                        </div>
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-age ps-3 py-3">
                        {row.created_at_time_ago === 'today' ? 'today' : `${row.created_at_time_ago} ago`}
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-age ps-3 py-3">
                        {handleStatusUpdatedAt(row.status_updated_at_time_ago)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

RequestsWatchedByMe.propTypes = {};

export default RequestsWatchedByMe;

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Requests watched by me:
    </p>
    <p className="tooltip-paragraph">
      By using the <b>Watch the request</b> field when managing a request, you
      can signal that you would like to stay updated with progress reports
      on a request.
    </p>
  </div>
);
