import { connect } from 'react-redux';
import React from 'react';
import { openModal, closeModal } from '../../actions/modal_actions';
import PrivateChannelForm from './private_channel_form';
import {fetchUsers} from '../../actions/user_actions';
import {fetchChannels, createChannel} from '../../actions/channel_actions';
import {getAllUsernames, getAllPrivateChannels, getJoinedUsers, getAllPrivateChannelsOfCurrentUser} from '../../reducers/selectors';
import {createUserChannel} from '../../actions/user_channel_actions';;

const mapStateToProps = (state) => {
  return {
    errors: state.errors?.session,
    formType: 'new_private_channel',
    usernames: getAllUsernames(state),
    privateChannels: getAllPrivateChannelsOfCurrentUser(state),
    currentUser: state.entities.currentUser,
    getJoinedUsers: (channelId) => getJoinedUsers(state, channelId)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { currentUser, workspaceId } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    processForm: ({ channel, selectedUsers }) =>
      dispatch(createChannel({ organizationId, channel })).then(res => {
        selectedUsers.forEach(user => {
          dispatch(createUserChannel({
            organizationId,
            workspaceId,
            userChannel: { user_id: user.id, channel_id: res.payload.channel.id },
          }));
        });
      }),
    createChannel: ({ channel }) =>
      dispatch(createChannel({ organizationId, workspaceId, channel })),
    createUserChannel: ({ userChannel }) =>
      dispatch(createUserChannel({ organizationId, workspaceId, userChannel })),
    closeModal: () => dispatch(closeModal()),
    fetchUsers: () => dispatch(fetchUsers({ organizationId, workspaceId })),
    fetchChannels: () => dispatch(fetchChannels({ organizationId, workspaceId }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateChannelForm);
