import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import AboutPageDrawer from '../../AboutDrawers/UsersSettingsPage/AboutPageDrawer';
import UsersSettingContainer from '../organization_users_access_management/users_container';
import TeamsSettingContainer from '../organization_teams_access_management/teams_container';
import WorkspacesSettingContainer from '../organization_workspaces_access_management/workspaces_container';
import WorkspaceRequestAccessSettingContainer from '../organization_workspaces_request_access_management/workspaces_container';
import TeamRequestAccessSettingContainer from '../organization_teams_request_access_management/teams_container';
import InvitationsSettingContainer from '../user_invitations_access_management/invitations_container';

const tabPath = (locale, accessTab) =>
  accessTab
    ? `/#/${locale}/settings/access_management?access_tab=${accessTab}`
    : `/#/${locale}/settings/access_management`;

const isActiveClass = (paramValue, accessTab) =>
  paramValue?.toLowerCase() === accessTab ? 'active' : '';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAboutPageDrawerOpen: false,
    };

    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {

    const {
      accessTab,
      currentLocale,
      currentUser,
      currentUserOrganization,
      isAccountAdmin,
      isEducationOrganizationPersona,
    } = this.props;

    return (
      <div className="settings organization-users container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Settings</div>
          <div className="subheader">
            <div className="page">Access management</div>
            {!isEducationOrganizationPersona && (
              <div
                className="pro-tip-button ms-2"
                onClick={() => this.handleOnClickOpenAboutPageDrawer()}
              >
                Pro Tip
              </div>
            )}
          </div>
        </div>
        <div className="content-container mt-3">
          <ul className="nav nav-tabs mt-4">
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(accessTab)}`} aria-current="page" href={tabPath(currentLocale)}>
                People
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(accessTab, 'teams')}`} aria-current="page" href={tabPath(currentLocale, 'teams')}>
                Teams
              </a>
            </li>
            {/* // Temporarily hide for now until self sign-up is back (tho not needed for Education persona)
              <li className="nav-item">
                <a className={`nav-link ${isActiveClass(accessTab, 'invitations')}`} href={tabPath(currentLocale, 'invitations')}>
                  Invitations
                </a>
              </li>
            */}
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(accessTab, 'workspaces')}`} href={tabPath(currentLocale, 'workspaces')}>
                Workspaces
              </a>
            </li>
            {isEducationOrganizationPersona && (
              <li className="nav-item">
                <a className={`nav-link ${isActiveClass(accessTab, 'workspace-access-requests')}`} href={tabPath(currentLocale, 'workspace-access-requests')}>
                  Workspace access requests
                </a>
              </li>
            )}
            {isEducationOrganizationPersona && (
              <li className="nav-item">
                <a className={`nav-link ${isActiveClass(accessTab, 'team-access-requests')}`} href={tabPath(currentLocale, 'team-access-requests')}>
                  Team access requests
                </a>
              </li>
            )}
          </ul>
          {!accessTab && (
            <UsersSettingContainer
              key="users-settings-container"
              currentUser={currentUser}
              currentUserOrganization={currentUserOrganization}
              currentLocale={currentLocale}
            />
          )}
          {accessTab === 'teams' && (
            <TeamsSettingContainer
              key="teams-settings-container"
              currentUser={currentUser}
              currentLocale={currentLocale}
            />
          )}
          {accessTab === 'workspaces' && isAccountAdmin && (
            <WorkspacesSettingContainer
              key="workspaces-settings-container"
              currentUser={currentUser}
              currentLocale={currentLocale}
              isAccountAdmin={isAccountAdmin}
            />
          )}
          {accessTab === 'invitations' && (
            <InvitationsSettingContainer
              currentUser={currentUser}
              currentUserOrganization={currentUserOrganization}
              currentLocale={currentLocale}
            />
          )}
          {accessTab === 'workspace-access-requests' &&
            isEducationOrganizationPersona && (
            <WorkspaceRequestAccessSettingContainer
              key="workspace-access-requests-settings-container"
              currentUser={currentUser}
              currentLocale={currentLocale}
              isAccountAdmin={isAccountAdmin}
            />
          )}
          {accessTab === 'team-access-requests' &&
            isEducationOrganizationPersona && (
            <TeamRequestAccessSettingContainer
              key="team-access-requests-settings-container"
              currentUser={currentUser}
              currentLocale={currentLocale}
              isAccountAdmin={isAccountAdmin}
            />
          )}
        </div>
        <SlidingPane
          className='about-page-drawer settings users-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer
              currentUser={currentUser}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
        </SlidingPane>
      </div>
    );
  }
}

Content.defaultProps = {};

Content.propTypes = {};

export default Content;
