import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Select from 'react-select';

import { sleep } from '../../../components/util/helpers';
import {
  composeValidators,
  getReactFinalFormSelectValues,
  toastifyConfiguration,
} from '../../../components/util/helpers';
import {
  accountTypeOptions,
  B2B_PERSONA_VALUE,
  B2B_PERSONA_LABEL,
  FREE_TRIAL_LABEL,
  FREE_TRIAL_VALUE,
  organizationPersonaOptions,
}  from '../../../components/util/common';
import { upsertOrganization, updateOrganization } from '../../../services/organization';

toast.configure();

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

class AddOrganizationPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      currentUser,
      isEditMode,
      selectedOrganization,
      onAddOrganizationPaneClose,
      handleGetPaginatedOrganizations,
    } = this.props;

    const {
      accountType,
      disabled,
      domain,
      name,
      organizationPersona,
      url,
    } = values;

    const organization = isEditMode
      ? await updateOrganization({
          id: selectedOrganization.id,
          ...name && { name },
          ...url && { url },
          ...domain && { domain },
          disabled: disabled,
          ...accountType && { accountType: accountType.value },
          ...organizationPersona && { organizationPersona: organizationPersona.value },
        })
      : await upsertOrganization({
          name,
          url,
          domain,
          accountType: accountType.value,
          organizationPersona: organizationPersona.value,
        })

    if (organization && !(organization || {}).error) {
      onAddOrganizationPaneClose();
      await handleGetPaginatedOrganizations(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      const action = isEditMode ? 'updated' : 'added';
      toast.success(`Organization, ${name}, ${action} successfully!`, toastifyConfiguration({}));
    } else {
      const action = isEditMode ? 'updating' : 'adding';
      toast.error(`Error ${action} organization, ${name}. Please try again.`, toastifyConfiguration({}));
    }
  }

  render() {
    const { isEditMode, selectedOrganization } = this.props;
    const { disabled, id, internal } = selectedOrganization || {};
    const initialDisabledValue = disabled || undefined;

    const orgAccountType = selectedOrganization?.account_type;
    const orgPersona = selectedOrganization?.organization_persona;

    const initialAccountTypeValue = isEditMode
      ? {
          value: orgAccountType,
          label: (accountTypeOptions.filter(type => type.value === orgAccountType).shift() || {}).label
        }
      : { value: FREE_TRIAL_VALUE, label: FREE_TRIAL_LABEL };

    const initialOrganizationPersonaValue = isEditMode
      ? {
          value: orgPersona,
          label: (organizationPersonaOptions.filter(type => type.value === orgPersona).shift() || {}).label
        }
      : { value: B2B_PERSONA_VALUE, label: B2B_PERSONA_LABEL };

    return (
      <div className="add-organization-pane">
        <Form
          onSubmit={this.handleOnSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                validate={composeValidators(required, maxLength)}
                name="name"
                initialValue={isEditMode ? selectedOrganization.name : undefined}
              >
                {({ input, meta }) => (
                  <p className="field name">
                    <input {...input} type="text" placeholder="name" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </p>
                )}
              </Field>
              <Field name="url" initialValue={isEditMode ? selectedOrganization.url : undefined}>
                {({ input, meta }) => (
                  <p className="field url">
                    <input {...input} type="text" placeholder="url" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </p>
                )}
              </Field>
              <Field
                name="domain"
                validate={composeValidators(maxLength)}
                initialValue={isEditMode ? selectedOrganization.domain : undefined}
              >
                {({ input, meta }) => (
                  <p className="field domain">
                    <input {...input} type="text" placeholder="domain" />
                    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                  </p>
                )}
              </Field>
              {!internal && (
                <>
                  <div className="field-container">
                    <label>Account type</label>
                    <Field
                      name="accountType"
                      validate={requiredSelect}
                      initialValue={initialAccountTypeValue}
                      component={ReactSelectAdapter}
                      options={accountTypeOptions}
                    />
                  </div>
                  <div className="field-container mt-3">
                    <label>Organization persona</label>
                    <Field
                      name="organizationPersona"
                      validate={requiredSelect}
                      initialValue={initialOrganizationPersonaValue}
                      component={ReactSelectAdapter}
                      options={organizationPersonaOptions}
                    />
                  </div>
                </>
              )}
              {id && !internal ? (
                <div className="disabled-radio-button">
                  <Field name="disabled" component="input" type="checkbox" initialValue={initialDisabledValue} />
                  <label>Disabled?</label>
                </div>
              ) : null}
              <div className="submit">
                <input type="submit" name="commit" value="Submit" disabled={submitting} />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

AddOrganizationPane.propTypes = {
  handleGetPaginatedOrganizations: PropTypes.func,
};

export default AddOrganizationPane;

// private

const ReactSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select">
    <Select {...input} {...rest} />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
)

const required = value => value ? undefined : 'Required';
const requiredSelect = value => value && value.value ? undefined : 'Required';
const maxLength = value =>
  !value || value.length < 300 ? undefined : 'Length must be under 300 characters';
