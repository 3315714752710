import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Field } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Logo from 'images/ci_logo.png';

import { sleep } from '../../components/util/helpers';
import { signupWithEmail } from '../../services/login_api';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
    this.handleSignupWithGoogle = this.handleSignupWithGoogle.bind(this);
  }

  onSubmit = values => {
    // await sleep(300);
    const { email } = values;
    const {} = this.props;
    const response = signupWithEmail({ email });
    if (!(response || {}).error) {
      // window.location.href = `/organizations/${organizationId}`;
    }
  }

  handleSignupWithGoogle = () => {
    console.log('!!!!!!! handleSignupWithGoogle ----->');
  }

  render () {
    console.log('!!!!!!! window location ---->', window.location);
    const { currentLocale } = this.props;
    return (
      <div className="session-new">
        <div className="login-form signup">
          <div className="login-logo-container">
            <img src={Logo} width={60} height={30} />
          </div>
          <div className="login-body signup">
            <div className="login-header signup">
              <div className="title">Get started</div>
              <div className="subtitle">Free up to 20 users</div>
            </div>
            <div className="login-omniauth-providers">
              <div className="provider">
                <button className="provider-signin-button" onClick={this.handleSignupWithGoogle}>
                  <svg width="16" height="16" viewBox="1 1 14 14" className="w1 h1 ml1 mr2">
                    <g fill="none">
                      <path d="M2.629 10.659A5.893 5.893 0 0 1 2 8c0-.956.226-1.858.629-2.659l2.065 1.544a3.487 3.487 0 0 0 0 2.23L2.629 10.66z" fill="#FBBC05"></path>
                      <path d="M2.629 5.341C3.627 3.357 5.713 2 8.139 2c1.563 0 2.959.573 4.047 1.5L10.4 5.245a3.6 3.6 0 0 0-2.26-.79c-1.61 0-2.97 1.015-3.446 2.43L2.629 5.34z" fill="#EA4335"></path>
                      <path d="M2.628 10.657L4.692 9.11c.475 1.417 1.835 2.435 3.448 2.435 1.702 0 2.986-.845 3.293-2.318H8.14V6.91h5.72c.084.355.14.736.14 1.091 0 3.818-2.79 6-5.86 6-2.427 0-4.514-1.358-5.512-3.343z" fill="#34A853"></path>
                      <path d="M12.141 12.506l-1.96-1.483a2.704 2.704 0 0 0 1.252-1.796H8.14V6.91h5.72c.084.355.14.736.14 1.091 0 1.956-.732 3.482-1.859 4.506z" fill="#4285F4"></path>
                    </g>
                  </svg>
                  <div className="provider-button-label ">Continue with Google</div>
                </button>
              </div>
            </div>
            <div className="auth-types-divider">or</div>
            <Form
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <div className="inner-form-container">
                  <form onSubmit={handleSubmit}>
                    <label>Work email</label>
                    <Field name="email" validate={required} autoFocus>
                      {({ input, meta }) => (
                        <p className="email-input">
                          <input {...input} type="email" autoComplete="email" />
                          {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                        </p>
                      )}
                    </Field>
                    <div className="submit">
                      <input type="submit" name="commit" value="Continue" disabled={submitting} />
                    </div>
                  </form>
                </div>
              )}
            />
            <div className="shared-registration-links">
              <div>
                <a href={`/${currentLocale}/users/sessions/new`}>
                  Login
                </a>
              </div>
              <div>
                <a href={`/${currentLocale}/users/password/new`}>
                  Forgot your password
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {};

export default LoginForm;

const required = value => console.log('!!!!!! value --->', value) || (value ? undefined : 'Required');
