import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FilterChannelsModal from './filter_channels_modal';
import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import TableComponent from '../../../components/common/TableComponent';
import AboutPageDrawer from '../../AboutDrawers/RequestsSettingsPage/AboutPageDrawer';
import { fetchOrganizationTagsAcrossWorkspaces } from '../../../services/organization_api';
// import AddRequestPane from '../../../components/pane/AddRequestPane';
// import { removeRequest, updateRequest, getRequestsCsv } from '../../../services/organization_api';
import {
  numToLocaleString,
  parseQueryString,
  removeEmptyFromObject,
  statusBadge,
  statusMapping,
  toastifyConfiguration,
} from '../../../components/util/helpers';
import currencyCodesHash from '../../../components/util/currency_codes_hash.json';
import { paramsToQueryString } from '../../../services/util/helpers';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedRequest: {},
      search: '',
      requests: [],
      requestsCount: 0,
      isAboutPageDrawerOpen: false,
      selection: [],
    };
    this.handleOnClickAddRequest = this.handleOnClickAddRequest.bind(this);
    this.onAddRequestPaneClose = this.onAddRequestPaneClose.bind(this);
    this.handleOnClickEditRequest = this.handleOnClickEditRequest.bind(this);
    this.handleOnClickRemoveRequest = this.handleOnClickRemoveRequest.bind(this);
    this.handleOnClickRemoveMultipleRequests = this.handleOnClickRemoveMultipleRequests.bind(this);
    this.handleRequestsSearch = this.handleRequestsSearch.bind(this);
    this.handleRequestsFilterFetch = this.handleRequestsFilterFetch.bind(this);
    this.handleGetPaginatedRequests = this.handleGetPaginatedRequests.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleConfirmDisable = this.handleConfirmDisable.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
    this.handleOnDisableRequest = this.handleOnDisableRequest.bind(this);
    this.handleOnOpenFilterChannelsModal = this.handleOnOpenFilterChannelsModal.bind(this);
    this.handleOnFilterChannels = this.handleOnFilterChannels.bind(this);
    this.handleClearCriteria = this.handleClearCriteria.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);

    const { currentUser } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = await fetchOrganizationTagsAcrossWorkspaces({
      organizationId,
    });

    this.setState({ tags: response?.entries || [] });
  }

  debounceSearch = debounce(input => this.handleRequestsFilterFetch(input), 500);

  handleRequestsSearch = event => {
    event && event.preventDefault();
    const { search } = this.state;
    const input = event?.target?.value;

    // Note: validate no previous "search" value exists, to enable clear search
    if (!input && !search) {
      return;
    }

    this.debounceSearch(input);
  }

  handleRequestsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser, location } = this.props;

    const filters = location?.search
      ? parseQueryString(location.search)
      : undefined;

    const filteredRequests = await this.props.fetchRequests({
      search: input,
      filters,
    });

    if (filteredRequests) {
      this.setState({ requests: filteredRequests.entries || [], search: input });
    }
  }

  handleGetPaginatedRequests = async (page, pageSize, sorted) => {
    const { currentUser, location } = this.props;
    const { search } = this.state;
    const filters = location?.search
      ? parseQueryString(location.search)
      : undefined;

    await this.props.fetchRequests({
      filters,
      page: page + 1,
      pageSize,
      search,
      sort: sorted,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddRequest = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedRequest: {} });
  }

  onAddRequestPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditRequest = request => {
    const { id, workspace_id } = request;
    this.props.navigate(`/workspaces/${workspace_id}/requests/${id}`);
  }

  handleConfirmDelete = async (request, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveRequest}
            subtitle="You want to delete this request?"
            itemToRemove={request}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmDisable = async (request, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnDisableRequest}
            subtitle="You want to disable this request?"
            itemToRemove={request}
            event={event}
            onClose={onClose}
            confirmButtonText={'Yes, disable it!'}
          />
        );
      }
    });
  }

  handleOnClickRemoveRequest = async (request, event) => {
    const { currentUser } = this.props;
    // const response = await removeRequest({
    //   organizationId: currentUser?.organization_id,
    //   requestId: request.id,
    // });

    const response = {};
    if (!(response || {}).error && !(response || {}).dependency) {
      await this.handleGetPaginatedRequests(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Request removed successfully.', toastifyConfiguration({}));
    } else if ((response || {}).dependency) {
      toast.warning(`Cannot remove requests in use by resources. Please try disabling the request instead.`, toastifyConfiguration({}));
    } else {
      toast.error('Error removing the request. Please try again.', toastifyConfiguration({}));
    }
  }

  handleOnClickRemoveMultipleRequests = async (requestIds, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    // const response = await removeUser({
    //   organization_id: currentUser?.organization_id,
    //   user_id: user.id,
    // });
    // if (!(response || {}).error) {
    //   await this.handleGetPaginatedUsers(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
    //   toast.success('User removed successfully!', toastifyConfiguration({}));
    // } else {
    //   toast.error('Error removing the user. Please try again.', toastifyConfiguration({}));
    // }
  }

  handleOnDisableRequest = async (request, event) => {
    const { currentUser } = this.props;
    // const response = await updateRequest({
    //   organizationId: currentUser?.organization_id,
    //   requestId: request.id,
    //   disabled: true,
    // });

    const response = {};

    if (!(request || {}).error) {
      await this.handleGetPaginatedRequests(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success(`Request, ${request.name}, disabled successfully!`, toastifyConfiguration({}));
    } else {
      toast.error(`Error disabling request ${request.name}. Please try again.`, toastifyConfiguration({}));
    }
  }

  handleOnOpenFilterChannelsModal = () => {
    const { currentUser, location, organizationPersona } = this.props;
    const { tags } = this.state;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <FilterChannelsModal
            currentUser={currentUser}
            location={location}
            onClick={this.handleOnFilterChannels}
            onClose={onClose}
            organizationPersona={organizationPersona}
            tags={tags}
          />
        );
      }
    });
  }

  handleOnFilterChannels = async ({ filterValuesSelected }) => {
    this.setState({ loading: true });

    const { search } = this.state;
    const { currentLocale } = this.props;

    const queryString = paramsToQueryString(
      removeEmptyFromObject(filterValuesSelected),
    );

    queryString
      ? this.props.navigate(`/settings/requests?${queryString}`, { replace: true })
      : this.props.navigate('/settings/requests', { replace: true });

    await this.props.fetchRequests({
      filters: filterValuesSelected,
      page: DEFAULT_PAGE + 1,
      pageSize: DEFAULT_PAGE_SIZE,
      search,
    });
    this.setState({ loading: false });
  }

  handleClearCriteria() {
    this.props.navigate(`/settings/requests`, { replace: true });
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    // await getRequestsCsv({ organizationId: currentUser?.organization_id });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  handleGetTableProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleGetTbodyProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleOnFetchData({ state }) {
    this.setState({ loading: true });
    this.handleGetPaginatedRequests(state.page, state.pageSize, state.sorted);
  }

  handleSelection({ selection }) {
    this.setState({ selection });
  }

  render() {
    let {
      // Note: Only enterprise accounts can add requests
      canAddWorkspaces,
      currentLocale,
      currentUser,
      organizationPersona,
      requests,
      requestsCount,
      pages,
      workspaces,
    } = this.props;

    let { isAddMode, isEditMode, selectedRequest } = this.state;

    const hasMoreAThanOneWorkspace = workspaces?.length > 1;
    const isBusinessOrganization = organizationPersona === 'b2b';

    const columns = [{
      id: 'name',
      Header: 'Request name',
      accessor: t => (
        <a
          href={`/#/${currentLocale}/workspaces/${t.workspace_id}/requests/${t.id}`}
          target="_blank"
        >
          {t.name}
        </a>
      ),
    },
    ...hasMoreAThanOneWorkspace ? [{
      id: 'workspace',
      Header: 'Workspace',
      sortable: false,
      accessor: t =>  <span>{getWorkspaceName({ workspaceId: t.workspace_id, workspaces })}</span>,
    }] : [],
    {
      accessor: 'author_email',
      Header: 'Created by',
    }, {
      id: 'status',
      Header: 'Status',
      accessor: t => (
        <div className={`status pill pt-2 pb-2 badge ${statusBadge(t.status)} d-flex align-items-center justify-content-center w-75`}>
          {statusMapping(t.status)}
        </div>
      )
    },
    ...isBusinessOrganization ? [{
      id: 'opportunity_value',
      Header: 'Opportunity value',
      accessor: t => <span>{t.opportunity_value ? `${handleCurrencySymbol(t.opportunity_value_currency)}${numToLocaleString(t.opportunity_value)}` : 'null'}</span>
    }] : [],
    {
      id: 'created_at',
      Header: 'Created at',
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'updated_at',
      Header: 'Updated at',
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: t => (
        <div>
          <a
            href={`/#/${currentLocale}/workspaces/${t.workspace_id}/requests/${t.id}`}
            target="_blank"
          >
            <span className='edit-cell'>
              <i className="bi bi-box-arrow-up-right"></i>
            </span>
          </a>
          {/*
            <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, t)}>
              <i className="bi bi-trash3"></i>
            </span>
          */}
        </div>
      )
    }];

    return (
      <div className="settings organization-requests container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Activity</div>
          <div className="subheader">
            <div className="page">Requests</div>
            {isBusinessOrganization && (
              <div
                className="pro-tip-button ms-2"
                onClick={() => this.handleOnClickOpenAboutPageDrawer()}
              >
                Pro Tip
              </div>
            )}
          </div>
        </div>
        <div className="content-container mt-5">
          <div className="row mb-4 resource-metadata">
            <div className="col-md-4 column-width">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="h5">Requests</div>
                    <div className="d-flex">
                      <i className="bi bi-volume-up me-1"></i>
                      <div className="card-count">{requestsCount}</div>
                    </div>
                  </div>
                  <div>
                    Requests created
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-actions-and-export d-flex justify-content-between">
            <div className="summary-actions d-flex">
              <div className="search">
                <input
                  type="text"
                  className="form-control search-input"
                  onChange={this.handleRequestsSearch}
                  placeholder="search requests" />
              </div>
              <div className="ms-2 header-container d-flex align-items-center">
                <i
                  className="bi bi-filter requests-filter-icon"
                  onClick={() => this.handleOnOpenFilterChannelsModal()}
                />
              </div>
            </div>
            <div className="ms-2 button-actions">
              {/* (
                <Button
                  handleClick={this.handleOnClickAddRequest}
                  label="Add New"
                  classes="primary small add-new"
                />
              */}
              {/*
                <Button
                  handleClick={this.handleCsvExport}
                  label="Export CSV"
                  classes="export small export-csv"
                />
              */}
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <TableComponent
              data={requests}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              deleteConfirmationSubtitle="You want to delete the request(s)?"
              loading={this.state.loading}
              pages={pages}
              columns={columns}
              manual
              handleOnFetchData={this.handleOnFetchData}
              handleSelection={this.handleSelection}
              handleGetTableProps={this.handleGetTableProps}
              handleGetTbodyProps={this.handleGetTbodyProps}
              handleOnClickRemoveMultipleItems={this.handleOnClickRemoveMultipleRequests}
              multipleStatusUpdateEnabled={true}
            />
            {/*
              <SlidingPane
                className='add-request-pane'
                overlayClassName='sliding-pane-overlay'
                isOpen={ this.state.isPaneOpen }
                title={isEditMode ? 'Edit Workspace' : 'Create New Workspace'}
                width='60%'
                subtitle=''
                onRequestClose={this.onAddRequestPaneClose}>
                  <AddRequestPane
                    handleGetPaginatedRequests={this.handleGetPaginatedRequests}
                    currentUser={currentUser}
                    onAddRequestPaneClose={this.onAddRequestPaneClose}
                    isEditMode={isEditMode}
                    selectedRequest={selectedRequest}
                  />
              </SlidingPane>
            */}

          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings teams-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;

const handleCurrencySymbol = currency =>
  currency ? currencyCodesHash[currency] : '';

const getWorkspaceName = ({ workspaceId, workspaces }) => {
  const foundWorkspace =
    workspaces.filter(workspace => workspace.id === workspaceId);

  return foundWorkspace?.length ? foundWorkspace[0].name : 'Unknown';
};
