import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import {
  fetchTwoFactorSettingsBackupCodes,
} from '../../../services/two_factor_settings_api';

const msp = (state) => {
  return {
    currentUser: state.entities.currentUser,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchTwoFactorSettingsBackupCodes: () =>
      fetchTwoFactorSettingsBackupCodes(),
  };
};

export default withRouter(connect(msp, mdp)(Content));
