export const fetchMessages = ({
  organizationId,
  workspaceId,
  channelId,
  pageSize,
}) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/messages?workspace_id=${workspaceId}&page_size=${pageSize}&channel_id=${channelId}`,
  });
};

export async function fetchMessagesMetadata({
  organizationId,
  workspaceId,
  channelId,
  returnCount,
  pageSize,
}) {
  const url =
    `/api/organizations/${organizationId}/messages/metadata?workspace_id=${workspaceId}&page_size=${pageSize}&channel_id=${channelId}&return_count=${returnCount}`;

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export const fetchMessage = ({ id, organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/messages/${id}?workspace_id=${workspaceId}`,
  });
};

export const createMessage = ({ message, organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/messages/`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: {
      ...message,
      workspace_id: workspaceId,
    },
    contentType: false,
    processData: false,
  });
};

export const editMessage = ({ id, message, organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/messages/${message.id}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'PATCH',
    data: { message, workspace_id: workspaceId }
  });
};

export const removeMessage = ({
  messageId,
  organizationId,
  channelId,
  workspaceId,
}) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/messages/${messageId}?workspace_id=${workspaceId}&channel_id=${channelId}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'DELETE'
  });
};
