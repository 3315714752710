import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import { fetchWorkspaceSummaryCounts } from '../../../services/stats_api';
import { staticSummaryDemoData } from '../util/demo_data';

const STATIC_SUMMARY_ITEMS = [
  { key: 'safety_issues', name: 'Safety issues', param: 'safety_issue' },
  { key: 'alerts', name: 'Alerts', param: 'alert' },
  { key: 'resource_requests', name: 'Resource requests', param: 'resource_request' },
  { key: 'maintenance', name: 'Maintenance', param: 'maintenance' },
];

class RequestsBreakdownEducationCard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      summaryItems: {},
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    const response = useDemoData
      ? staticSummaryDemoData({ persona: 'tech' })
      : await fetchWorkspaceSummaryCounts({
          workspaceId,
          organizationId,
        });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, summaryItems: response })
      } else {
        this.setState({ isLoading: false, isError: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentLocale,
      currentUser,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      useDemoData,
      workspaceId,
    } = this.props;

    const WIDGET_TITLE = 'Requests breakdown';
    const { isLoading, summaryItems } = this.state;

    const arrayOfItemValues = Object.values(summaryItems || {});
    const sumOfArrayItems = arrayOfItemValues.reduce((partialSum, a) => partialSum + a, 0);
    const isEmptyState = sumOfArrayItems === 0;

    if (isLoading) {
      return (
        <Card className="bottom-widget static-summary-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="bottom-widget bottom-widget static-summary-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState
                message="No data available for 'Requests breakdown'"
                currentLocale={currentLocale}
              />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="bottom-widget static-summary-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="column-header ps-4 pt-4">Requests summary</TableCell>
                    <TableCell className="column-header pe-4 pt-4" align="right">
                      Counts
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {STATIC_SUMMARY_ITEMS.map(item => (
                    <TableRow
                      key={item.key}

                    >
                      <TableCell className="dashboard-table-cell ps-4 py-3">
                        {useDemoData
                          ? item.name
                          : (
                            <a
                              href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/filter?${item.param}=true`}
                              target="_blank"
                            >
                            {item.name}
                            </a>
                          )
                        }
                      </TableCell>
                      <TableCell align="right" className="dashboard-table-cell pe-4 py-3">
                        {useDemoData
                          ? summaryItems[item.key]
                          : (
                            <a
                              href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/filter?${item.param}=true`}
                              target="_blank"
                            >
                            {summaryItems[item.key]}
                            </a>
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

RequestsBreakdownEducationCard.propTypes = {};

export default RequestsBreakdownEducationCard;

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Requests quick filters:
    </p>
    <p className="tooltip-paragraph">
      By using the <b>help wanted?</b>, <b>quick win?</b>, and
      <b>broken window?</b> fields when managing a request, you support easily
      filtering requests in a workspace.
    </p>
  </div>
);
