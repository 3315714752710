import React from 'react';
import withRouter from '../../util/with_router';
// import ChannelListContainer from './channel_list/channel_list_container';

class PublicChannelForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newChannelName: this.props.isEditMode || this.props.isViewMode ? this.props.channelName : '',
      description: this.props.isEditMode || this.props.isViewMode ? this.props.description : '',
    };
    this.handlePublicChannelSubmit = this.handlePublicChannelSubmit.bind(this);
  }

  handlePublicChannelSubmit(e) {
    const channel = {
      name: this.state.newChannelName,
      description: this.state.description,
    };

    const { isEditMode } = this.props;

    const response = isEditMode
      ? this.props.processEdit({ channel })
      : this.props.processForm({ channel });

    if (!isEditMode) {
      const { workspaceId } = this.props;

      response.done(newChannelId => {
        newChannelId && this.props.navigate(`/workspaces/${workspaceId}/requests/${newChannelId}`);
      });
    }

    this.props.closeModal();
  }

  update(field) {
    return (e) => this.setState({
      [field]: e.target.value
    });
  };

  render() {
    const {
      isEditMode,
      isViewMode,
      isEducationOrganizationPersona,
      onClose,
    } = this.props;

    const subjectPlaceholder = isEducationOrganizationPersona
      ? 'example: Does anyone have resources for teaching American History?'
      : 'example: Two factor authentication';

    const subjectDescription = isEducationOrganizationPersona
      ? `example: I'm teaching the American Revolution next week and require resources in the teaching of this unit.`
      : 'example: Our enterprise customers absolutely require this feature before we can move forward with a renewal.'

    return (
      <div className="confirm-modal custom-ui create-channel-form">
        {isEditMode ? (
          <div className="form-header header mb-4">
            Edit the <span className="emphasis">Request</span>
          </div>
        ) : isViewMode ? (
          <div className="form-header header mb-4">
            View the <span className="emphasis">Request</span>
          </div>
        ) : (
          <div className="form-header header mb-4">
            Create a new <span className="emphasis">Request</span>
          </div>
        )}
        <form className="d-flex flex-column" onSubmit={this.handlePublicChannelSubmit}>
          <div className="mb-4 text-start">
            <label className="mb-2">Subject</label>
            <input
              type="text"
              className="form-control"
              value={this.state.newChannelName}
              onChange={this.update('newChannelName')}
              placeholder={subjectPlaceholder}
              disabled={isViewMode}
            />
          </div>
          <div className="mb-4 text-start">
            <label className="mb-2">Description (optional)</label>
            <textarea
              className="form-control mb-3"
              value={this.state.description}
              onChange={this.update('description')}
              placeholder={subjectDescription}
              disabled={isViewMode}
              rows="8"
            />
          </div>
          <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
            {isViewMode ? null : (
              <input
                type="submit"
                value={isEditMode ? 'Update' : 'Create'}
                className="py-3 mb-2 confirm-submit-button"
              />
            )}
            <button
              type="button"
              className="btn py-3 pb-0 m-0 close-button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(PublicChannelForm);
