import React from 'react';

const BaseRadioButtons = ({
  onChange,
  options,
  defaultValue,
  name,
  className,
  styleOptions = {},
}) => {

  const radioOptions = options.map(option => (
    <div className="radio-button-container" key={option.value}>
      <input
        type="radio"
        name={name}
        value={option.value}
        id={`${name}-${option.label}`}
        className={className}
        checked={defaultValue === option.value}
        onChange={onChange}
      />
      <label htmlFor={`${name}-${option.label}`}>{option.label}</label>
    </div>
  ))

  return (
    <div className="radio-buttons-container">{radioOptions}</div>
    );
};

export default BaseRadioButtons;
