import { paramsToQueryString } from '../services/util/helpers';

export const fetchChannels = ({ organizationId, workspaceId, filters }) => {
  const queryString = paramsToQueryString(filters);

  const url = !queryString
    ? `/api/organizations/${organizationId}/channels?workspace_id=${workspaceId}`
    : `/api/organizations/${organizationId}/channels?workspace_id=${workspaceId}&${queryString}`;

  return $.ajax({
    url: url,
  });
};

export const fetchChannelsSummary = ({ organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/channels_summary?workspace_id=${workspaceId}`,
  });
};

export const fetchChannel = ({ id, organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/channels/${id}?workspace_id=${workspaceId}`,
  });
};

export const fetchChannelFavorites = ({ id, organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/channels/${id}/favorites?workspace_id=${workspaceId}`,
  });
};

export const favoriteChannel = ({ channelId, organizationId, workspaceId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/channels/${channelId}/favorites`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: { workspace_id: workspaceId }
  });
};

export const unfavoriteChannel = ({ favoriteId, channelId, organizationId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/channels/${channelId}/favorites/${favoriteId}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'DELETE',
  });
};

export const createChannel = ({ organizationId, workspaceId, channel }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/channels`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: { channel, workspace_id: workspaceId }
  });
};

export async function createChannelWithoutRedux({
  organizationId,
  workspaceId,
  channel,
}) {
  const url = `/api/organizations/${organizationId}/channels`;
  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify({
      channel,
      workspace_id: workspaceId,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
};

export const updateChannel = ({ channel, organizationId, workspaceId, id }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/channels/${id}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'PUT',
    contentType: 'application/json',
    data: JSON.stringify({ channel, workspace_id: workspaceId })
  });
};

export const removeChannel = ({ organizationId, workspaceId, id }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/channels/${id}?workspace_id=${workspaceId}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'DELETE'
  });
};

export async function handleUserMentions({
  organizationId,
  workspaceId,
  channelId,
  mentions,
  text,
}) {
  const response = await fetch(`/api/organizations/${organizationId}/channels/${channelId}/handle_mentions`, {
    method: 'post',
    body: JSON.stringify({
      workspace_id: workspaceId,
      mentions,
      text,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getTagsForChannels({
  organizationId,
  workspaceId,
}) {
  const params = { workspace_id: workspaceId };
  const url = `/api/organizations/${organizationId}/channels/tags?${paramsToQueryString(params)}`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getCountForChannels({
  organizationId,
  workspaceId,
}) {
  const params = { workspace_id: workspaceId };
  const url = `/api/organizations/${organizationId}/channels/count?${paramsToQueryString(params)}`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function deliverCustomerFeedbackToContactImmed({
  name,
  existingFeaturePath,
  description,
  organizationId,
}) {
  const url = `/api/organizations/${organizationId}/contact_immed_feedbacks`

  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify({
      name,
      existing_feature_path: existingFeaturePath,
      description,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}
