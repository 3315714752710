import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";

export const WhenFieldChanges = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      // declarative-form-rules from erikras article
      { input: { onChange } }
    ) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {value => {
              if (Array.isArray(value)) {
                if (JSON.stringify(value) === JSON.stringify(becomes)) {
                  onChange(to);
                }
              } else {
                if (value === becomes) {
                  onChange(to);
                }
              }

            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);
