
export async function upsertConfiguration({
  organization_id,
  idp_sso_target_url,
  idp_entity_id,
  assertion_consumer_service_binding,
  name_identifier_format,
  idp_cert_fingerprint,
  idp_cert_fingerprint_algorithm,
  idp_cert,
}) {
  const response = await fetch(`/organizations/${organization_id}/idp_setups`, {
    method: 'post',
    body: JSON.stringify({
      idp_sso_target_url,
      idp_entity_id,
      assertion_consumer_service_binding,
      name_identifier_format,
      idp_cert_fingerprint,
      idp_cert_fingerprint_algorithm,
      idp_cert,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateConfiguration({
  id,
  organization_id,
  idp_sso_target_url,
  idp_entity_id,
  assertion_consumer_service_binding,
  name_identifier_format,
  idp_cert_fingerprint,
  idp_cert_fingerprint_algorithm,
  idp_cert,
}) {
  const response = await fetch(`/organizations/${organization_id}/idp_setups/${id}`, {
    method: 'put',
    body: JSON.stringify({
      idp_sso_target_url,
      idp_entity_id,
      assertion_consumer_service_binding,
      name_identifier_format,
      idp_cert_fingerprint,
      idp_cert_fingerprint_algorithm,
      idp_cert,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function enableConfiguration({
  id,
  organization_id,
  disabled,
}) {
  const response = await fetch(`/organizations/${organization_id}/idp_setups/${id}/enable`, {
    method: 'put',
    body: JSON.stringify({ disabled }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}
