export async function loginWithPassword({ email, password, otpAttempt, hash }) {
  const response = await fetch('/users/sessions', {
    method: 'post',
    body: JSON.stringify({
      session: {
        email,
        password,
        otp_attempt: otpAttempt,
        hash,
      }
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  }).catch((error) => {
    return;
  });
  return response;
};

export const loginWithClever = ({ hash }) => {
  return $.ajax({
    url: `/users/clever/oauth2/omniauth/authorize`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: { hash },
  });
};

export const loginWithGoogle = ({ hash }) => {
  return $.ajax({
    url: `/users/google/oauth2/omniauth/authorize`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: { hash },
  });
};

export const signupWithEmail = ({ email }) => {
  return $.ajax({
    url: `/users/registrations`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: {
      registrations: {
        email,
      },
    },
  });
};

export async function setPasswordFromInvitation({
  password,
  passwordConfirmation,
  invitationToken,
}) {
  const response = await fetch('/users/invitation/accept', {
    method: 'put',
    body: JSON.stringify({
      invite: {
        password,
        password_confirmation: passwordConfirmation,
        invitation_token: invitationToken,
      }
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  }).catch((error) => {
    return;
  });
  return response;
};
