import React from 'react';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';
import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class Profile extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUserOrganization, currentUser, currentLocale } = this.props;
    const { name, isSsoEnabled } = currentUserOrganization || {};

    return (
      <div className='main contactimmed-main'>
        <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale} />
        <ContentContainer
          organizationName={name}
          isSsoEnabled={isSsoEnabled}
          // isCurrentUserAdmin={isCurrentUserAdmin},
        />
      </div>
    );
  }
}

export default withRouter(Profile);
