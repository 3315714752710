import React from 'react';

import withRouter from '../../../util/with_router';

// const summaryCount = list =>
//   (list || []).length;

const summaryCount = count => count || 0;

class ChannelListSummary extends React.Component {

  constructor(props) {
    super(props);
    this.handleSummaryClick = this.handleSummaryClick.bind(this);
  }

  handleSummaryClick(filter) {
    const { workspaceId } = this.props;
    const { filterParam, paramValue } = filter;

    if (!filterParam) {
        this.props.navigate('');
    }
    //this.props.navigate(`/workspaces/${workspaceId}/requests/${id}/thread`);
    filterParam
      ? this.props.navigate(`/workspaces/${workspaceId}/requests/filter?${filterParam}=${paramValue}`)
      : this.props.navigate(`/workspaces/${workspaceId}/requests/filter?requests=all`)
  }

  render() {
    const {
      currentUser,
      channelsSummary,
      isEducationOrganizationPersona,
    } = this.props;

    return(
      <div className="channel-list-summary">
        <ul>
          <li
            className="list-summary-item"
            onClick={() => this.handleSummaryClick({})}
          >
            <div className="summary-name">All active:</div>
            <div className="summary-count">
              {(channelsSummary?.total) || 0}
            </div>
          </li>
          {!isEducationOrganizationPersona && (
            <>
              <li
                className="list-summary-item"
                onClick={() => this.handleSummaryClick({ filterParam: 'help_wanted', paramValue: true })}
              >
                <div className="summary-name">Help Wanted:</div>
                <div className="summary-count">
                  {summaryCount(channelsSummary?.help_wanted)}
                </div>
              </li>
              <li
                className="list-summary-item"
                onClick={() => this.handleSummaryClick({ filterParam: 'quick_win', paramValue: true })}
              >
                <div className="summary-name">Quick Wins:</div>
                <div className="summary-count">
                  {summaryCount(channelsSummary?.quick_wins)}
                </div>
              </li>
              <li
                className="list-summary-item"
                onClick={() => this.handleSummaryClick({ filterParam: 'broken_window', paramValue: true })}
              >
                <div className="summary-name">Broken Windows:</div>
                <div className="summary-count">
                  {summaryCount(channelsSummary?.broken_windows)}
                </div>
              </li>
            </>
          )}
          {isEducationOrganizationPersona && (
            <>
              <li
                className="list-summary-item"
                onClick={() => this.handleSummaryClick({ filterParam: 'safety_issue', paramValue: true })}
              >
                <div className="summary-name">Safety Issues:</div>
                <div className="summary-count">
                  {summaryCount(channelsSummary.safety_issues)}
                </div>
              </li>
              <li
                className="list-summary-item"
                onClick={() => this.handleSummaryClick({ filterParam: 'alert', paramValue: true })}
              >
                <div className="summary-name">Alerts:</div>
                <div className="summary-count">
                  {summaryCount(channelsSummary.alerts)}
                </div>
              </li>
              <li
                className="list-summary-item"
                onClick={() => this.handleSummaryClick({ filterParam: 'resource_request', paramValue: true })}
              >
                <div className="summary-name">Resource Requests:</div>
                <div className="summary-count">
                  {summaryCount(channelsSummary?.resource_requests)}
                </div>
              </li>
              <li
                className="list-summary-item"
                onClick={() => this.handleSummaryClick({ filterParam: 'maintenance', paramValue: true })}
              >
                <div className="summary-name">Maintenance:</div>
                <div className="summary-count">
                  {summaryCount(channelsSummary.maintenance)}
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }
}

export default withRouter(ChannelListSummary);
