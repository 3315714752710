import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchOrganizationWorkspace } from '../../../actions/organization_actions';

const msp = (state, ownProps) => {
  return {
    currentUser: state.entities.currentUser,
    workspace: state.entities.organization.workspace,
    workspaceId: ownProps.match.params.workspaceId,
    permittedWorkspaceRoles: ownProps.permittedWorkspaceRoles,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchWorkspace: ({ workspaceId }) =>
      dispatch(fetchOrganizationWorkspace({ organizationId, workspaceId })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
