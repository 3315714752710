import React from 'react';
import Modal from 'react-modal';

import currencyCodesHash from '../../../../components/util/currency_codes_hash.json';
import { ellipsis } from '../../../../components/util/helpers';

class ChannelHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.handleLike = this.handleLike.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    this.props.fetchChannel(this.props.match.params.channelId);
    this.props.fetchUsers();
  }

  handleLike() {
    const { channelId, channelFavorites } = this.props;
    const isFavoritedByCurrentUser = channelFavorites.is_favorited_by_current_user;
    const favoriteId = channelFavorites.favorite_id;

    if (isFavoritedByCurrentUser) {
      this.props.unlikeChannel({ channelId, favoriteId });
    } else {
      this.props.likeChannel({ channelId });
    }
  }

  render() {
    const {
      channel,
      currentUser,
      channelFavorites,
      workspaceId,
      channelId,
    } = this.props;
    const channelName = channel?.name || '';
    const isQuickWin = channel?.quick_win;
    const opportunityValue = channel?.opportunity_value;
    const opportunityValueCurrency = channel?.opportunity_value_currency;
    const currencySymbol = opportunityValue && currencyCodesHash[opportunityValueCurrency];
    const isFavoritesForChannel = channelFavorites && channelFavorites.channel_id === channelId;
    const isFavoritedByCurrentUser = channelFavorites.is_favorited_by_current_user;

    const isLikedClassName = isFavoritedByCurrentUser
      ? 'is-liked'
      : 'is-not-liked';

    const likeIconClassName = isFavoritedByCurrentUser
      ? 'bi bi-hand-thumbs-up-fill is-liked'
      : 'bi bi-hand-thumbs-up';

    return (
      <div className="channel-header py-3 ps-3">
        <div className="column icon">
          {isQuickWin && (
            <i className="bi bi-lightning-fill fa-bolt"></i>
          )}
        </div>
        <div className="column channel-summary pe-3">
          <div className="channel-title-container">
            <div className="channel-title">
              <div>
                {ellipsis({ string: channelName, maxLength: 60 })}
              </div>
              <i
                className="bi bi-pencil ms-2"
                onClick={() => this.props.openModal('new_public_channel', {
                  currentUser,
                  workspaceId,
                  channelId,
                  channelName: channelName,
                  description: channel?.description,
                  isEditMode: true,
                })}
              ></i>
            </div>
            <div className="channel-participation">
              {isFavoritesForChannel ? (
                <div className="likes-container" onClick={() => this.handleLike()}>
                  <div className={isLikedClassName}>
                    <i className={likeIconClassName}></i>:
                  </div>
                  <div className={`count ${isLikedClassName}`}>
                    {channelFavorites.favorited_count}
                  </div>
                </div>
              ) : (
                <div className="likes-container">
                  <div className="is-not-liked">
                    <i className="bi bi-hand-thumbs-up"></i>:
                  </div>
                  <div className="count is-not-liked">0</div>
                </div>
              )}
              {/*
                <div className="channel-users">
                  <i className="bi bi-person"></>
                  <span className="channel-header-numUsers">
                    {this.props.numUsers(this.props.match.params.channelId)}
                  </span>
                </div>
              */}
            </div>
          </div>
          <div className="channel-header-details-container">
            <div className="channel-description-container">
              <div className="label">Description</div>
              <div className="description">
                {ellipsis({ string: channel?.description || 'Description', maxLength: 99 })}
                {channel?.description?.length > 99 ? (
                  <i
                    className="bi bi-eyeglasses open-description-icon"
                    onClick={() => this.props.openModal('new_public_channel', {
                      currentUser,
                      workspaceId,
                      channelId,
                      channelName: channelName,
                      description: channel?.description,
                      isViewMode: true,
                    })}
                  ></i>
                ): null}
              </div>
            </div>
            {opportunityValue && (
              <div className="opportunity-value">
                {`${currencySymbol || ''} ${opportunityValue.toLocaleString()}`}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ChannelHeader;
