import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RoadClosedImage from 'images/road_closed.png';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';

class NotAuthorized extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currentLocale, currentUser } = this.props;
    return (
      <div className='main contactimmed-main'>
        <HorizontalNavigation
          currentUser={currentUser}
          currentLocale={currentLocale}
        />
        <div className="error-403-page">
          <h1 className="title">403 - Forbidden</h1>
          <div className="subtitle">
            <h1>Stop! Road Block Ahead</h1>
          </div>
          <div className="container">
            <div className="message">
              <p>
                Sorry. Only authorized traffic allowed to go through...
              </p>
              <p>
                This is for your safety. We've set up a detour sign that will
                get you home.
              </p>
              <div className="return-to">
                <p><a href={`/#/${currentLocale}`}>Home Page</a></p>
              </div>
              <p>
                You can also check the <span className="nav-bar">Navigation Bar</span>
                {' '}and filter some items by keyword to quickly find what you
                were looking for.
              </p>
            </div>
            <div className="image">
              <img
                src={RoadClosedImage}
                alt="ContactImmed Not Authorized"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NotAuthorized.propTypes = {};

export default NotAuthorized;
