import React, { Fragment } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import withRouter from '../../../util/with_router';

import RequestsBreakdownEducationCard from '../widgets/RequestsBreakdownEducationCard';
import RequestsWatchedByMe from '../widgets/RequestsWatchedByMe';
import CustomSummaryCard from '../widgets/CustomSummaryCard';
import PulseOfTheCustomerCard from '../widgets/PulseOfTheCustomerCard';
import OpportunityValueBySizeCard from '../widgets/OpportunityValueBySizeCard';
import AssetChurnRiskAssessmentCard from '../widgets/AssetChurnRiskAssessmentCard';
import UnresolvedRequestsNearRenewal from '../widgets/UnresolvedRequestsNearRenewal';
import RequestsCreatedOverTime from '../widgets/RequestsCreatedOverTime';
import RequestsStatusSummary from '../widgets/RequestsStatusSummary';
import AtRiskCustomersCard from '../widgets/AtRiskCustomersCard';
import RequestsByCustomerRiskLevelCard from '../widgets/RequestsByCustomerRiskLevelCard';
import RequestsStatusByDates from '../widgets/RequestsStatusByDates';

class EducationAdministratorsDashboardWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {

    const {
      currentLocale,
      currentUser,
      isCurrentUserAccountAdmin,
      isEducationOrganizationPersona,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      openModal,
      useDemoData,
      workspaceId,
    } = this.props;

    return (
      <div className="primary-dashboard">
        <Row>
          <Col>
            <RequestsCreatedOverTime
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
          <Col>
            <RequestsStatusSummary
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RequestsBreakdownEducationCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
          <Col md={6}>
            <CustomSummaryCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RequestsWatchedByMe
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(EducationAdministratorsDashboardWidgets);
