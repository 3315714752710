import { connect } from 'react-redux';
import React from 'react';
import ActivationWizardModal from './activation_wizard_modal';
import { createChannel } from '../../actions/channel_actions';

const mapStateToProps = (state, ownProps) => {
  const { entities } = state;

  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    channedId,
    currentUser,
    workspaceId,
  } = ownProps;

  const { organization_id } = currentUser;

  return {
    processForm: ({ channel }) => dispatch(
      createChannel({ organizationId: organization_id, workspaceId, channel }),
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationWizardModal);
