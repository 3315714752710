import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { sleep } from '../../../components/util/helpers';
import { disableTwoFactor } from '../../../services/user';
import AboutPageDrawer from '../../AboutDrawers/SecuritySettingsPage/AboutPageDrawer';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAboutPageDrawerOpen: false,
    };
    this.handleDisableTwoFactor = this.handleDisableTwoFactor.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    const { currentUser } = this.props;
    const organizationId = currentUser?.organization_id;
    this.props.getCurrentUser({ organizationId });
  }

  handleDisableTwoFactor = async () => {
    await sleep(300);
    const { currentUser } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = await disableTwoFactor();
    if (!(response || {}).error) {
      // refresh the page
      this.props.getCurrentUser({ organizationId });
      this.props.navigate(0);
      // location.reload(true);
    } else {
      this.props.navigate(0);
    }
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {
    const { currentUser, currentLocale, location } = this.props;
    const twoFactorEnabledSuccessfully = location?.state?.twoFactorEnabledSuccessfully;

    const isTwoFactorRequired =
      currentUser.otp_required_for_login || twoFactorEnabledSuccessfully;

    return (
      <div className="settings user-profile security container-fluid vh-100 px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Security</div>
          <div className="subheader">
            <div className="page">Security</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
            {false && (
              <div
                className="pro-tip-button"
                onClick={() => this.handleOnClickOpenAboutPageDrawer()}
              >
                Pro Tip
              </div>
            )}
          </div>
        </div>
        <div className="content-container">
          <div className="security-item">
            <div className="sub-header">Two Factor Settings</div>
            <div>
              {isTwoFactorRequired ? (
                <div
                  className="change-password-link"
                  onClick={this.handleDisableTwoFactor}
                >
                  Disable
                </div>
              ) : (
                <a
                  className="change-password-link"
                  href={`/#/${currentLocale}/settings/two-factor-settings`}
                >
                  Enable
                </a>
              )}
            </div>
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings security'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;
