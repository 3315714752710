import React from "react";
import { Form, Field } from 'react-final-form';
import PropTypes from "prop-types";
import { QRCodeSVG } from 'qrcode.react';
import { sleep } from '../../../components/util/helpers';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactorSettingsBackupCodes: {},
    };
  }

  componentDidMount() {
    this.props.fetchTwoFactorSettingsBackupCodes()
      .then(data =>
        this.setState({ twoFactorSettingsBackupCodes: data.twoFactorSettingsBackupCodes })
      )
      .catch(error =>
        this.setState({
          twoFactorSettingsBackupCodes: { isError: true, status: error.status, message: error.message }
        })
      );
  }

  render() {
    const { currentUser, currentLocale } = this.props;
    const { twoFactorSettingsBackupCodes } = this.state;

    const {
      isError,
      status,
      backup_codes,
      backup_codes_already_viewed,
    } = twoFactorSettingsBackupCodes;

    if (isError && status === 403) {
      return (
        <div className="settings two-factor-settings container-fluid px-5">
          <div className="settings-content card-container">
            <div className="card">
              <div className="two-factor-settings-new">
                <div className="header">
                  <h2>Two factor is not enabled or error rendering backup codes.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (isError) {
      return (
        <div className="settings two-factor-settings container-fluid px-5">
          <div className="settings-content card-container">
            <div className="card">
              <div className="two-factor-settings-new">
                <div className="header">
                  <h2>Error preparing backup codes. Please try again.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (backup_codes || backup_codes_already_viewed) {
      return (
        <div className="settings two-factor-settings container-fluid px-5">
          <div className="settings-content card-container">
            <div className="card">
              <div className="two-factor-settings-edit">
                <div className="header">
                  <h2>Two Factor Authentication</h2>
                </div>
                <div className="card-deck">
                  <div className="card">
                    <div className="card-header">
                      Backup Codes
                    </div>
                    <div className="card-body">
                      <p>
                        Keep these backup codes safe in case you lose access to your
                        authenticator app:
                      </p>
                      <hr />

                      <div className="backup-codes-container">
                        {backup_codes_already_viewed && (
                          <div className="codes-already-viewed">
                            You've already viewed your backup codes.
                          </div>
                        )}

                        {backup_codes && backup_codes.map(code =>
                          <div className="backup-codes">
                            <div className="code">{code}</div>
                          </div>
                        )}
                      </div>
                      <hr />
                      <p>
                        Treat your recovery codes with the same level of attention as you
                        would your password! We recommend saving them with a password
                        manager:
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      Two Factor Setup Complete
                    </div>
                    <div className="card-body">
                      <p>Two factor authentication has been successfully enabled.</p>
                      <a className="" href={`/#/${currentLocale}/settings/security`}>
                        Return to security settings
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

Content.propTypes = {};

export default Content;
