export async function uploadMediaFileToS3({
  organizationId,
  workspaceId,
  formData,
}) {
  const url = `/api/organizations/${organizationId}/workspaces/${workspaceId}/media_files`;
  const response = await fetch(url, {
    method: 'post',
    body: formData,
    headers: {
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
};
