import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  isEmailValid,
  sleep,
  toastifyConfiguration,
} from '../../../components/util/helpers';
import { upsertUsersInBulk } from '../../../services/organization';

toast.configure();

const BULK_ADD_LIMIT = 25;

const WORKSPACE_MANAGER_VALUE = 'workspace_manager';
const WORKSPACE_MANAGER_LABEL = 'Manager';
const WORKSPACE_USER_VALUE = 'workspace_user';
const WORKSPACE_USER_LABEL = 'User';
const ACCOUNT_ADMIN_VALUE = 'account_admin';
const ACCOUNT_ADMIN_LABEL = 'Account Admin';
const ACCOUNT_USER_VALUE = 'account_user';
const ACCOUNT_USER_LABEL = 'Account User';

const PERMITTED_WORKSPACE_ROLES = [
  {
    value: WORKSPACE_MANAGER_VALUE,
    label: WORKSPACE_MANAGER_LABEL,
  },
  {
    value: WORKSPACE_USER_VALUE,
    label: WORKSPACE_USER_LABEL,
  },
];

const PERMITTED_ACCOUNT_LEVEL_ROLES = [
  {
    value: ACCOUNT_ADMIN_VALUE,
    label: ACCOUNT_ADMIN_LABEL,
  },
  {
    value: ACCOUNT_USER_VALUE,
    label: ACCOUNT_USER_LABEL,
  },
];

const workspacesWithRolesOptions = workspacesAvailable => {
  return workspacesAvailable.map(workspace => {
    return [
      {
        value: { workspace_id: workspace.id, role: WORKSPACE_USER_VALUE },
        label: `${workspace.name}: ${WORKSPACE_USER_LABEL} role`,
      },
      {
        value: { workspace_id: workspace.id, role: WORKSPACE_MANAGER_VALUE },
        label: `${workspace.name}: ${WORKSPACE_MANAGER_LABEL} role`,
      }
    ];
  }).flat();
};

const isUserNotGivenAccountAdminRole = ({ index, values }) => {
  if (!values) {
    return true;
  }

  const { userAttributes } = values;
  if (!userAttributes) {
    return true;
  }

  const userObject = userAttributes[index];
  return userObject?.accountRole?.value !== ACCOUNT_ADMIN_VALUE;
};

class BulkAddUsersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitAttempted: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleTrackingSubmit = this.handleTrackingSubmit.bind(this);
    this.handleAddAdditionalUser = this.handleAddAdditionalUser.bind(this);
    this.handleRemoveAdditionalUser = this.handleRemoveAdditionalUser.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      currentUser,
      defaultPage,
      defaultPageSize,
      handleGetPaginatedUsers,
      onClose,
    } = this.props;
    const organizationId = currentUser?.organization_id;

    const userParams = {
      organizationId,
      users: values.userAttributes?.map(user => ({
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        account_roles: [user.accountRole.value],
        ...(user.workspaceWithRole?.length > 0 && {
          workspaces_with_roles: user.workspaceWithRole.map(obj => obj.value),
        }),
      })).filter(user => user),
    }

    const response = await upsertUsersInBulk(userParams);

    if (response?.non_existing_users_added) {
      await handleGetPaginatedUsers(defaultPage, defaultPageSize);
      toast.success(`Users added successfully`, toastifyConfiguration({}));
      onClose();
    } else {
      toast.error(`Error adding users`, toastifyConfiguration({}));
    }

  }

  handleTrackingSubmit = () => {
    this.setState({ submitAttempted: true });
  }

  handleAddAdditionalUser = (push, usersLength) => {
    if (usersLength < BULK_ADD_LIMIT) {
      push('userAttributes', undefined);
    }
  }

  handleRemoveAdditionalUser = (fields, indexOfItemToRemove) => {
    const path = (fields.value || [])[indexOfItemToRemove];
    fields.remove(indexOfItemToRemove);
  }

  // NESTED FORMS WITH ARRAYS
  // https://medium.com/ @erikras/ final-form-arrays-and-mutators-13159cb7d285

  render () {
    const { submitAttempted } = this.state;
    const {
      canAddWorkspaces,
      isCurrentUserAccountAdmin,
      onClose,
      workspacesAvailable,
    } = this.props;

    const initialUserAttributesValue = [null];
    const initialAccountRoleValue = { value: ACCOUNT_USER_VALUE, label: ACCOUNT_USER_LABEL };
    const firstWorkspace = (workspacesAvailable || [])[0];
    const initialWorkspaceWithRolesOption = [{
      value: { workspace_id: firstWorkspace?.id, role: WORKSPACE_USER_VALUE },
      label: `${firstWorkspace?.name}: ${WORKSPACE_USER_LABEL} role`,
    }];

    return (
      <div className="bulk-add-users-form confirm-modal custom-ui">

        <Form
          onSubmit={this.handleOnSubmit}
          mutators={{
            ...arrayMutators
          }}
          render={({
            errors,
            handleSubmit,
            form: {
              mutators: { push }
              // mutators: { push, pop, unshift }
            }, // injected from final-form-arrays above
            form,
            submitting,
            values
          }) => (
            <div className="inner-form-container">
              <form onSubmit={handleSubmit}>
                <div className="field user-attributes">
                  <FieldArray name="userAttributes" initialValue={initialUserAttributesValue} validate={required}>
                    {({ fields }) => (
                      <div className="user-builder-container">
                        <h3 className="header text-start">Bulk add users<sup className="sup bulk-add ms-1">*up to 25 per batch</sup></h3>
                          <div className="user-content-container text-start">
                            <div className="content">
                              <div className="user-column">
                                <div className="buttons button-actions add-user">
                                  <button
                                    className="button primary small add-new ms-0"
                                    type="button"
                                    onClick={() => this.handleAddAdditionalUser(push, fields.length)}
                                  >
                                    Add additional row
                                  </button>
                                </div>
                              </div>
                              <div className="user-fields-container">
                                {fields.map((name, parentIndex) => (
                                  <Fragment key={name}>
                                    <div className="fields mt-3">
                                      <div className="d-flex align-items-start ">
                                        <div className="field-container">
                                          <label className="mb-1">First name</label>
                                          <Field
                                            name={`${name}.firstName`}
                                          >
                                            {({ input, meta }) => (
                                              <Fragment>
                                                <p className="field first-name d-flex flex-column" style={{ marginBottom: 0 }}>
                                                  <input {...input} type="text" className="form-control" placeholder="first name" />
                                                  {meta.error && meta.touched && <span>{meta.error}</span>}
                                                </p>
                                              </Fragment>
                                            )}
                                          </Field>
                                        </div>
                                        <div className="field-container ms-2">
                                          <label className="mb-1">Last name</label>
                                          <Field name={`${name}.lastName`}>
                                            {({ input, meta }) => (
                                              <p className="field last-name d-flex flex-column" style={{ marginBottom: 0 }}>
                                                <input {...input} type="text" className="form-control" placeholder="last name" />
                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                              </p>
                                            )}
                                          </Field>
                                        </div>
                                        <div className="field-container ms-2">
                                          <label className="mb-1">Email</label>
                                          <Field
                                            name={`${name}.email`}
                                            validate={composeValidators(required, mustBeValidEmail)}
                                          >
                                            {({ input, meta }) => (
                                              <p className="field email d-flex flex-column" style={{ marginBottom: 0, width: 300 }}>
                                                <input {...input} type="text" className="form-control" placeholder="email" />
                                                {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                                              </p>
                                            )}
                                          </Field>
                                        </div>
                                        {isCurrentUserAccountAdmin && (
                                          <div className="field-container ms-2">
                                            <label className="mb-1">Account role</label>
                                            <Field
                                              name={`${name}.accountRole`}
                                              validate={requiredSelect}
                                              initialValue={initialAccountRoleValue}
                                              component={ReactSelectAdapter}
                                              className="account-level-role"
                                              options={PERMITTED_ACCOUNT_LEVEL_ROLES.map(role => ({
                                                value: role.value, label: role.label
                                              }))}
                                            />
                                          </div>
                                        )}
                                        <div className="field-container ms-2">
                                          <label
                                            className="mb-1"
                                            style={{ visibility: 'hidden' }}
                                          >
                                            Remove
                                          </label>
                                          <div
                                            className="remove-user-object d-flex align-items-center justify-content-center p-0 ms-2 mb-0"
                                            type="button"
                                            style={{ height: 38 }}
                                            onClick={() => this.handleRemoveAdditionalUser(fields, parentIndex)}
                                          >
                                            Remove user
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-end">
                                        <div className="field-container">
                                          <label className="mb-1 mt-2">Select workspace(s) access</label>
                                          {isUserNotGivenAccountAdminRole({ index: parentIndex, values }) ? (
                                            <Field
                                              isMulti
                                              name={`${name}.workspaceWithRole`}
                                              validate={requiredSelectForWorkspace}
                                              // initialValue={initialWorkspaceWithRolesOption}
                                              component={ReactSelectAdapter}
                                              className="workspace-with-role"
                                              options={workspacesWithRolesOptions(workspacesAvailable)}
                                            />
                                          ) : (
                                            <div className="account-admin-notice mt-1">
                                              Account admin have full access to workspaces
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </FieldArray>
                </div>
                <div className="d-flex align-items-center mt-4">
                  {submitting ? (
                    <button
                      disabled={true}
                      className="button submit-button submitting ms-0 d-flex align-items-center justify-content-center"
                    >
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      <span>Submitting...</span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="button submit-button ms-0"
                      disabled={submitting}
                      onClick={this.handleTrackingSubmit}
                    >
                      Submit
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn close-button ms-0"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  {/*
                    <div className="button submit-button text-start mt-4">
                      <input onClick={this.handleTrackingSubmit} type="submit" name="commit" value="Submit" disabled={submitting} />
                    </div>
                  */}

                </div>
              </form>
            </div>
          )}
        />
      </div>
    );
  }
}

BulkAddUsersForm.propTypes = {
  error: PropTypes.bool
};
export default BulkAddUsersForm;

// private

const ReactSelectAdapter = ({ input, meta, ...rest }) => (
  <div className={`field select ${rest?.className}`}>
    <Select {...input} {...rest} searchable placeholder={rest && rest.placeholder}/>
    {meta.error && meta.touched && <span className="error">{meta.error}</span>}
  </div>
)

const required = value => value ? undefined : 'Required';
const requiredSelect = value => value && value.value ? undefined : 'Required';
const requiredSelectForWorkspace = value => value && value?.length > 0 ? undefined : 'Required';
const mustBeValidEmail = value => isEmailValid(value) ? undefined : 'Must be a valid email';
const maxLength = (str, max) => str && str.length > max;

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
