import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import DefaultAvatar from 'images/default_user_avatar.svg';

import withRouter from '../../../../util/with_router';
import { fetchMessagesMetadata } from '../../../../services/message_api';
import MessageActions from './message_actions';
import MessageFormContainer from '../message_form/message_form_container';

const DEFAULT_PAGE_SIZE = 25;

const messageCreatedAt = createdAt => {
  const date = new Date(createdAt);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
}

const wasMessageEdited = ({ message }) => {
  return message.created_at !== message.updated_at;
};

class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMessageId: '',
      ignoreScrollToBottom: false,
      chatListPageSize: DEFAULT_PAGE_SIZE,
      chatListTotalCount: 0,
      chatListTotalPages: 0,
      chatListCurrentPage: 1,
      isMessagesLoading: true,
      initialMessagesLoading: true,
    };

    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handleDeleteMessage = this.handleDeleteMessage.bind(this);
    this.handleSetEditMessageId = this.handleSetEditMessageId.bind(this);
    this.handleClearingEditMessageId = this.handleClearingEditMessageId.bind(this);
    this.handleLoadMoreMessages = this.handleLoadMoreMessages.bind(this);
  }

  async componentDidMount() {
    const { currentUser, channel, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    if (channel) {
      this.props.fetchMessages({
        organizationId,
        channelId: channel?.id,
        pageSize: DEFAULT_PAGE_SIZE,
      });
    }

    this.scrollToBottom();
    // NOTE: To get pagination metadata. Including it in the original request
    // breaks the json builder
    const metadata = channel
      ? await fetchMessagesMetadata({
        organizationId,
        workspaceId: workspaceId,
        channelId: channel?.id,
        returnCount: true,
        pageSize: DEFAULT_PAGE_SIZE,
      })
      : {};

    this.setState({
      isMessagesLoading: false,
      initialMessagesLoading: false,
      chatListTotalCount: metadata?.total_count,
      chatListTotalPages: metadata?.total_pages,
    });
  }

  componentDidUpdate(beforeProps) {
    if (JSON.stringify(beforeProps.getChannelMessages) !== JSON.stringify(this.props.getChannelMessages)) {
      this.scrollToBottom();
    }
  }

  handleDeleteMessage({ message }, event) {
    event.preventDefault();
    const { currentUser } = this.props;
    const organizationId = currentUser?.organization_id;
    this.props.deleteMessage({
      organizationId,
      messageId: message.id,
      channelId: message.channel_id,
    });
  }

  handleSetEditMessageId({ message }, event) {
    event.preventDefault();
    this.setState({ editMessageId: message.id, ignoreScrollToBottom: true });
  }

  handleClearingEditMessageId({ resetScroll }) {
    if (resetScroll) {
      this.setState({ editMessageId: '', ignoreScrollToBottom: false });
    } else {
      this.setState({ editMessageId: '' });
    }
  }

  scrollToBottom() {
    const { ignoreScrollToBottom } = this.state;
    if (!ignoreScrollToBottom) {
      this.el.scrollIntoView({ behavior: 'auto' });
    } else {
      this.setState({ ignoreScrollToBottom: false, isMessagesLoading: false, });
    }
  }

  handleLoadMoreMessages = () => {
    const { currentUser, channel } = this.props;
    const organizationId = currentUser?.organization_id;
    const { chatListPageSize, chatListCurrentPage } = this.state;
    const newPageSize = chatListPageSize + DEFAULT_PAGE_SIZE;
    this.setState({
      chatListPageSize: newPageSize,
      chatListCurrentPage: chatListCurrentPage + 1,
      ignoreScrollToBottom: true,
      isMessagesLoading: true,
    });
    this.props.fetchMessages({
      organizationId,
      channelId: channel.id,
      pageSize: newPageSize,
    });
  }

  // handleScrollThroughMessages = ({ target: { scrollTop }}) => {
  //   const { currentUser, channel } = this.props;
  //   const organizationId = currentUser?.organization_id;
  //   const { chatListPageSize } = this.state;
  //   const newPageSize = chatListPageSize + DEFAULT_PAGE_SIZE;
  //   if (scrollTop === 0) {
  //     this.setState({
  //       chatListPageSize: newPageSize,
  //       ignoreScrollToBottom: true,
  //     });
  //     this.props.fetchMessages({
  //       organizationId,
  //       channelId: channel.id,
  //       pageSize: newPageSize,
  //     });
  //   }
  // }

  renderChatLog(source) {
    const { actionCabelUrl, currentUser } = this.props;
    const { editMessageId } = this.state;

    let userBlockArrs = [];
    let userBlockArr = [];

    this.messages = source;
    source.forEach(message => {
      if (userBlockArr.length === 0) {
        userBlockArr.push(message);
      } else if (message.author_id === userBlockArr.slice(-1)[0].author_id) {
        userBlockArr.push(message);
      } else {
        userBlockArrs.push(userBlockArr);
        userBlockArr = [message];
      }
    });
    if (userBlockArr.length > 0) {
      userBlockArrs.push(userBlockArr);
    }

    let res = userBlockArrs.map(userBlock => {
      const author_username = userBlock[0].author_display_name || userBlock[0].author_email;
      const author_job_title = userBlock[0].author_job_title;
      const avatar = userBlock[0].author_avatar;
      let created_at = messageCreatedAt(userBlock[0].created_at);
      let first_message = userBlock[0].content;
      if (userBlock[0].content) {
        first_message = ReactHtmlParser(userBlock[0].content);
      }

      const rest_messages = userBlock.slice(1).map(msg => {

        return (
          <div key={`chat_${msg.id}`} className="chat-message d-flex align-items-center justify-content-between">
            {
              msg.id === editMessageId
              ? (
                <MessageFormContainer
                  currentUser={currentUser}
                  actionCabelUrl={actionCabelUrl}
                  defaultMessage={msg}
                  handleClearingEditMessageId={this.handleClearingEditMessageId}
                  isEditMode={true}
                />
              ) : (
                <Fragment>
                  <div className="chat-message-container">
                    <span className="chat-created-at">{messageCreatedAt(msg.created_at)}</span>
                    <div className="d-flex align-items-center">
                      {msg.content && (
                        ReactHtmlParser(msg.content)
                      )}
                      {wasMessageEdited({ message: msg }) && (
                        <p className="message-edited-notification ms-1">(edited)</p>
                      )}
                    </div>
                  </div>
                  <MessageActions
                    deleteMessageHandler={this.handleDeleteMessage.bind(this, { message: msg })}
                    editMessageHandler={this.handleSetEditMessageId.bind(this, { message: msg })}
                    currentUser={currentUser}
                    message={msg}
                  />
                </Fragment>
              )
            }
          </div>
        );
      });
      return (
        <section key={`section_${userBlock[0].id}`} className="chat-section">
          <div className="avatar-chat-message-container d-flex align-items-center justify-content-between">
            {
              userBlock[0].id === editMessageId
              ? (
                <MessageFormContainer
                  currentUser={currentUser}
                  actionCabelUrl={actionCabelUrl}
                  defaultMessage={userBlock[0]}
                  handleClearingEditMessageId={this.handleClearingEditMessageId}
                  isEditMode={true}
                />
              ) : (
                <Fragment>
                  <div className="chat-message-header">
                    {avatar && avatar.url ? (
                      <img
                        className="user-avatar-image"
                        src={avatar.tiny.url}
                        alt="user-avatar"
                        height="45"
                        width="45"
                      />
                    ) : (
                      <img
                        className="default-avatar"
                        src={DefaultAvatar}
                        alt="ContactImmed"
                        height="45"
                        width="45"
                      />
                    )}
                    <div className="chat-message-header-text">
                      <div className="chat-author-and-created-at-container">
                        <span className="chat-author">{author_username}</span>
                        <span className="chat-created-at">{created_at}</span>
                        {author_job_title && (
                          <span className="author-job-title chat-created-at">
                            - {author_job_title}
                          </span>
                        )}
                      </div>
                      <div className="chat-message-first d-flex align-items-center">
                        {first_message}
                        {wasMessageEdited({ message: userBlock[0] }) && (
                          <p className="message-edited-notification ms-1">(edited)</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <MessageActions
                    deleteMessageHandler={this.handleDeleteMessage.bind(this, { message: userBlock[0] })}
                    editMessageHandler={this.handleSetEditMessageId.bind(this, { message: userBlock[0] })}
                    currentUser={currentUser}
                    message={userBlock[0]}
                  />
                </Fragment>
              )
            }
          </div>
          {rest_messages}
        </section>
      );
    }, this);
    return <div>{res}</div>;
  }

  render() {
    const {
      channel,
      getChannelMessages,
      isEducationOrganizationPersona,
    } = this.props;

    const {
      isMessagesLoading,
      chatListTotalCount,
      chatListCurrentPage,
      chatListTotalPages,
      initialMessagesLoading,
    } = this.state;

    const showLoadMore =  (chatListTotalPages - 1) > chatListCurrentPage;

    if (!channel && !isMessagesLoading) {
      return (
        <div className="chat-logs d-flex align-items-center justify-content-center">
          <div className="messages-empty-state w-75 d-flex flex-column align-items-center justify-content-center">
            <div className="mb-2">
              <div>Our apologies. This request doesn't exist.</div>
            </div>
            <div className="text-center">
              Please select a request from the left panel or create a new one.
            </div>
          </div>
        </div>
      )
    }

    if (chatListTotalCount < 1 && getChannelMessages?.length < 1 && !isMessagesLoading && !initialMessagesLoading) {
      return (
        <div className="chat-logs d-flex align-items-center justify-content-center">
          <div className="messages-empty-state w-75 d-flex flex-column align-items-center justify-content-center">
            <div className="mb-2">
              Welcome 👋! Post messages here.
            </div>
            {isEducationOrganizationPersona ? (
              <div className="text-center">
                The most powerful k-12 collaboration tool for education
              </div>
            ) : (
              <div className="text-center">
                Please click "Pro Tip" for information on how to use ContactImmed
                in order to elevate customer sympathy and revenue growth for your
                organization.
              </div>
            )}
          </div>
          <div
            ref={el => {
              this.el = el;
            }}
          />
        </div>
      )
    }

    return (
      <div className="chat-logs">
        {(showLoadMore || initialMessagesLoading) && (
          <div className="mt-2 d-flex align-items-center justify-content-center load-more-messages">
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={this.handleLoadMoreMessages}
            >
              {!isMessagesLoading && 'load more'}
              {isMessagesLoading && 'loading...'}
            </button>
          </div>
        )}
        <ul>{this.renderChatLog(getChannelMessages)}</ul>
        <div
          ref={el => {
            this.el = el;
          }}
        />
      </div>
    );
  }
}

export default withRouter(MessageList);
