import { connect } from 'react-redux';
import TwoFactorSettings from './two_factor_settings';
import withRouter from '../../../util/with_router';

const msp = (state) => {
  return {
    currentUser: state.entities.currentUser,
    // channels: Object.values(state.entities.channels),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    // action: () => dispatch(action({ organizationId })),
  };
};

export default withRouter(connect(msp, mdp)(TwoFactorSettings));
