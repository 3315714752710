import React from 'react';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';
import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class OrganizationUsers extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUser, currentLocale } = this.props;

    return (
      <div className='main intranet contactimmed-main'>
        <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale} />
        <ContentContainer
          currentUser={currentUser}
          organizationLogs={[]}
          currentLocale={currentLocale}
        />
      </div>
    );
  }
}

export default withRouter(OrganizationUsers);
