import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class WizardRequestCreationStepHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isEducationOrganizationPersona } = this.props;

    if (isEducationOrganizationPersona) {
      return (
        <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
          Requests allow you to log support needs related to school operations,
          instruction, and any service requests that contribute to a highly
          functional school district. Requests can also be directed to
          teams and departments for enhanced visibility and quicker response times.
          For example, a teacher should use ContactImmed to summit a support
          request to maintenance if the air conditioning goes out in a classroom.
        </div>
      );
    }

    return (
      <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
        Requests enable you to log customer desires, pain points, and
        any initiatives that will help generate new ARR and support customer retention.
        They can also include innovative or novel ideas from internal team members.
      </div>
    );
  }
}

WizardRequestCreationStepHeader.propTypes = {
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
};

export default WizardRequestCreationStepHeader;
