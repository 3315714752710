import React from 'react';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';

const isMessageEditable = ({ message }) => {
  // const now = moment();
  // const fiveMinutesFromCreatedAt = moment(message.created_at).add(5, 'minutes');
  //
  // return fiveMinutesFromCreatedAt.isSameOrAfter(now);
  return true;
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {/* custom icon */}
    {children}
    <i className="bi bi-three-dots-vertical"></i>
  </a>
));

class MessageActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {

    const {
      deleteMessageHandler,
      editMessageHandler,
      currentUser,
      message,
    } = this.props;

    const isMyMessage = currentUser?.id === message?.author_id

    if (!isMyMessage) {
      return null;
    }

    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
        <Dropdown.Menu size="sm" title="">
          {isMessageEditable({ message }) && (
            <Dropdown.Item
              onClick={editMessageHandler}
              className="mb-2"
            >
              Edit
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={deleteMessageHandler}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
     </Dropdown>
    );
  }
}

export default MessageActions;
