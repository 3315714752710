import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import { ellipsis, numToLocaleString } from '../../../components/util/helpers';
import { fetchWorkspaceCustomerPulse } from '../../../services/stats_api';
import { pulseOfTheCustomerDemoData } from '../util/demo_data';

class PulseOfTheCustomerCard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      pulseItems: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = useDemoData
      ? pulseOfTheCustomerDemoData({ persona: 'tech' })
      : await fetchWorkspaceCustomerPulse({
          workspaceId,
          organizationId,
        });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, pulseItems: response.entries })
      } else {
        this.setState({ isLoading: false, isError: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentLocale,
      currentUser,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      useDemoData,
      workspaceId,
    } = this.props;

    const WIDGET_TITLE = 'Top 10 most liked customer requests';
    const { pulseItems, isLoading } = this.state;
    const data = pulseItems;
    const isEmptyState = pulseItems && !pulseItems.length;

    if (isLoading) {
      return (
        <Card className="static-summary-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="static-summary-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState
                message="No active customer requests with likes"
                currentLocale={currentLocale}
              />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="static-summary-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>

                    <TableCell
                      className="column-header customer-pulse-votes ps-4 pt-4"
                    >
                      Votes
                    </TableCell>
                    <TableCell className="column-header pt-4">Created</TableCell>
                    <TableCell className="column-header pe-4 pt-4">
                      Request
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow
                      key={row.channel_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className="dashboard-table-cell ps-4 py-3">
                        {
                          row.favorites_rank
                            ? `${numToLocaleString(row.favorites_rank)}`
                            : 0
                        }
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-age ps-3 py-3">
                        {row.time_ago && `${row.time_ago} ago`}
                      </TableCell>
                      <TableCell className="dashboard-table-cell channel-path ps-3 pe-4 py-3">
                        {useDemoData ? (
                          <abbr title={row.channel_name}>
                            {
                              row.channel_name &&
                              `${ellipsis({ string: row.channel_name, maxLength: 25 })}`
                            }
                          </abbr>
                        ) : (
                          <a
                            href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${row.channel_id}`}
                            target="_blank"
                          >
                            <abbr title={row.channel_name}>
                              {
                                row.channel_name &&
                                `${ellipsis({ string: row.channel_name, maxLength: 25 })}`
                              }
                            </abbr>
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

PulseOfTheCustomerCard.propTypes = {};

export default PulseOfTheCustomerCard;

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Most liked requests:
    </p>
    <p className="tooltip-paragraph">
      By using the <b>like</b> icon for requests, you can express your support
      for a request. Users can see which request have the most likes. The more
      likes, the greater the visibility, the more likely (pun intended) the
      request is to gain support.
    </p>
  </div>
);
