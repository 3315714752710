import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import {
  fetchOrganizationWorkspaceWebhooks,
} from '../../../actions/organization_actions';

const msp = (state, ownProps) => {
  return {
    currentUser: state.entities.currentUser,
    endpoints: state.entities.organization.webhooks,
    // pages: state.entities.organization.pages || 1,
    endpointsCount: state.entities.organization.webhooksCount || 0,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchEndpoints: ({ page, pageSize, search, workspaceId }) =>
      dispatch(fetchOrganizationWorkspaceWebhooks({
        organizationId,
        workspaceId,
        page,
        pageSize,
        search,
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
