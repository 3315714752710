export const RECEIVE_USER_CHANNELS = 'RECEIVE_USER_CHANNELS';
export const RECEIVE_USER_CHANNEL = 'RECEIVE_USER_CHANNEL';
import * as userChannelAPI from '../services/user_channel_api';

export const receiveUserChannels = (user_channels) => {
  return {
    type: RECEIVE_USER_CHANNELS,
    user_channels
  };
};

export const receiveUserChannel = (payload) => {
  return {
    type: RECEIVE_USER_CHANNEL,
    payload
  };
};

export const fetchUserChannels = ({ organizationId, workspaceId }) => (dispatch) => {
  return userChannelAPI.fetchUserChannelsApi({ organizationId, workspaceId }).then(res => dispatch(receiveUserChannels(res)));
};

export const createUserChannel = ({ organizationId, workspaceId, userChannel }) => (dispatch) => {
  return userChannelAPI.createUserChannelApi({ organizationId, workspaceId, userChannel }).then(res => dispatch(receiveUserChannel(res)));
};
