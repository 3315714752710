import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Select from 'react-select'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getReactFinalFormSelectValues,
  sleep,
  toastifyConfiguration,
} from '../../components/util/helpers';
import { updateTeamMember } from '../../services/team';

toast.configure();

class EditTeamMemberPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const { currentUser, teamId, selectedTeamMember, onEditTeamMemberPaneClose } = this.props;
    const { roles } = values;
    const teamMember = await updateTeamMember({
        organization_id: currentUser?.organization_id,
        team_id: teamId,
        team_member_id: selectedTeamMember.id,
        roles: roles ? getReactFinalFormSelectValues(roles) : [],
      });

    if (!(teamMember || {}).error) {
      onEditTeamMemberPaneClose();
      location.reload();
    } else {
      toast.error(`Error updating team member, ${selectedTeamMember.email}. Please try again.`, toastifyConfiguration({}));
    }
  }

  render() {
    const { permittedTeamRoles, selectedTeamMember } = this.props;
    const initialRolesValue =  selectedTeamMember.roles.map(role => ({
      value: role.name, label: (permittedTeamRoles.filter(teamRole => teamRole.value === role.name).shift() || {}).label
    }))
    return (
      <div className="add-user-pane">
        <Form
          onSubmit={this.handleOnSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                initialValue={selectedTeamMember.email}
              >
                {({ input, meta }) => (
                  <p className="field email">
                    <input {...input} type="text" disabled />
                  </p>
                )}
              </Field>
              <Field
                name="roles"
                validate={requiredSelect}
                initialValue={initialRolesValue}
                component={ReactSelectAdapter}
                options={permittedTeamRoles.map(role => ({
                  value: role.value, label: role.label
                }))}
              />
              <div className="submit">
                <input type="submit" name="commit" value="Submit" disabled={submitting} />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

EditTeamMemberPane.propTypes = {
};

export default EditTeamMemberPane;

// private

const ReactSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select">
    <Select {...input} {...rest} searchable isMulti />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
)

const requiredSelect = value => value && !!value.length ? undefined : 'Required';
