import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchCurrentUser } from '../../../actions/user_actions';

const msp = (state) => {
  return {
    currentUser: state.entities.currentUser,
  };
};

const mdp = dispatch => {
  return {
    getCurrentUser: ({ organizationId }) => fetchCurrentUser({ organizationId }),
  };
};

export default withRouter(connect(msp, mdp)(Content));
