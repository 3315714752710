import React from 'react';

import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class Workspaces extends React.Component {

  componentDidMount() {}

  render() {
    const {
      currentLocale,
      currentUser,
      isAccountAdmin,
      permittedRoles,
      permittedWorkspaceRoles,
    } = this.props;

    return (
      <ContentContainer
        currentLocale={currentLocale}
        currentUser={currentUser}
        isAccountAdmin={isAccountAdmin}
        organizationWorkspaces={[]}
        permittedRoles={permittedRoles}
        permittedWorkspaceRoles={permittedWorkspaceRoles}
      />
    );
  }
}

export default withRouter(Workspaces);
