import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Button from '../../components/common/Button';
import { enableConfiguration } from '../../services/sso';
import { toast } from 'react-toastify';
import { sleep, toastifyConfiguration } from '../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

class SsoConfigurationEnabler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSsoDisabled: props.ssoConfiguration.disabled,
    };
    this.handleOnClickEnableSaml = this.handleOnClickEnableSaml.bind(this);
  }

  handleOnClickEnableSaml = async () => {
    await sleep(300);
    const {
      ssoConfiguration,
      currentUser,
    } = this.props;
    const { isSsoDisabled } = this.state;
    const reqBody = {
      id: ssoConfiguration.id,
      organization_id: currentUser?.organization_id,
      disabled: isSsoDisabled ? false : true,
    }
    const configuration = await enableConfiguration(reqBody);

    if (configuration && !(configuration || {}).error) {
      // 'true' will force the page to reload from the server
      // 'false', the default, will reload from cache
      this.setState({ isSsoDisabled: configuration.disabled });
    } else {
      toast.error(`Error ${isSsoDisabled ? 'enabling' : 'disabling'} SSO configuration. Please try again.`, toastifyConfiguration({}));
    }
  }

  render() {
    const { currentUser, ssoConfiguration } = this.props;
    const { isSsoDisabled } = this.state;

    return (
      <div className="sso-configuration-enabler configuration-card">
        <div className="header">
          <div className="step-number">3.</div>
          <div>saml is not enabled</div>
        </div>
        <div className="content">
          <div className="confirmation-suggestion">
            After you enable SAML, we recommend attempting a successful login
            (through logout, another browser, or browser incogneato mode).
            While SAML is enabled, non-admin users will not be able to login via
            password.
          </div>
          <div className="enable-saml-actions">
            <Button
              handleClick={this.handleOnClickEnableSaml}
              label={isSsoDisabled ? 'Enable SAML' : 'Disable SAML'}
              classes="large"
            />
          </div>
        </div>
      </div>
    );
  }
}

SsoConfigurationEnabler.propTypes = {
};

export default SsoConfigurationEnabler;
