import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { logoutCurrentUser } from '../../../services/session_api';

class PlanIsConfirmedModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user, onClose } = this.props;

    return (
      <div className="confirm-modal choose-plan custom-ui">
        <div className="mb-5">
          <i className="bi bi-check-circle"></i>
        </div>
        <div className="header mb-4">
          Subscription Confirmed!
        </div>
        <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
          <div className="subtitle confirmed-plan">
            <div>
              <span className="contact-us-emphasis">Congratulations!</span> You've
              successfully purchased the chosen plan. Enjoy premium features and
              benefits.
            </div>
            <div className="mt-3">Thank you for choosing us!</div>
          </div>
        </div>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-contact-us-button"
            onClick={() => {
              logoutCurrentUser({ user });
              onClose();
            }}
          >
            Log out to activate
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            Back to plans
          </button>
        </div>
      </div>
    );
  }
}

PlanIsConfirmedModal.propTypes = {
  onClose: PropTypes.func,
};

export default PlanIsConfirmedModal;
