import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class WizardMissionCompleteStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      currentLocale,
      isEducationOrganizationPersona,
      newRequestId,
      workspaceId,
    } = this.props;

    if (isEducationOrganizationPersona) {
      return (
        <div className="text-start activation-modal-home-page">
          <div className="h3 mb-4 wizard-section-header">
            Task complete!
          </div>
          <div className="mb-4 wizard-section-subheader bg py-4 px-3">
            Now that you've experienced key features, you're ready to
            get started. Please open the request here{' '}
            <a
              href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${newRequestId}`}
              target="_blank"
            >
              <i className="bi bi-box-arrow-up-right"></i>
            </a>
          </div>
          <div className="mb-4 wizard-section-header">
            Enriching requests with details is the best way to:
          </div>
          <div className="section-list">
            <ul>
              <li className="list-item">
                Quickly route your request to the right stakeholders who
                can effectively complete the tasks.
              </li>
              <li className="list-item">
                Help your district identify trends that will provide analytical
                insights and facilitate smoother operations moving forward.
              </li>
            </ul>
          </div>
          <div className="mb-4 wizard-section-header">
            You're on your way to elevating the district!
          </div>
        </div>
      );
    }

    return (
      <div className="text-start activation-modal-home-page">
        <div className="h3 mb-4 wizard-section-header">
          Mission complete!
        </div>
        <div className="mb-4 wizard-section-subheader bg py-4 px-3">
          Now that you've experienced key features, you're ready to
          get started. Please open the request here{' '}
          <a
            href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${newRequestId}`}
            target="_blank"
          >
            <i className="bi bi-box-arrow-up-right"></i>
          </a>
        </div>
        <div className="mb-4 wizard-section-header">
          Enriching requests with details is the best way to:
        </div>
        <div className="section-list">
          <ul>
            <li className="list-item">
              Informing your coworkers about the deal value behind the request,
              identifying which customers are involved, and providing other
              relevant information will help quickly route the customer’s voice
              to the right stakeholders who can get tasks done.
            </li>
            <li className="list-item">
              Suggest <b>teams</b> and mention users. Identify features and
              assets that will provide valuable analytical insights.
            </li>
          </ul>
        </div>
        {/*
          <div className="mb-4 wizard-section-subheader">
            Letting your coworkers know the deal value behind the request,
            which customers are speaking, and other information that’ll
            quickly route the customer's voice to the right stakeholders who
            can get tasks done.
          </div>
          <div className="mb-4 wizard-section-subheader">
            Suggest <b>teams</b> and mention users. Identify features
            and assets that'll aid in analytical insights.
          </div>
        */}

        <div className="mb-4 wizard-section-header">
          You're on your way to enhancing customer insights and satisfaction.
        </div>
      </div>
    );
  }
}

WizardMissionCompleteStep.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
  newRequestId: PropTypes.string,
  workspaceId: PropTypes.string,
};

export default WizardMissionCompleteStep;
