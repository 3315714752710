import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class GeneralUpgradePlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigate, onClose } = this.props;

    return (
      <div className="confirm-modal choose-plan general-upgrade-plan custom-ui">
        <div className="mb-5">
          <i className="bi bi-gem"></i>
        </div>
        <div className="header mb-4">
          Thank you for using us!
        </div>
        <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
          <div className="subtitle cancel-enterprise-plan">
            <div className="mb-3 contact-us-emphasis">
              Please upgrade.
            </div>
            <div>
              Your Free Trial plan requires an upgrade to create more than 100
              Requests. Please view our "upgrade plan" options.
            </div>
          </div>
        </div>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-contact-us-button"
            onClick={() => {
              navigate('/settings/pricing');
              onClose();
            }}
          >
            See Upgrade Options
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

GeneralUpgradePlanModal.propTypes = {
  onClose: PropTypes.func,
};

export default GeneralUpgradePlanModal;
