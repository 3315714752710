import React from "react";
import { Form, Field } from 'react-final-form';
import PropTypes from "prop-types";
import { QRCodeSVG } from 'qrcode.react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { sleep } from '../../../components/util/helpers';
import AboutPageDrawer from '../../AboutDrawers/SecuritySettingsPage/AboutPageDrawer';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactorSettingsData: {},
      isAboutPageDrawerOpen: false,
    };
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  componentDidMount() {
    this.props.fetchTwoFactorSettingsData()
      .then(data =>
        this.setState({ twoFactorSettingsData: data.twoFactorSettingsData })
      )
      .catch(error =>
        this.setState({
          twoFactorSettingsData: { isError: true, status: error.status, message: error.message }
        })
      );
  }

  handleOnSubmit = async values => {
    await sleep(100);
    const { code, password } = values;
    const { currentUser } = this.props;

    const response = await this.props.enableTwoFactorAuth({
      code, password
    })

    if (!(response || {}).error) {
      this.props.navigate('/settings/security', { state: { twoFactorEnabledSuccessfully: true } });
    }
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {
    const { currentUser } = this.props;
    const { twoFactorSettingsData } = this.state;
    const {
      two_factor_qr_code_uri,
      otp_secret,
      isError,
      status,
    } = twoFactorSettingsData;

    const slidePaneComponent = () => {
      return (
        <SlidingPane
          className='about-page-drawer settings security'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      );
    }

    const openPaneComponent = () => {
      return (
        <div
          className="pro-tip-button"
          onClick={() => this.handleOnClickOpenAboutPageDrawer()}
        >
          Pro Tip
        </div>
      );
    }

    if (isError && status === 403) {
      return (
        <div className="settings two-factor-settings container-fluid vh-100 px-5">
          <div className="content-header">
            <div className="title">Security</div>
            <div className="subheader">
              <div className="page">Two Factor</div>
              <div className="hyphen">-</div>
              <div className="small-title">Setting</div>
              {openPaneComponent()}
            </div>
          </div>
          <div className="settings-content card-container">
            <div className="card">
              <div className="two-factor-settings-new enabled">
                <div className="header">
                  <div className="already-enabled">
                    Two factor is already enabled.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {slidePaneComponent()}
        </div>
      )
    }

    if (isError) {
      return (
        <div className="settings two-factor-settings container-fluid vh-100 px-5">
          <div className="content-header">
            <div className="title">Security</div>
            <div className="subheader">
              <div className="page">Two Factor</div>
              <div className="hyphen">-</div>
              <div className="small-title">Setting</div>
              {openPaneComponent()}
            </div>
          </div>
          <div className="settings-content card-container">
            <div className="card">
              <div className="two-factor-settings-new error">
                <div className="header error">
                  <div>Error preparing two factor settings. Please try again.</div>
                </div>
              </div>
            </div>
          </div>
          {slidePaneComponent()}
        </div>
      )
    }

    if (two_factor_qr_code_uri) {
      return (
        <div className="settings two-factor-settings container-fluid px-5">
          <div className="content-header">
            <div className="title">Security</div>
            <div className="subheader">
              <div className="page">Two Factor</div>
              <div className="hyphen">-</div>
              <div className="small-title">Setting</div>
              {openPaneComponent()}
            </div>
          </div>
          <div className="settings-content card-container">
            <div className="card">
              <div className="two-factor-settings-new">
                <div className="header">
                  <h2>Two Factor Authentication</h2>
                </div>
                <div className="card-deck">
                  <div className="card">
                    <div className="card-header">
                      1. Scan QR Code
                    </div>
                    <div className="card-body">
                      <p>Please scan the below QR code using an OTP compatible app (such as Google Authenticator or Authy).</p>
                      <hr />
                      <p className="qr-code">
                        {two_factor_qr_code_uri && (
                          <QRCodeSVG
                            // size={228}
                            value={two_factor_qr_code_uri}
                            />
                        )}
                      </p>
                      <hr />
                      <p className="text-center">
                        If you cannot scan, please enter the following code manually: <code>
                        {otp_secret}
                        </code>
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      2. Confirm OTP Code
                    </div>
                    <div className="card-body">
                      <p>Please confirm that your authentication application is working by entering a generated code below.</p>
                      <Form
                        onSubmit={this.handleOnSubmit}
                        validate={values => {
                          const errors = {};
                          if (!values.code) {
                            errors.code = 'Required';
                          }
                          if (!values.password) {
                            errors.password = 'Required';
                          }
                          return errors;
                        }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                          <form onSubmit={handleSubmit}>
                            <Field name="code">
                              {({ input, meta }) => (
                                <div className="field code">
                                  <div className="label">
                                    Code
                                  </div>
                                  <input {...input} />
                                  {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                                </div>
                              )}
                            </Field>
                            <Field name="password">
                              {({ input, meta }) => (
                                <div className="field password">
                                  <div className="label">Password</div>
                                  <input {...input} type="password" />
                                  {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                                </div>
                              )}
                            </Field>
                            <div className="buttons">
                              <button type="submit" className="primary large" disabled={submitting}>
                                Confirm and Enable Two Factor
                              </button>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {slidePaneComponent()}
        </div>
      );
    }
    return null;
  }
}

Content.propTypes = {};

export default Content;
