import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import {
  fetchOrganizationWorkspaces,
  fetchOrganizationWorkspaceAccessRequests,
} from '../../../actions/organization_actions';
import {
  isEducationOrganizationPersona,
} from '../../../reducers/selectors';

const msp = (state, ownProps) => {
  const { entities } = state;
  return {
    currentUser: entities.currentUser,
    workspaces: entities.organization.workspaces,
    workspaceAccessRequests: entities.organization.workspaceAccessRequests,
    pages: entities.organization.pages || 1,
    workspacesAccessRequestsCount: entities.organization.workspaceAccessRequestsCount || 0,
    workspaceTags: (entities.organization.tags.workspace || {}).entries || [],
    permittedWorkspaceRoles: ownProps.permittedWorkspaceRoles,
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchWorkspaces: ({ page, pageSize, search, sort }) =>
      dispatch(fetchOrganizationWorkspaces({
        organizationId,
        page,
        pageSize,
        search,
        sort,
      })
    ),
    fetchWorkspaceAccessRequests: ({ page, pageSize, search, sort }) =>
      dispatch(fetchOrganizationWorkspaceAccessRequests({
        organizationId,
        page,
        pageSize,
        search,
        sort,
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
