export async function updateUserPassword({
  organization_id,
  user_id,
  current_password,
  password,
  password_confirmation,
}) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}/update_password`, {
    method: 'put',
    body: JSON.stringify({
      organization_id,
      user_id,
      current_password,
      password,
      password_confirmation,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getUserRoles({ organization_id, user_id }) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}/roles`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getUserWorkspaces({ organization_id, user_id }) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}/workspaces`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getUserTeams({ organization_id, user_id }) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}/teams`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function disableTwoFactor() {
  const response = fetch(`/settings/two_factor_settings`, {
    method: 'delete',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;;
}

export async function signOutUser({ signOutPath }) {
  const response = fetch(signOutPath, {
    method: 'delete',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.redirected) {
      window.location.href = response.url;
    }
  })
  .then((data) => {
    return data;
  });
  return response;;
}
