export const fetchWorkspaces = ({ organizationId }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/workspaces`,
  });
};


export async function fetchWorkspace({
  workspaceId,
}) {
  const url = `/settings/workspaces/${workspaceId}.json`

  return $.ajax({
    url: url,
  });
}

export const createWorkspace = ({ organizationId, workspace }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/workspaces`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: { workspace }
  });
};

export const updateWorkspace = ({ workspace, organizationId, id }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/workspaces/${id}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'PUT',
    contentType: 'application/json',
    data: JSON.stringify({ workspace })
  });
};

export const removeWorkspace = ({ organizationId, id }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/workspaces/${id}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'DELETE'
  });
};
