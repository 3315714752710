import React from 'react';

const FeatureUrlInput = ({
  onChange,
  value,
  defaultValue,
  name,
  className,
  placeholder,
}) => {

  return (
    <input
      name={name}
      // defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      className={className}
      type="text"
      placeholder={placeholder}
    />
  );
};

export default FeatureUrlInput;
