import React from 'react';
import BaseMultiSelector from '../base_multi_selector';

const MultiSelector = ({
  onChange,
  selectedOption,
  defaultValue,
  options,
  name,
  className,
  placeholder,
  styleOptions,
}) => {
  return (
    <BaseMultiSelector
      name={name}
      options={options}
      defaultValue={defaultValue}
      value={selectedOption}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      styleOptions={styleOptions}
    />
  );

};

export default MultiSelector;
