import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import Modal from 'react-modal';
import moment from 'moment';
import 'react-table/react-table.css';
import SlidingPane from 'react-sliding-pane';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Button from '../../../components/common/Button';
import AboutPageDrawer from '../../AboutDrawers/AuditSettingsPage/AboutPageDrawer';

import { capitalize, getFormattedDate, reactTableSortParamValue, sleep } from '../../../components/util/helpers';
import { getAuditLogs, getAuditLogsCsv } from '../../../services/audit';
import { getUsers } from '../../../services/organization';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

const DEFAULT_INPUT_VALUE_LABEL = 'All';
const DEFAULT_USER_INPUT_VALUE_LABEL = 'All or search users';
const DEFAULT_INPUT_VALUE = DEFAULT_INPUT_VALUE_LABEL.toLowerCase();
const isSelectNotAll = option => option.value !== DEFAULT_INPUT_VALUE;

const eventTypeOptions = [
  { value: 'any', label: 'All' },
  // { value: 'login', label: 'Login' },
  { value: 'create', label: 'Created' },
  { value: 'update', label: 'Updated' },
  { value: 'destroy', label: 'Deleted' },
];

const itemOptions = ({ featuresEnabled }) => {
  const isSsoEnabled = featuresEnabled?.sso_login;

  return [
    { value: 'any', label: 'Any' },
    { value: 'workspace', label: 'Workspace' },
    // { value: 'workspacemembership', label: 'Workspace Membership' },
    { value: 'channel', label: 'Channel' },
    { value: 'team', label: 'Team' },
    // { value: 'teammembership', label: 'Team Membership' },
    // { value: 'role', label: 'Role' },
    // { value: 'roleassignment', label: 'Role Assignment' },
    ...(isSsoEnabled ? [{ value: 'idpsetup', label: 'IDP Setup - SSO' }] : []),
    { value: 'user', label: 'User' },
  ];
}

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 0,
      totalCount: 0,
      selectedUserOption: null,
      selectedBeginDateOption: null,
      selectedEndDateOption: null,
      selectedEventTypeOption: null,
      selectedItemOption: null,
      logs: [],
      users: [],
      isAboutPageDrawerOpen: false,
      isCsvActionInProgress: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleLogsFilterFetch = this.handleLogsFilterFetch.bind(this);
    this.handleOnUserInputChange = this.handleOnUserInputChange.bind(this);
    this.handleUsersFilterFetch = this.handleUsersFilterFetch.bind(this);
    this.handleGetPaginatedAuditLogs = this.handleGetPaginatedAuditLogs.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
    const logs =  await getAuditLogs({});
    if (logs) {
      this.setState({ logs: logs.entries, pages: logs.total_pages, totalCount: logs.total_count, loading: false })
    }
  }

  debounceSearch = debounce(input => this.handleUsersFilterFetch(input), 500);

  handleOnUserInputChange = input => {
    if (!input) {
      return;
    }

    this.debounceSearch(input);
  }

  handleUsersFilterFetch = async input => {
    event && event.preventDefault();
    const filteredUsers = await getUsers({ search: input, organization_id: this.props.organizationId });
    if (filteredUsers) {
      this.setState({ users: filteredUsers.entries });
    }
  }

  handleOnChange = (selectedOption, reactSelectEvent) => {
    this.setState({ [reactSelectEvent.name]: selectedOption });
    sleep(1000).then(() => this.handleLogsFilterFetch());
  }

  handleBeginDateChange = selectedBeginDateOption => {
    if (!selectedBeginDateOption && !this.state.selectedBeginDateOption ) { return; }
    this.setState({ selectedBeginDateOption: getFormattedDate(selectedBeginDateOption) });
    sleep(1000).then(() => this.handleLogsFilterFetch());
  };

  handleEndDateChange = selectedEndDateOption => {
    if (!selectedEndDateOption && !this.state.selectedEndDateOption) { return; }
    this.setState({ selectedEndDateOption:  getFormattedDate(selectedEndDateOption) });
    sleep(1000).then(() => this.handleLogsFilterFetch());
  };

  handleLogsFilterFetch = async event => {
    event && event.preventDefault();
    const {
      selectedUserOption,
      selectedBeginDateOption,
      selectedEndDateOption,
      selectedEventTypeOption,
      selectedItemOption,
    } = this.state;
    const logs = await getAuditLogs({
      ...(selectedUserOption && isSelectNotAll(selectedUserOption) && { user: selectedUserOption.value }),
      ...(selectedBeginDateOption && isSelectNotAll(selectedBeginDateOption) && { start_date: selectedBeginDateOption }),
      ...(selectedEndDateOption && isSelectNotAll(selectedEndDateOption) && { end_date: selectedEndDateOption }),
      ...(selectedEventTypeOption && isSelectNotAll(selectedEventTypeOption) && { event_type: selectedEventTypeOption.value }),
      ...(selectedItemOption && isSelectNotAll(selectedItemOption) && { item_type: selectedItemOption.value })
    });

    if (logs) {
      this.setState({ logs: logs.entries, pages: logs.total_pages, totalCount: logs.total_count, loading: false })
    }
  };

  handleGetPaginatedAuditLogs = async (page, pageSize, sorted) => {
    event && event.preventDefault();
    const isSortSelected = !!sorted.length;
    const { currentUser } = this.props;
    const {
      selectedUserOption,
      selectedBeginDateOption,
      selectedEndDateOption,
      selectedEventTypeOption,
      selectedItemOption,
    } = this.state;
    const logs = await getAuditLogs({
      ...(selectedUserOption && isSelectNotAll(selectedUserOption) && { user: selectedUserOption.value }),
      ...(selectedBeginDateOption && isSelectNotAll(selectedBeginDateOption) && { start_date: selectedBeginDateOption }),
      ...(selectedEndDateOption && isSelectNotAll(selectedEndDateOption) && { end_date: selectedEndDateOption }),
      ...(selectedEventTypeOption && isSelectNotAll(selectedEventTypeOption) && { event_type: selectedEventTypeOption.value }),
      ...(selectedItemOption && isSelectNotAll(selectedItemOption) && { item_type: selectedItemOption.value }),
      page,
      page_size: pageSize,
      ...(isSortSelected && { sort: reactTableSortParamValue(sorted) } )

    });
    logs
      ? this.setState({ logs: logs.entries, pages: logs.total_pages, totalCount: logs.total_count, loading: false })
      : this.setState({ logs: [], pages: 0, loading: false });
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    const {
      selectedUserOption,
      selectedBeginDateOption,
      selectedEndDateOption,
      selectedEventTypeOption,
      selectedItemOption,
    } = this.state;
    await getAuditLogsCsv({
      ...(selectedUserOption && isSelectNotAll(selectedUserOption) && { user: selectedUserOption.value }),
      ...(selectedBeginDateOption && isSelectNotAll(selectedBeginDateOption) && { start_date: selectedBeginDateOption }),
      ...(selectedEndDateOption && isSelectNotAll(selectedEndDateOption) && { end_date: selectedEndDateOption }),
      ...(selectedEventTypeOption && isSelectNotAll(selectedEventTypeOption) && { event_type: selectedEventTypeOption.value }),
      ...(selectedItemOption && isSelectNotAll(selectedItemOption) && { item_type: selectedItemOption.value }),
    });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {
    const { currentUser, currentLocale } = this.props;
    const { featuresEnabled } = currentUser;
    const {
      isCsvActionInProgress,
      selectedUserOption,
      selectedBeginDateOption,
      selectedEndDateOption,
      selectedEventTypeOption,
      selectedItemOption,
      logs,
      users,
      totalCount,
    } = this.state;

    const columns = [{
      id: 'created_at',
      Header: 'Time',
      width: 250,
      accessor: log  => moment(log.created_at).locale(currentLocale).utc().format('MMMM Do YYYY, h:mm:ss a'),
    }, {
      Header: 'User',
      accessor: 'whodunnit',
      width: 250,
    }, {
      id: 'object_changes',
      Header: 'Updates',
      sortable: false,
      accessor: log => formatObjectChangesV2(log.object_changes),
      width: 500,
    }, {
      Header: 'IP',
      accessor: 'ip',
      width: 80,
    }, {
      id: 'event',
      Header: 'Type',
      accessor: log => formatEventType(log.event),
      width: 100,
    }, {
      Header: 'Item Changed',
      accessor: 'item_type',
      width: 200,
    },  {
      Header: 'Item Id',
      accessor: 'item_id',
      width: 350,
    }]
    {/* <span className="changes">{formatObjectChanges(log.object_changes)}</span> */}
    return (
      <div
        className="settings organization-audit-logs audit-logs-filterable-table container-fluid px-5"
        ref={ref => this.el = ref}
      >
        <div className="content-header">
          <div className="title">Activity</div>
          <div className="subheader">
            <div className="page">Audit Logs</div>
            <div
              className="pro-tip-button ms-2"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="audit-row">
            <div className="dropdown user-dropdown">
              <label>User</label>
              <Select
                isClearable
                value={selectedUserOption}
                placeholder={DEFAULT_USER_INPUT_VALUE_LABEL}
                name='selectedUserOption'
                onChange={this.handleOnChange}
                onInputChange={this.handleOnUserInputChange}
                filterOption={() => (true)}
                formatOptionLabel={formatUserOptionLabel}
                options={userOptions(users)} />
            </div>
            <div className="dropdown date-dropdown">
              <label>Begin date</label>
              <DayPickerInput onDayChange={this.handleBeginDateChange} />
            </div>
            <div className="dropdown date-dropdown">
              <label>End date</label>
              <DayPickerInput onDayChange={this.handleEndDateChange} />
            </div>
          </div>
          <div className="audit-row">
            <div className="dropdown event-type-dropdown">
              <label>Type</label>
              <Select
                placeholder={DEFAULT_INPUT_VALUE_LABEL}
                name='selectedEventTypeOption'
                value={selectedEventTypeOption}
                onChange={this.handleOnChange}
                options={eventTypeOptions} />
            </div>
            <div className="dropdown object-dropdown">
              <label>Item</label>
              <Select
                placeholder={DEFAULT_INPUT_VALUE_LABEL}
                name='selectedItemOption'
                value={selectedItemOption}
                onChange={this.handleOnChange}
                options={itemOptions({ featuresEnabled })} />
            </div>
          </div>
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                <div>{pluralize('Total Logs', totalCount, false)}</div>
                <div className="count-container">
                  {totalCount?.toLocaleString()}
                </div>
              </div>
            </div>
            <div className="button-actions">
              {isCsvActionInProgress ? (
                <button className="export small export-csv" type="button" disabled>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  <span>Exporting...</span>
                </button>
              ) : (
                <Button
                  handleClick={() => {
                    this.setState({ isCsvActionInProgress: true });
                    this.handleCsvExport();
                  }}
                  label="Export CSV"
                  classes="export small export-csv"
                />
              )}
            </div>
          </div>
          <div className="container-fluid table-details-section logs-table">
            <ReactTable
              data={logs}
              columns={columns}
              defaultPageSize={25}
              loading={this.state.loading}
              pages={this.state.pages}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedAuditLogs(state.page, state.pageSize, state.sorted);
              }}
            />
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings audits-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;

const formatUserOptionLabel = ({ value, label, firstName, lastName }) => (
  <div className="user-option-label">
    {firstName && lastName &&
      <div>{`${firstName} ${lastName}`}</div>
    }
    <div>{label}</div>
  </div>
);

const defaultOptions = [{ value: DEFAULT_INPUT_VALUE, label: DEFAULT_INPUT_VALUE_LABEL }];
const userOptions = users =>
  defaultOptions.concat(users.map(obj =>
    ({ value: obj.email, label: obj.email, firstName: obj.first_name, lastName: obj.last_name })
  ));
const formatEventType = (eventType = '') =>
  ['update', 'create', 'delete'].indexOf(eventType.toLowerCase()) > -1
    ? `${capitalize(eventType)}d`
    : capitalize(eventType)

const formatObjectChangesV2 = objectChanges => {
  if (!objectChanges) { return undefined };

  const obj = JSON.parse(objectChanges);

  return (
    <table className="event-change-table container-fluid">
      {Object.entries(obj).map(([key, value]) => (
        <tr key={key}>
          <td className="event-change-key py-2 px-2">{key}</td>
          <td className="event-change-cell py-2 px-2">
            {`${value[0]} to ${value[1]}`}
          </td>
        </tr>
      ))}
    </table>
  );
}

const formatObjectChanges = objectChanges => {
  const obj = JSON.parse(objectChanges);
  if (!objectChanges) { return undefined };
  return Object.keys(obj).map(
    item => `${item} changed from "${obj[item][0]}" to "${obj[item][1]}"`).join(', ');
}
