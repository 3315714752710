import {connect} from 'react-redux';
import ChannelHeader from './channel_header';
import withRouter from '../../../../util/with_router';
import {
  fetchChannel,
  fetchChannelFavorites,
  favoriteChannel,
  unfavoriteChannel,
} from '../../../../actions/channel_actions';
import { fetchUsers } from '../../../../actions/user_actions';
import { getJoinedUsers } from '../../../../reducers/selectors';
import { openModal } from '../../../../actions/modal_actions';


const msp = (state, ownProps) => {
  return {
    currentUser: state.entities.currentUser,
    channel: state.entities.channels[ownProps.match.params.channelId],
    channelFavorites: state.entities.channelFavorites,
    channelId: ownProps.match.params.channelId,
    workspaceId: ownProps.match.params.workspaceId,
    // numUsers: Object.values(state.entities.users).length,
    numUsers: (channelId) => getJoinedUsers(state, channelId).length
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchChannel: (id) => {
      dispatch(fetchChannel({ organizationId, workspaceId, id }));
      dispatch(fetchChannelFavorites({ organizationId, workspaceId, id }));
    },
    fetchUsers: () => dispatch(fetchUsers({ organizationId })),
    likeChannel: ({ channelId }) => {
      dispatch(favoriteChannel({ organizationId, workspaceId, channelId }));
    },
    openModal: (modal, props) => dispatch(openModal(modal, props)),
    unlikeChannel: ({ channelId, favoriteId }) => {
      dispatch(unfavoriteChannel({ organizationId, channelId, favoriteId }))
    }
  };
};

export default withRouter(connect(msp, mdp)(ChannelHeader));
