import {
  RECEIVE_CHANNELS_SUMMARY,
} from '../actions/channel_actions';

const channelsSummaryReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_CHANNELS_SUMMARY:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  };
};

export default channelsSummaryReducer;
