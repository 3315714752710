import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class ConfirmCancelEnterprisePlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleCancelPlan, onClose } = this.props;

    return (
      <div className="confirm-modal cancel-plan custom-ui">
        <div className="mb-5">
          <i className="bi bi-x-circle"></i>
        </div>
        <div className="header mb-4">
          Cancel Plan?
        </div>
        <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
          <div className="subtitle cancel-enterprise-plan">
            An Enterprise plan can only be canceled at renewal. We'd love to
            have a dialogue with you to understand where we failed your
            expectations. May we contact you?
          </div>
        </div>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-contact-us-button"
            onClick={() => {
              handleCancelPlan();
              onClose();
            }}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    );
  }
}

ConfirmCancelEnterprisePlanModal.propTypes = {
  handleCancelPlan: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmCancelEnterprisePlanModal;
