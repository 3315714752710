import {
  RECEIVE_CHANNELS,
  RECEIVE_CHANNELS_SUMMARY,
  RECEIVE_CHANNEL,
} from '../actions/channel_actions';
import { RECEIVE_AUTHENTICATED_USER } from '.././actions/session_actions';

const channelReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_AUTHENTICATED_USER:
      return Object.assign({}, state, { [action.payload.channel?.id]: action.payload.channel });
    case RECEIVE_CHANNELS:
      return action.channels;
    case RECEIVE_CHANNEL:
      return Object.assign({}, state, { [action.payload.channel?.id]: action.payload.channel });
    default:
      return state;
  };
};

export default channelReducer;
