export const supportedLanguages = {
    'en-US' : 'English (United States): en-US',
    'es-US' : 'Spanish (United States): es-US',
    'fr' : 'French: fr',
};

export const languageOptions = Object.keys(supportedLanguages).map(value => ({
  value,
  label: supportedLanguages[value]
}));
