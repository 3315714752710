import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Field } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Logo from 'images/ci_logo.png';
import Button from 'react-bootstrap/Button';

import { sleep } from '../../components/util/helpers';
import { setPasswordFromInvitation } from '../../services/login_api';

const isPasswordValidLength = ({ values }) => {
  const { password } = values;
  return password?.length > 7;
};

const doPasswordsMatch = ({ values }) => {
  const { password, passwordConfirmation } = values;

  if (!password) {
    return false;
  }

  return password === passwordConfirmation;
};

const isPasswordValid = ({ values }) => {
  const isPasswordLongEnough =isPasswordValidLength({ values });

  return isPasswordLongEnough && doPasswordsMatch({ values });
};

const validationCheckClasses = ({ values, boolean }) => {
  const { password } = values;

  if (!password) {
    return 'validation-item d-flex mb-1 neutral';
  }

  return boolean
    ? 'validation-item d-flex mb-1 valid'
    : 'validation-item d-flex mb-1 invalid';
};

class InvitationPasswordSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      invalidCredentials: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = async values => {
    const { password, passwordConfirmation } = values;
    const { currentLocale, invitationToken } = this.props;

    const response = await setPasswordFromInvitation({
      password,
      passwordConfirmation,
      invitationToken,
    });

    const { errors } = response || {};

    if (errors) {
      this.setState({ invalidCredentials: true });
      this.setState({ isSubmitting: false });
    } else {
      window.location.href = window.location.origin;
    }
  }

  render () {
    const { currentLocale } = this.props;
    const { invalidCredentials, isSubmitting } = this.state;

    return (
      <section className="vh-100 login-workflows">
        <div className="container-fluid row d-flex align-items-center justify-content-center h-100">
          <div className="set-password set-password-form">
            <div className="login-logo-container mb-3">
              <img src={Logo} width={60} height={30} />
            </div>
            <div className="login-body">
              <div className="login-header">
                <div className="title">Set your password</div>
              </div>
              <Form
                onSubmit={(values) => {
                  this.setState({ isSubmitting: true });
                  this.onSubmit(values);
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <div className="inner-form-container">
                    <form onSubmit={handleSubmit}>
                      <label>Password</label>
                      <Field name="password" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-outline mb-4">
                            <input {...input} type="password" className="form-control form-control-md" />
                            {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                            {invalidCredentials && (
                              <span className="field-error">Incorrect email or password</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <label>Password confirmation</label>
                      <Field name="passwordConfirmation" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-outline mb-4">
                            <input {...input} type="password" className="form-control form-control-md" />
                            {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                            {invalidCredentials && (
                              <span className="field-error">Incorrect email or password</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <div className="password-validation-checks mb-2">
                        <div className={validationCheckClasses({ values, boolean: isPasswordValidLength({ values }) })}>
                          {isPasswordValidLength({ values }) ? (
                            <i className="bi bi-check-lg me-1"></i>
                          ) : (
                            <i className="bi bi-x me-1"></i>
                          )}
                          <div className="item">
                            password length 8 characters or greater
                          </div>
                        </div>
                        <div className={validationCheckClasses({ values, boolean: doPasswordsMatch({ values }) })}>
                          {doPasswordsMatch({ values }) ? (
                            <i className="bi bi-check-lg me-1"></i>
                          ) : (
                            <i className="bi bi-x me-1"></i>
                          )}
                          <div className="item">
                            password confirmation matches
                          </div>
                        </div>
                      </div>
                      <div className="submit">
                        {isSubmitting ? (
                          <div className="submit-button w-100 text-center submitting">
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            <span>Setting your password...</span>
                          </div>
                        ) : (
                          <input
                            type="submit"
                            name="commit"
                            value="Submit"
                            className="submit-button w-100"
                            disabled={submitting || !isPasswordValid({ values })}
                          />
                        )}
                      </div>
                      <div className="shared-registration-links mt-2">
                        <div>
                          <a href={`/${currentLocale}`}>
                            Home
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

InvitationPasswordSet.propTypes = {};

export default InvitationPasswordSet;

const required = value => value ? undefined : 'Required';
