import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class AboutPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.props;
    return (
      <div className="about-page-drawer channels">
        <div className="content-container">
          <div className="header">
            About this page
          </div>
          <div className="content">
            <div className="section">
              <div className="subheader">Purpose:</div>
              <div className="section-content">
                Manage invitations. If you attempt to add a new user, but the
                account already exists, a request will be sent to the account
                holder inviting them to join your account. Managing those
                account invitations takes place here.
              </div>
            </div>
            <div className="section">
              <div className="subheader">Invitation groupings:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Tabs
                </div>
                <div className="section-list">
                  <ul>
                    <li className="list-item">
                      Pending
                      <ul>
                        <li className="list-item first-sublist-item">
                          The existing account holder has not yet accepted or
                          denied your invitation.
                        </li>
                      </ul>
                    </li>
                    <li className="list-item">
                      Awaiting your final approval
                      <ul>
                        <li className="list-item first-sublist-item">
                          The existing account holder has accepted your
                          invitation. However, an admin must give a final
                          approval before the user joins your organization. This
                          secure access policy is in place in case the original
                          invitation was in error or obsolete.
                        </li>
                      </ul>
                    </li>
                    <li className="list-item">
                      Rejected by user
                      <ul>
                        <li className="list-item first-sublist-item">
                          The existing account holder has rejected your
                          invitation.
                        </li>
                      </ul>
                    </li>
                    <li className="list-item">
                      Approved
                      <ul>
                        <li className="list-item first-sublist-item">
                          An admin has given the final approval, and the user is
                          now a member of your organization.
                        </li>
                      </ul>
                    </li>
                    <li className="list-item">
                      Revoked
                      <ul>
                        <li className="list-item first-sublist-item">
                          An admin has revoked an invitations that was sent.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutPageDrawer;
