import { connect } from 'react-redux';
import React from 'react';
import { openModal, closeModal } from '../../actions/modal_actions';
import JoinChannelForm from './join_channel_form';
import {fetchChannels} from '../../actions/channel_actions';
import {createUserChannel, fetchUserChannels} from '../../actions/user_channel_actions';;
import { getAllPublicChannels, getJoinedChannels, getJoinedUsers} from '../../reducers/selectors';

const mapStateToProps = (state) => {
  return {
    errors: state.errors?.session,
    formType: 'join_channel',
    currentUser: state.entities.currentUser,
    getAllPublicChannels: getAllPublicChannels(state),
    getJoinedChannels: getJoinedChannels(state),
    getJoinedUsers: (channelId) => getJoinedUsers(state, channelId)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { currentUser, workspaceId } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    processForm: ({ userChannel }) => {
      return dispatch(createUserChannel({ organizationId, workspaceId, userChannel }))
    },
    closeModal: () => dispatch(closeModal()),
    fetchChannels: () => dispatch(fetchChannels({ organizationId, workspaceId })),
    fetchUserChannels: () => dispatch(fetchUserChannels({ organizationId, workspaceId })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinChannelForm);
