import { connect } from 'react-redux';
import React from 'react';
import { openModal, closeModal } from '../../actions/modal_actions';
import PublicChannelForm from './public_channel_form';
import { createChannel, updateChannel } from '../../actions/channel_actions';
import { isEducationOrganizationPersona } from '../../reducers/selectors';

const mapStateToProps = (state) => {
  const { entities } = state;

  return {
    errors: state.errors?.session,
    formType: 'new_public_channel',
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { currentUser, workspaceId, channelId } = ownProps;
  const { organization_id } = currentUser || {};

  return {
    processForm: ({ channel }) => dispatch(
      createChannel({ organizationId: organization_id, workspaceId, channel })
    ),
    processEdit: ({ channel }) => dispatch(
      updateChannel({ organizationId: organization_id, workspaceId, id: channelId, channel })
    ),
    closeModal: () => dispatch(closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicChannelForm);
