import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class WizardIntroductionStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      canUserCreateUsersOrTeams,
      isEducationOrganizationPersona,
    } = this.props;

    if (isEducationOrganizationPersona) {
      return (
        <div className="text-start activation-modal-home-page">
          <div className="mb-2 wizard-section-header">
            Allow us to guide you in how to quickly get{' '}
            <span className="bold">activated</span> with ContactImmed!
          </div>
          <div className="mb-4 wizard-section-header">
            We will cover the key features that will help you achieve the most
            success. <span className="bold">Congratulations to your
            district for centralizing resource requests, custodial and food
            service requests, safety alerts, and inter-district dialogue into a
            cohesive system, fostering enhanced collaboration and swift response
            times across all departments and stakeholders.</span>
          </div>
          <div className="section-header mb-4">
            This guide will cover the following:
          </div>
          <div className="section-list">
            <ol>
              <li className="list-item">
                Access to district workspaces
              </li>
              <li className="list-item">
                Logging requests
              </li>
              {canUserCreateUsersOrTeams && (
                <li className="list-item">
                  Inviting team members to collaborate
                </li>
              )}
              {canUserCreateUsersOrTeams && (
                <li className="list-item">
                  Creating a team
                </li>
              )}
              <li className="list-item">
                Access to teams
              </li>
              <li className="list-item last">
                Utilizing all of the above, along with additional insights, to
                enhance the dashboard for a deeper understanding of district
                trends and staff needs
                <ol>
                  <li className="list-item first-sublist-item">
                    This tool enables administrators to identify district-wide
                    trends, monitor real-time progress, and make informed
                    decisions using up-to-the-minute data on school operations
                    and instructional needs.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      );
    }

    return (
      <div className="text-start activation-modal-home-page">
        <div className="mb-2 wizard-section-header">
          Allow us to guide you in how to quickly get{' '}
          <span className="bold">activated</span> with ContactImmed!
        </div>
        <div className="mb-4 wizard-section-header">
          We will cover the key features that will help you achieve the most
          success <span className="bold">Cheers to your
          organization for always keeping the customer in focus and "activating"
          deals as a result.</span>
        </div>
        <div className="section-header mb-4">
          This guide will cover the following:
        </div>
        <div className="section-list">
          <ol>
            <li className="list-item">
              Logging customer requests
            </li>
            {canUserCreateUsersOrTeams && (
              <li className="list-item">
                Inviting team members to collaborate
              </li>
            )}
            {canUserCreateUsersOrTeams && (
              <li className="list-item">
                Creating a team
              </li>
            )}
            <li className="list-item last">
              Utilizing all of the above, along with additional insights, to
              enhance the dashboard for a deeper understanding of business
              trends and customer needs.
              <ol>
                <li className="list-item first-sublist-item">
                  Gain insights into where your customers face the most
                  challenges, the popularity of various requests, and the
                  financial impact of your efforts. This dashboard allows all
                  stakeholders to quickly orient themselves and see which
                  customer voices are directly relevant to them.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

WizardIntroductionStep.propTypes = {
  canUserCreateUsersOrTeams: PropTypes.bool.isRequired,
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
};

export default WizardIntroductionStep;
