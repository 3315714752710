import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Field, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays';
import Wizard from '../../components/FormWizard/wizard';
import WizardIntroductionStep from './wizard_introduction_step';
import WizardRequestCreationStepHeader from './wizard_request_creation_step_header';
import WizardUserCreationStepHeader from './wizard_user_creation_step_header';
import WizardTeamCreationStepHeader from './wizard_team_creation_step_header';
import WizardMissionCompleteStep from './wizard_mission_complete_step';

import { sleep } from '../../components/util/helpers';
import { createChannelWithoutRedux } from '../../services/channel_api';
import {
  assignWorkspaceToUser,
  requestAccessToOrganizationTeams,
  requestAccessToOrganizationWorkspaces,
  upsertUser,
} from '../../services/organization';
import { upsertTeam } from '../../services/team';

const DEFAULT_USER_ACCOUNT_ROLE = 'account_user';
const DEFAULT_WORKSPACE_ACCOUNT_ROLE = 'workspace_user';

const onSubmit = async values => {
  await sleep(300);
  return;
}

const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span className="input-error">{error}</span> : null
    }
  />
)

const required = value => (value ? undefined : 'Required');

const alreadyAssignedClass = option =>
  option.alreadyAssigned ? 'workspace-already-assigned' : ''

class ActivationWizardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workspaceItems: props.organizationWorkspaceNames?.map(name => ({
        value: name,
        label: name,
        checked: false,
        alreadyAssigned: !!props.userWorkspaces?.filter(workspace =>
          workspace.name?.toLowerCase() === name.toLowerCase(),
        )?.length,
      })) || [{}],
      teamItems: props.teamsWithinUserWorkspaces?.map(team => ({
        value: team.id,
        label: `${team.name} - ${team.workspace_name}`,
        checked: false,
        alreadyAssigned: team.is_already_assigned,
      })) || [{}],
      isRequestCreationSubmitting: false,
      isUserCreationSubmitting: false,
      isTeamCreationSubmitting: false,
      isTeamAccessRequestSubmitting: false,
      isWorkspaceAccessRequestSubmitting: false,
    };
    this.handleCreateNewRequest = this.handleCreateNewRequest.bind(this);
    this.handleCreateNewUser = this.handleCreateNewUser.bind(this);
    this.handleCreateNewTeam = this.handleCreateNewTeam.bind(this);
    this.handleRequestAccessToWorkspaces = this.handleRequestAccessToWorkspaces.bind(this);
    this.handleSelectWorkspaceItem = this.handleSelectWorkspaceItem.bind(this);
    this.handleSelectTeamItem = this.handleSelectTeamItem.bind(this);
    this.handleRequestAccessToTeams = this.handleRequestAccessToTeams.bind(this);
  }

  handleCreateNewRequest = async (values) => {
    await sleep(300);
    const { requestName, requestDescription } = values || {};

    if (!requestName) {
      return;
    }

    const { workspaceId } = this.props;
    const channel = { name: requestName, description: requestDescription };

    const response = await createChannelWithoutRedux({
      channel,
      workspaceId,
    });

    if (response?.channel?.id) {
      this.setState({ newRequestId: request.channel.id });
    }
    this.setState({ isRequestCreationSubmitting: false });
  }

  handleCreateNewUser = async (values) => {
    await sleep(300);
    const {
      userAccountRole,
      userEmail,
      userFirstName,
      userLastName,
      userWorkspaceRole,
    } = values || {};

    if (!userFirstName || !userLastName || !userEmail) {
      return;
    }

    const { currentUser, workspaceId } = this.props;
    const organization_id = currentUser?.organization_id;

    const response = await upsertUser({
      organization_id,
      first_name: userFirstName,
      last_name: userLastName,
      email: userEmail,
      roles: [userAccountRole || DEFAULT_USER_ACCOUNT_ROLE],
    });

    const { id } = response || {};

    if (id) {
      this.setState({ newUserId: id });
      const assignmentResponse = await assignWorkspaceToUser({
        organization_id,
        user_id: id,
        workspace_id: workspaceId,
        role: userWorkspaceRole || DEFAULT_WORKSPACE_ACCOUNT_ROLE,
      });
    }
    this.setState({ isUserCreationSubmitting: false });
  }

  handleCreateNewTeam = async (values) => {
    await sleep(300);
    const { teamName, teamDescription } = values || {};

    if (!teamName) {
      return;
    }

    const { currentUser, workspaceId } = this.props;
    const organization_id = currentUser?.organization_id;

    const response = await upsertTeam({
      organization_id,
      name: teamName,
      description: teamDescription,
      workspace_id: workspaceId,
    });

    const { id } = response || {};

    if (id) {
      this.setState({ newTeamId: id });
    }
    this.setState({ isTeamCreationSubmitting: false });
  }

  handleRequestAccessToWorkspaces = async (values) => {
    await sleep(300);
    const { workspaceItems } = this.state;
    const workspaceAccessRequestList = workspaceItems.filter(item =>
      !item.alreadyAssigned && item.checked
    )

    if (!workspaceAccessRequestList || workspaceAccessRequestList.length === 0) {
      return;
    }

    const { currentUser } = this.props;
    const organization_id = currentUser?.organization_id;

    const response = await requestAccessToOrganizationWorkspaces({
      organization_id,
      workspace_access_request_list: workspaceAccessRequestList.map(list => list.value),
    });

    const { success } = response || {};

    if (success) {
      this.setState({ workspaceRequestSubmitted: true });
    }
    this.setState({ isWorkspaceAccessRequestSubmitting: false });
  }

  handleRequestAccessToTeams = async (values) => {
    await sleep(300);
    const { teamItems } = this.state;
    const teamAccessRequestList = teamItems.filter(item =>
      !item.alreadyAssigned && item.checked
    )

    if (!teamAccessRequestList || teamAccessRequestList.length === 0) {
      return;
    }

    const { currentUser } = this.props;
    const organization_id = currentUser?.organization_id;

    const response = await requestAccessToOrganizationTeams({
      organization_id,
      team_access_request_list: teamAccessRequestList.map(list => list.value),
    });

    const { success } = response || {};

    if (success) {
      this.setState({ teamRequestSubmitted: true });
    }
    this.setState({ isTeamAccessRequestSubmitting: false });
  }

  handleSelectWorkspaceItem = (event) => {
    const itemValue = event?.target?.name;
    const isChecked = event?.target?.checked;

    this.setState((prevState) => ({
      workspaceItems: prevState.workspaceItems.map((item) => {
        if (`${item.value}` === itemValue) {
          return { ...item, checked: isChecked };
        } else {
          return item;
        }
      }),
    }));
  }

  handleSelectTeamItem = (event) => {
    const itemValue = event?.target?.name;
    const isChecked = event?.target?.checked;

    this.setState((prevState) => ({
      teamItems: prevState.teamItems.map((item) => {
        if (`${item.value}` === itemValue) {
          return { ...item, checked: isChecked };
        } else {
          return item;
        }
      }),
    }));
  }

  render() {
    const {
      currentLocale,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      isEducationOrganizationPersona,
      myWorkspaces,
      onClose,
      organizationWorkspaces,
      userWorkspaces,
      workspaceId,
    } = this.props;

    const {
      isRequestCreationSubmitting,
      isTeamCreationSubmitting,
      isTeamAccessRequestSubmitting,
      isUserCreationSubmitting,
      isWorkspaceAccessRequestSubmitting,
      newRequestId,
      newUserId,
      newTeamId,
      teamItems,
      teamRequestSubmitted,
      workspaceItems,
      workspaceRequestSubmitted,
    } = this.state;

    const doesUserHaveAccessToEveryWorkspace =
      isCurrentUserAccountAdmin ||
      workspaceItems?.filter(item =>
        !item.alreadyAssigned
      ).length === 0;

    // const doesUserHaveAccessToEveryWorkspace = false;

    const doesUserHaveAccessToAnyWorkspace =
      isCurrentUserAccountAdmin ||
      workspaceItems?.filter(item =>
        item.alreadyAssigned
      ).length > 0;

    // const doesUserHaveAccessToAnyWorkspace = false;

    const canUserCreateUsersOrTeams =
      isCurrentUserAccountAdmin || isCurrentUserWorkspaceManager;

    return (
      <div className="activation-wizard-modal activation-wizard custom-ui">
        <div className="h1 mb-4">Activation Launchpad</div>
        {/*
          <div className="h1 mb-4">Activation Launchpad</div>
          <h2>Quick Start Form</h2>
        */}
        <Wizard
          initialValues={{
            userAccountRole: DEFAULT_USER_ACCOUNT_ROLE,
            userWorkspaceRole: DEFAULT_WORKSPACE_ACCOUNT_ROLE,
          }}
          onSubmit={onSubmit}
          omitFinalOnSubmit={true}
        >
          <Wizard.Page>
            <WizardIntroductionStep
              canUserCreateUsersOrTeams={canUserCreateUsersOrTeams}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Wizard.Page>
          {isEducationOrganizationPersona && (
            <Wizard.Page>
              <div className="mb-2 wizard-section-header text-start">
                Workspace access
              </div>
              <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
                <div>
                  Workspaces represent the various school entities within your
                  district. The workspaces you have access to are listed in the
                  navigation bar and below the "Dashboard" panel on the home
                  page.
                </div>
                <div className="mt-3">
                  If you need access to additional workspaces to fulfill your
                  school functions, we've outlined them here. For example, a
                  high school teacher may only need access to the high school
                  workspace, while a custodial team member may require access
                  to multiple school workspaces.
                </div>
              </div>
              <div className="fields">
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    {doesUserHaveAccessToEveryWorkspace ? (
                      <label className="mb-2">
                        Congratulations! You have full access to all workspaces
                        and can skip this step.
                      </label>
                    ) : (
                      <>
                        <label className="mb-2">
                          Select a workspace if you need access. A request will
                          be submitted to your district's admin, who will either
                          approve or deny it.
                        </label>
                        <div className="workspace-list-container d-flex row">
                          {workspaceItems.map((option) => (
                            <label className="mt-1" key={option.value}>
                              <input
                                className={`me-2 workspace-checkbox ${alreadyAssignedClass(option)}`}
                                type="checkbox"
                                name={`${option.value}`}
                                checked={option.checked}
                                onChange={this.handleSelectWorkspaceItem}
                                disabled={option.alreadyAssigned}
                              />
                              <span className={`workspace-checkbox-label ${alreadyAssignedClass(option)}`}>
                                {option.label}
                              </span>
                              {option.alreadyAssigned && (
                                <span className="ms-1 workspace-already-assigned-label">
                                  *You are already a member of this workspace
                                </span>
                              )}
                            </label>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {!doesUserHaveAccessToEveryWorkspace && (
                <>
                  {workspaceRequestSubmitted ? (
                    <div className="text-start py-4 px-3 step-completion-container mt-4">
                      Congratulations on successfully requesting access to the
                      workspace(s)! <b>If you do not currently have access to any
                      workspace, admin approval will be required before you can
                      proceed with the activation guide</b>. You will receive an
                      email notification once access is granted.
                    </div>
                  ) : (
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => isWorkspaceAccessRequestSubmitting ? (
                        <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting" disabled={true}>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          <span>Submitting...</span>
                        </button>
                      ) : (
                        <button
                          className="submit-button mt-4"
                          onClick={() => {
                          this.setState({ isWorkspaceAccessRequestSubmitting: true });
                          this.handleRequestAccessToWorkspaces(values);
                        }}>
                          Request Access
                        </button>
                      )}
                    </FormSpy>
                  )}
                </>
              )}

            </Wizard.Page>
          )}
          {doesUserHaveAccessToAnyWorkspace && (
            <Wizard.Page>
              <div className="mb-2 wizard-section-header text-start">
                Create a request
              </div>
              <WizardRequestCreationStepHeader
                isEducationOrganizationPersona={isEducationOrganizationPersona}
              />
              <div className="fields">
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    <label className="mb-2">Request name</label>
                    <Field
                      name="requestName"
                      component="input"
                      className="form-control"
                      type="text"
                      placeholder="Request name"
                      validate={required}
                    />
                  </div>
                  <Error name="requestName" />
                </div>
                <div className="d-flex flex-column text-start mb-2">
                  <div className="field-container">
                    <label className="mb-2">Request description (optional)</label>
                    <Field
                      name="requestDescription"
                      component="textarea"
                      className="form-control"
                      type="text"
                      placeholder={`# Description to provide additional details`}
                    />
                  </div>
                  <Error name="requestDescription" />
                </div>
              </div>
              {newRequestId ? (
                <div className="text-start py-4 px-3 step-completion-container mt-4">
                  Congratulations on successfully creating a new request! You can
                  create additional requests through the workspace here {' '}
                  <a
                    href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${newRequestId}`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                </div>
              ) : (
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => isRequestCreationSubmitting ? (
                    <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting" disabled={true}>
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      <span>Submitting...</span>
                    </button>
                  ) : (
                    <button
                      className="submit-button mt-4"
                      onClick={() => {
                      this.setState({ isRequestCreationSubmitting: true });
                      this.handleCreateNewRequest(values);
                    }}>
                      Create a request
                    </button>
                  )}
                </FormSpy>
              )}
            </Wizard.Page>
          )}
          {doesUserHaveAccessToAnyWorkspace && canUserCreateUsersOrTeams && (
            <Wizard.Page
              validate={values => {
                const errors = {}
                if (!values.userEmail) {
                  errors.userEmail = 'Required'
                }
                if (!values.userFirstName) {
                  errors.userFirstName = 'Required'
                }
                if (!values.userLastName) {
                  errors.userLastName = 'Required'
                }
                return errors
              }}
            >
              <div className="mb-2 wizard-section-header text-start">
                Create a user
              </div>
              <WizardUserCreationStepHeader
                isEducationOrganizationPersona={isEducationOrganizationPersona}
              />
              <div className="fields">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column text-start mb-4">
                    <div className="field-container">
                      <label className="mb-2">First name</label>
                      <Field
                        name="userFirstName"
                        component="input"
                        className="form-control activation-wizard-new-user-name"
                        type="text"
                        placeholder="First name"
                        validate={required}
                      />
                    </div>
                    <Error name="userFirstName" />
                  </div>
                  <div className="d-flex flex-column text-start mb-4">
                    <div className="field-container">
                      <label className="mb-2">Last name</label>
                      <Field
                        name="userLastName"
                        component="input"
                        className="form-control activation-wizard-new-user-name"
                        type="text"
                        placeholder="Last name"
                        validate={required}
                      />
                    </div>
                    <Error name="userLastName" />
                  </div>
                </div>
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    <label className="mb-2">Email</label>
                    <Field
                      name="userEmail"
                      component="input"
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      validate={required}
                    />
                  </div>
                  <Error name="userEmail" />
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex flex-column text-start">
                    <div className="field-container">
                      <label className="mb-2">Account role</label>
                      <Field
                        name="userAccountRole"
                        component="select"
                        className="form-select activation-wizard-new-user-role"
                      >
                        <option value="account_user">Account user</option>
                        <option value="account_admin">Account admin</option>
                      </Field>
                    </div>
                    <Error name="userAccountRole" />
                  </div>
                  <div className="d-flex flex-column text-start">
                    <div className="field-container">
                      <label className="mb-2">Workspace role</label>
                      <Field
                        name="userWorkspaceRole"
                        component="select"
                        className="form-select activation-wizard-new-user-role"
                      >
                        <option value="workspace_user">Workspace user</option>
                        <option value="workspace_manager">Workspace manager</option>
                      </Field>
                    </div>
                    <Error name="userWorkspaceRole" />
                  </div>
                </div>
              </div>
              {newUserId ? (
                <div className="text-start py-4 px-3 step-completion-container mt-4">
                  Congratulations on successfully creating a new user! Users
                  can be mentioned in request messages, triggering notifications
                  for their immediate attention. You can create additional users
                  in the user settings here {' '}
                  <a
                    href={`/#/${currentLocale}/settings/access_management`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                </div>
              ) : (
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => isUserCreationSubmitting ? (
                    <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting">
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      <span>Submitting...</span>
                    </button>
                  ) : (
                    <button
                      className="submit-button mt-4"
                      onClick={() => {
                      this.setState({ isUserCreationSubmitting: true });
                      this.handleCreateNewUser(values);
                    }}>
                      Create a user
                    </button>
                  )}
                </FormSpy>
              )}

            </Wizard.Page>
          )}
          {doesUserHaveAccessToAnyWorkspace && canUserCreateUsersOrTeams && (
            <Wizard.Page>
              <div className="mb-2 wizard-section-header text-start">
                Create a team
              </div>
              <WizardTeamCreationStepHeader
                isEducationOrganizationPersona={isEducationOrganizationPersona}
              />
              <div className="fields">
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    <label className="mb-2">Team name</label>
                    <Field
                      name="teamName"
                      component="input"
                      className="form-control"
                      type="text"
                      placeholder="Team name"
                      validate={required}
                    />
                  </div>
                  <Error name="teamName" />
                </div>
                <div className="d-flex flex-column text-start mb-2">
                  <div className="field-container">
                    <label className="mb-2">Team description (optional)</label>
                    <Field
                      name="teamDescription"
                      component="textarea"
                      className="form-control"
                      type="text"
                      placeholder={`# Description of the team`}
                    />
                  </div>
                  <Error name="teamDescription" />
                </div>
              </div>
              {newTeamId ? (
                <div className="text-start py-4 px-3 step-completion-container mt-4">
                  Congratulations on successfully creating a team! Team tags
                  allow you to easily "assign" requests to stakeholders within
                  a specific area. You can create additional teams in the team settings here {' '}
                  <a
                    href={`/#/${currentLocale}/settings/access_management?access_tab=teams`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                </div>
              ) : (
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => isTeamCreationSubmitting ? (
                    <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting">
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      <span>Submitting...</span>
                    </button>
                  ) : (
                    <button
                      className="submit-button mt-4"
                      onClick={() => {
                      this.setState({ isTeamCreationSubmitting: true });
                      this.handleCreateNewTeam(values);
                    }}>
                      Create a team
                    </button>
                  )}
                </FormSpy>
              )}
            </Wizard.Page>
          )}
          {doesUserHaveAccessToAnyWorkspace && isEducationOrganizationPersona && (
            <Wizard.Page>
              <div className="mb-2 wizard-section-header text-start">
                Team access
              </div>
              <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
                <div>
                  Teams represent groups within an individual school workspace.
                  They are an effective way to organize coworkers by area of
                  responsibility, reflecting the district's structure. Requests can be
                  assigned to teams using the "assigned teams" option, allowing
                  the request to quickly reach the team(s) best equipped to
                  collaborate.
                </div>
                <div className="mt-3">
                  If ther are additional teams you should join to represent
                  your role(s) in the district, we've listed them here. For
                  example, a school principal may need access access to the
                  "Principal's Office" team within their school, while a food
                  service employee may require access to the food service team
                  within the school.
                </div>
              </div>
              <div className="fields">
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    <label className="mb-2">
                      Select a team if you need access. A request
                      will be submitted to your admin, who will either approve
                      or deny it.
                    </label>
                    <div className="workspace-list-container d-flex row">
                      {teamItems.map((option) => (
                        <label className="mt-1" key={option.value}>
                          <input
                            className={`me-2 workspace-checkbox ${alreadyAssignedClass(option)}`}
                            type="checkbox"
                            name={`${option.value}`}
                            checked={option.checked}
                            onChange={this.handleSelectTeamItem}
                            disabled={option.alreadyAssigned}
                          />
                          <span className={`workspace-checkbox-label ${alreadyAssignedClass(option)}`}>
                            {option.label}
                          </span>
                          {option.alreadyAssigned && (
                            <span className="ms-1 workspace-already-assigned-label">
                              *You are already a member of this team
                            </span>
                          )}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                {teamRequestSubmitted ? (
                  <div className="text-start py-4 px-3 step-completion-container mt-4">
                    Congratulations on successfully requesting access to the
                    team(s)! You will receive an email notification once
                    access is granted.
                  </div>
                ) : (
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => isTeamAccessRequestSubmitting ? (
                      <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting" disabled={true}>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        <span>Submitting...</span>
                      </button>
                    ) : (
                      <button
                        className="submit-button mt-4"
                        onClick={() => {
                        this.setState({ isTeamAccessRequestSubmitting: true });
                        this.handleRequestAccessToTeams(values);
                      }}>
                        Request Access
                      </button>
                    )}
                  </FormSpy>
                )}
              </div>
            </Wizard.Page>
          )}
          {doesUserHaveAccessToAnyWorkspace && (
            <Wizard.Page>
              <WizardMissionCompleteStep
                currentLocale={currentLocale}
                isEducationOrganizationPersona={isEducationOrganizationPersona}
                newRequestId={newRequestId}
                workspaceId={workspaceId}
              />
            </Wizard.Page>
          )}
        </Wizard>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-2 close-wizard-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

ActivationWizardModal.propTypes = {
  handleChoosePlan: PropTypes.func,
  planToChoose: PropTypes.string,
  onClose: PropTypes.func,
};

export default ActivationWizardModal;
