import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import MessageFormContainer from './message_form/message_form_container';
import ChannelHeaderContainer from './channel_header/channel_header_container';
import MessageListContainer from './message_list/message_list_container';
import ChannelMetadataContainer from './channel_metadata/channel_metadata_container';
import FilteredListContainer from './filtered_list/filtered_list_container';

import { lastElementOfArray } from '../../util/helpers';

class Channel extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      channelId,
      workspaceId,
      isChannelsLoading,
      actionCabelUrl,
      location,
    } = this.props;

    const isFilteredChannelsView =
      location?.pathname === `/workspaces/${workspaceId}/requests/filter`;

    if (isChannelsLoading) {
      return (
        <div className="channel-main">
          <div className="loading-spinner-container">
            <Spinner animation="border" role="status" variant="secondary" />
          </div>
        </div>
      );
    }

    if (isFilteredChannelsView) {
      return (
        <div className="channel-main channels-summary-view">
          <FilteredListContainer
            currentUser={currentUser}
            key={`channels-list-filter-${location?.search}`}
          />
        </div>
      );
    }

    return (
      <div className="channel-main">
        <ChannelHeaderContainer
          currentUser={currentUser}
          key={`channel-header-${channelId}`}
        />
        <MessageListContainer
          actionCabelUrl={actionCabelUrl}
          key={`message-list-${channelId}`}
        />
        <MessageFormContainer
          currentUser={currentUser}
          actionCabelUrl={actionCabelUrl}
        />
        <ChannelMetadataContainer currentUser={currentUser} />
      </div>
    );
  }
}

export default Channel;
