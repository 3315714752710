import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import {
  fetchOrganizationTeams,
  fetchOrganizationWorkspaceTags,
} from '../../../actions/organization_actions';

const msp = (state) => {
  return {
    currentUser: state.entities.currentUser,
    teams: state.entities.organization.teams,
    pages: state.entities.organization.pages || 1,
    teamsCount: state.entities.organization.teamsCount || 0,
    teamTags: (state.entities.organization.tags.team || {}).entries || [],
    workspacesAvailable: state.entities.workspaces,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchTeams: ({ page, pageSize, search, sort }) =>
      dispatch(fetchOrganizationTeams({
        organizationId,
        workspaceId,
        page,
        pageSize,
        search,
        sort,
      })
    ),
    fetchTags: ({ search, tagType }) => workspaceId &&
      dispatch(fetchOrganizationWorkspaceTags({
        organizationId,
        workspaceId,
        search,
        tagType,
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
