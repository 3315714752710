import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class AboutPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currentLocale, isCurrentUserAccountAdmin } = this.props;

    return (
      <div className="about-page-drawer channels">
        <div className="content-container">
          <div className="header">
            About this page
          </div>
          <div className="content">
            <div className="section">
              <div className="subheader">Purpose:</div>
              <div className="section-content">
                <div className="section-paragraph">
                  Communicate the voice of the customer and their desires.
                  Use this information to delight the customer and elevate the
                  value of your organization.
                </div>
                <div className="section-paragraph">
                  Sales, customer success, and other interested agents can
                  easily create Customer Requests that succintly describe what
                  you're hearing from the customer and internal stakeholders.
                </div>
                <div className="section-paragraph">
                  In addition, provide details such as opportunity/deal value,
                  customers associated, assigned teams, feature links, company
                  assets affected, etc... so that your teammates will always be
                  intuned to what customers are saying and experiencing. Make it
                  super easy for teammates to quickly distinguish desires by
                  categorizing them. Let your coworkers know about
                  "Broken Windows", "Quick Wins", or if you're just asking for
                  help. Let the link between sales, customer success, and
                  delivery teams always remain a tight bond.
                </div>
                <div className="section-paragraph">
                  And delivery teams can opt to act, helping sales,
                  customer success, and, most importantly, your customers.
                </div>
              </div>
            </div>
            <div className="section">
              <div className="subheader">To get started:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Create Customer Requests:
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Create a Customer Request with just a name. Once created,
                      provide as much information in the "Request details" section
                      as you can.
                      <ol>
                        <li className="list-item first-sublist-item">
                          Don't forget to include the customers it affects. Teams
                          should know who the customer is.
                        </li>
                        <li className="list-item first-sublist-item">
                          In addition, if you know which team(s) it applies to,
                          include them in assigned teams. If the team is not yet
                          a menu option, then create a new menu option.
                          A workplace manager can assign teammates to suggested
                          team through {isCurrentUserAccountAdmin
                            ? <a href={`/#/${currentLocale}/settings/access_management?access_tab=teams`}target="_blank">admin settings</a>
                            : 'admin settings'
                          }.
                        </li>
                      </ol>
                    </li>
                    <li className="list-item">
                      Also notice the left panel's "Summary" section. There, anyone can, at
                      a glance, see Quick Wins, Help Wanted, Broken Windows, and items
                      assigned to them via Assigned Teams.
                    </li>
                    <li className="list-item">
                      The more you share the voice of the customer, the more your teammates
                      can see, hear, and understand what they're experiencing. <b>And Act!</b>
                    </li>
                  </ol>
                </div>
                <div className="underline subsection-header mb-2">
                  Message teammates:
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Participate in the discussion. See a desire that's easy to
                      fulfill or see one that picks your interest, then let your
                      teammates know.
                    </li>
                    <li className="list-item">
                      Individual Customer Requests are meant to be short lived.
                      Grasp the pain point or trend, delight the customer,
                      and deliver value.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutPageDrawer;
