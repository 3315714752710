import { connect } from 'react-redux';
import Teams from './teams';
import withRouter from '../../../util/with_router';

const msp = (state, ownProps) => {
  return {
    currentUser: state.entities.currentUser,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    // action: () => dispatch(action({ organizationId })),
  };
};

export default withRouter(connect(msp, mdp)(Teams));
