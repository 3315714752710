import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class AboutPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.props;
    return (
      <div className="about-page-drawer channels">
        <div className="content-container">
          <div className="header">
            About this page
          </div>
          <div className="content">
            <div className="section">
              <div className="subheader">Purpose:</div>
              <div className="section-content mb-2">
                Customer tags are used in "Requests" so that delivery
                teams, and other stakeholders, can know whose voices are
                speaking. They are typically added and created within workspace
                Requests directly via the "customers" input.
              </div>
              <div className="section-content">
                The big enabler in this view is "customer renewal date". By
                adding customer renewal dates for tags representing customers,
                you enable a dahsboard widget that allows delivery teams to know
                which customers are nearing renewal and to prioritize their requests
                in order to boost renewals.
              </div>
            </div>
            <div className="section">
              <div className="subheader">To get started:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Edit a tag
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Add and update renewal dates
                      <ul>
                        <li className="list-item first-sublist-item">
                          <b>Customer Success</b> representatives are meant to
                          manage this page. Monitor the status of customers that
                          you oversee.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutPageDrawer;
