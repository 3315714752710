export const requestsOverTimeDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return {
      entries: [
        {
          date: '2024-05-08',
          count: 10,
        },
        {
          date: '2024-05-04',
          count: 18,
        },
        {
          date: '2024-05-01',
          count: 2,
        },
        {
          date: '2024-04-21',
          count: 0,
        },
        {
          date: '2024-04-18',
          count: 45,
        },
        {
          date: '2024-02-10',
          count: 1,
        },
        {
          date: '2024-02-08',
          count: 1,
        },
        {
          date: '2024-02-05',
          count: 7,
        },
        {
          date: '2023-12-11',
          count: 20,
        },
        {
          date: '2023-12-10',
          count: 4,
        },
      ].reverse(),
    };
  }
  return { entries: [] };
}

export const requestsStatusSummaryDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return { total: 40, in_progress: 20, completed: 60 };
  }
  return {};
}

export const assetChurnRiskDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return {
     entries: [
       { tag_name: 'Integrations', count: 343 },
       { tag_name: 'CSV Export', count: 156 },
       { tag_name: 'RBAC', count: 133 },
       { tag_name: 'Site Speed', count: 234 },
       { tag_name: 'History Chart', count: 123 },
       { tag_name: 'Reporting', count: 512 },
       { tag_name: 'Login (SSO)', count: 12 },
     ],
   };
  }
  return { entries: [] };
}

export const customerRequestsByDealValue = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return {
      entries: [
        {
          channel_id: '9ef732a2-1928-429e-b607-c12df362e448',
          channel_name: 'AI analysis of data',
          status: 'ready',
          opportunity_value: 15000000,
          opportunity_value_currency: 'USD'
        },
        {
          channel_id: '81a4aaab-90ed-4811-9730-19a60276f405',
          channel_name: 'Reporting: User Expects Ability to Export List as CSV',
          status: 'in_progress',
          opportunity_value: 12340000,
          opportunity_value_currency: 'USD'
        },
        {
          channel_id: '4e1ec3c3-dfc5-435d-abca-bca4cd1d5709',
          channel_name: 'Customer Needs SSO to Work with OKTA ',
          status: 'testing',
          opportunity_value: 10000000,
          opportunity_value_currency: 'USD'
        },
        {
          channel_id: '98a415a9-f27e-4b00-95cf-90be6f222c9e',
          channel_name: 'RBAC Needed in order to operationalize',
          status: 'ready',
          opportunity_value: 8000000,
          opportunity_value_currency: 'USD'
        },
        {
          channel_id: '83e62cca-a4a9-4c04-b229-69354aef1b5d',
          channel_name: 'Churn Risk: Customer Needs Stopgap for Teams',
          status: 'ready',
          opportunity_value: 7000000,
          opportunity_value_currency: 'USD'
        },
        {
          channel_id: 'e3c168b3-ddbe-4fda-8af8-553f68789fa8',
          channel_name: 'Scheduled reports',
          status: 'ready',
          opportunity_value: 5030000,
          opportunity_value_currency: 'USD'
        },
      ],
    };
  }

  return { entries: [] };
}

export const atRiskCustomersDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return {
      entries: [
        {
          customer: 'ACME Corp',
          customer_health_status: 'at_risk',
          active_requests_count: 8,
        },
        {
          customer: 'Stark Industries',
          customer_health_status: 'at_risk',
          active_requests_count: 5,
        },
        {
          customer: 'Security Lock',
          customer_health_status: 'at_risk',
          active_requests_count: 4,
        },
        {
          customer: 'SAAS, Inc.',
          customer_health_status: 'at_risk',
          active_requests_count: 3,
        },
        {
          customer: 'AI Intelligence',
          customer_health_status: 'at_risk',
          active_requests_count: 3,
        },
        {
          customer: 'Motors International',
          customer_health_status: 'at_risk',
          active_requests_count: 3,
        },
      ],
    };
  }
  return { entries: [] };
}

export const requestsByCustomerRiskLevelsDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return {
      entries: [
        {
          customer_health_status: 'at_risk',
          active_requests_count: 24,
        },
        {
          customer_health_status: 'neutral',
          active_requests_count: 8,
        },
        {
          customer_health_status: 'healthy',
          active_requests_count: 13,
        },
        {
          customer_health_status: undefined,
          active_requests_count: 60,
        },
      ],
    };
  }
  return { entries: [] };
}

export const pulseOfTheCustomerDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return {
      entries: [
        {
          channel_id: '4e1ec3c3-dfc5-435d-abca-bca4cd1d5709',
          channel_name: 'Customer Needs SSO to Work with OKTA ',
          favorites_rank: 100,
          created_at: '2022-08-26T01:05:58.970Z',
          time_ago: 'over 1 year',
        },
        {
          channel_id: 'b16112e2-ebd6-4e69-bb3b-8b63d595ba11',
          channel_name: 'Renewal: Roadmap for AI Vision',
          favorites_rank: 84,
          created_at: '2022-12-02T04:17:26.203Z',
          time_ago: 'over 1 year',
        },
        {
          channel_id: '8259a598-4b28-4610-b11e-689601f02207',
          channel_name: 'UI Back Button Color is Not Consistent',
          favorites_rank: 71,
          created_at: '2022-08-26T01:36:47.826Z',
          time_ago: 'over 1 year',
        },
        {
          channel_id: '9bfa7907-7bca-46c7-9469-1e7ee5d6952e',
          channel_name: 'Two factor authentication',
          favorites_rank: 54,
          created_at: '2024-02-08T02:21:50.373Z',
          time_ago: '3 months',
        },
        {
          channel_id: '5fc47698-376c-4268-bd1e-900377a2f49b',
          channel_name: 'Welcome',
          favorites_rank: 49,
          created_at: '2022-08-07T19:02:44.778Z',
          time_ago: 'almost 2 years',
        },
        {
          channel_id: 'e3c168b3-ddbe-4fda-8af8-553f68789fa8',
          channel_name: 'Scheduled reports',
          favorites_rank: 41,
          created_at: '2024-05-01T02:27:30.772Z',
          time_ago: '12 days',
        },
        {
          channel_id: '98a415a9-f27e-4b00-95cf-90be6f222c9e',
          channel_name: 'RBAC Needed in order to operationalize',
          favorites_rank: 35,
          created_at: '2024-05-01T16:49:05.104Z',
          time_ago: '11 days',
        }
      ],
    };
  }
  return { entries: [] };
}

export const unresolvedRequestsNearRenewalDemoData = ({ persona, month }) => {
  if (!persona || persona === 'tech') {
    return {
      entries: [
        {
          channel_id: '9ef732a2-1928-429e-b607-c12df362e448',
          channel_name: 'AI analysis of data',
          opportunity_value: 15000000,
          opportunity_value_currency: 'USD',
          status: 'ready',
          created_at: '2024-02-05T03:49:35.110Z',
          time_ago: '3 months',
          renewals_approaching: [
            {
              id: '84bf158e-1ad8-4894-a601-52bc8d20e1a8',
              name: 'Security Lock',
              customer_renewal_date: '2024-07-01',
            },
            {
              id: 'a2050dcc-a61f-4777-9504-0c6a3252a729',
              name: 'Motors International',
              customer_renewal_date: '2024-07-07',
            },
            {
              id: 'ee45d7d3-c44a-45c0-9736-97979b87fcc9',
              name: 'Stark Industries',
              customer_renewal_date: '2024-06-13',
            },
            {
              id: 'a2a0b8a4-4205-43b8-8a5c-01c93650cd45',
              name: 'ACME Corp',
              customer_renewal_date: '2024-06-10',
            },
          ],
        },
        {
          channel_id: '81a4aaab-90ed-4811-9730-19a60276f405',
          channel_name: 'Reporting: User Expects Ability to Export List as CSV',
          opportunity_value: 12340000,
          opportunity_value_currency: 'USD',
          status: 'in_progress',
          created_at: '2022-08-23T23:10:24.362Z',
          time_ago: 'over 1 year',
          renewals_approaching: [
            {
              id: 'a2050dcc-a61f-4777-9504-0c6a3252a729',
              name: 'Motors International',
              customer_renewal_date: '2024-07-07',
            },
            {
              id: 'a2a0b8a4-4205-43b8-8a5c-01c93650cd45',
              name: 'ACME Corp',
              customer_renewal_date: '2024-06-10',
            },
          ],
        },
        {
          channel_id: 'e3c168b3-ddbe-4fda-8af8-553f68789fa8',
          channel_name: 'Scheduled reports',
          opportunity_value: 10000000,
          opportunity_value_currency: 'USD',
          status: 'ready',
          created_at: '2024-05-01T02:27:30.772Z',
          time_ago: '12 days',
          renewals_approaching: [
            {
              id: '84bf158e-1ad8-4894-a601-52bc8d20e1a8',
              name: 'Security Lock',
              customer_renewal_date: '2024-07-01',
            },
            {
              id: 'a2050dcc-a61f-4777-9504-0c6a3252a729',
              name: 'Motors International',
              customer_renewal_date: '2024-07-07',
            },
          ],
        },
        {
          channel_id: '98a415a9-f27e-4b00-95cf-90be6f222c9e',
          channel_name: 'RBAC Needed in order to operationalize',
          opportunity_value: 10000000,
          opportunity_value_currency: 'USD',
          status: 'ready',
          created_at: '2024-05-01T16:49:05.104Z',
          time_ago: '11 days',
          renewals_approaching: [
            {
              id: 'a2050dcc-a61f-4777-9504-0c6a3252a729',
              name: 'Motors International',
              customer_renewal_date: '2024-07-07',
            },
          ],
        },
        {
          channel_id: '83e62cca-a4a9-4c04-b229-69354aef1b5d',
          channel_name: 'Churn Risk: Customer Needs Stopgap for Teams',
          opportunity_value: 10000000,
          opportunity_value_currency: 'USD',
          status: 'ready',
          created_at: '2023-12-10T23:13:52.603Z',
          time_ago: '5 months',
          renewals_approaching: [
            {
              id: 'a2a0b8a4-4205-43b8-8a5c-01c93650cd45',
              name: 'ACME Corp',
              customer_renewal_date: '2024-06-10',
            },
          ],
        },
        {
          channel_id: '4e1ec3c3-dfc5-435d-abca-bca4cd1d5709',
          channel_name: 'Customer Needs SSO to Work with OKTA ',
          opportunity_value: 10000000,
          opportunity_value_currency: 'USD',
          status: 'testing',
          created_at: '2022-08-26T01:05:58.970Z',
          time_ago: 'over 1 year',
          renewals_approaching: [
            {
              id: 'a2a0b8a4-4205-43b8-8a5c-01c93650cd45',
              name: 'ACME Corp',
              customer_renewal_date: '2024-06-10',
            },
          ],
        },
      ],
    };
  }
  return { entries: [] };
}

export const customSummaryDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return {
      entries: [
        {
          tag_name: 'Reporting',
          count: 33
        },
        {
          tag_name: 'Activation',
          count: 27
        },
        {
          tag_name: 'Authentication',
          count: 22
        },
        {
          tag_name: 'Data Science',
          count: 13
        },
      ],
    };
  }
  return { entries: [] };
}

export const staticSummaryDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return { quick_wins: 15, help_wanted: 20, broken_window: 14 };
  }

  return {};
}


export const requestsForMyTeamByRenewalDemoData = ({ persona }) => {
  if (!persona || persona === 'tech') {
    return {
      entries: [
        {
          channel_id: '81a4aaab-90ed-4811-9730-19a60276f405',
          channel_name: 'Reporting: User Expects Ability to Export List as CSV',
          opportunity_value: 12340000,
          opportunity_value_currency: 'USD',
          status: 'in_progress',
          created_at: '2022-08-23T23:10:24.362Z',
          time_ago: 'over 1 year',
          assets: [
            {
              id: '8323ce7a-06a7-4944-ac37-ff713ea6767c',
              name: 'automation',
              tag_type: 'asset',
              uuid: '8323ce7a-06a7-4944-ac37-ff713ea6767c',
              customer_renewal_date: null,
              disabled: null,
              customer_health_status: null,
            },
            {
              id: 'fae2dd38-bb61-4bc7-9ccc-ecabb678b99e',
              name: 'dashboard',
              tag_type: 'asset',
              uuid: 'fae2dd38-bb61-4bc7-9ccc-ecabb678b99e',
              customer_renewal_date: null,
              disabled: null,
              customer_health_status: null,
            },
          ],
          earliest_renewal_date: '2024-06-10',
          renewals_approaching: [
            {
              id: 'a2050dcc-a61f-4777-9504-0c6a3252a729',
              name: 'Security Lock',
              customer_renewal_date: '2024-07-07',
            },
            {
              id: 'a2a0b8a4-4205-43b8-8a5c-01c93650cd45',
              name: 'ACME Corp',
              customer_renewal_date: '2024-06-10',
            },
          ],
        },
        {
          channel_id: 'e3c168b3-ddbe-4fda-8af8-553f68789fa8',
          channel_name: 'Scheduled reports',
          opportunity_value: 10000000,
          opportunity_value_currency: 'USD',
          status: 'ready',
          created_at: '2024-05-01T02:27:30.772Z',
          time_ago: '12 days',
          assets: [
            {
              id: '6c2bc00f-e3f1-4c66-ac25-2bf2e1ef9f77',
              name: 'user settings',
              tag_type: 'asset',
              uuid: '6c2bc00f-e3f1-4c66-ac25-2bf2e1ef9f77',
              customer_renewal_date: null,
              disabled: null,
              customer_health_status: null,
            },
          ],
          earliest_renewal_date: '2024-07-01',
          renewals_approaching: [
            {
              id: '84bf158e-1ad8-4894-a601-52bc8d20e1a8',
              name: 'Motors International',
              customer_renewal_date: '2024-07-01',
            },
            {
              id: 'a2050dcc-a61f-4777-9504-0c6a3252a729',
              name: 'Security Lock',
              customer_renewal_date: '2024-07-07',
            },
          ],
        },
        {
          channel_id: '98a415a9-f27e-4b00-95cf-90be6f222c9e',
          channel_name: 'RBAC Needed in order to operationalize',
          opportunity_value: 10000000,
          opportunity_value_currency: 'USD',
          status: 'ready',
          created_at: '2024-05-01T16:49:05.104Z',
          time_ago: '11 days',
          assets: [
            {
              id: '6c2bc00f-e3f1-4c66-ac25-2bf2e1ef9f77',
              name: 'user settings',
              tag_type: 'asset',
              uuid: '6c2bc00f-e3f1-4c66-ac25-2bf2e1ef9f77',
              customer_renewal_date: null,
              disabled: null,
              customer_health_status: null,
            },
          ],
          earliest_renewal_date: '2024-07-07',
          renewals_approaching: [
            {
              id: 'a2050dcc-a61f-4777-9504-0c6a3252a729',
              name: 'Security Lock',
              customer_renewal_date: '2024-07-07',
            },
          ],
        },
        {
          channel_id: '4e1ec3c3-dfc5-435d-abca-bca4cd1d5709',
          channel_name: 'Customer Needs SSO to Work with OKTA ',
          opportunity_value: 10000000,
          opportunity_value_currency: 'USD',
          status: 'testing',
          created_at: '2022-08-26T01:05:58.970Z',
          time_ago: 'over 1 year',
          assets: [
            {
              id: '8323ce7a-06a7-4944-ac37-ff713ea6767c',
              name: 'automation',
              tag_type: 'asset',
              uuid: '8323ce7a-06a7-4944-ac37-ff713ea6767c',
              customer_renewal_date: null,
              disabled: null,
              customer_health_status: null,
            },
            {
              id: '0c1c39eb-e5d6-4fb8-a6a0-b2d8e07b5ef4',
              name: 'ai',
              tag_type: 'asset',
              uuid: '0c1c39eb-e5d6-4fb8-a6a0-b2d8e07b5ef4',
              customer_renewal_date: null,
              disabled: null,
              customer_health_status: null,
            },
            {
              id: '6c2bc00f-e3f1-4c66-ac25-2bf2e1ef9f77',
              name: 'user settings',
              tag_type: 'asset',
              uuid: '6c2bc00f-e3f1-4c66-ac25-2bf2e1ef9f77',
              customer_renewal_date: null,
              disabled: null,
              customer_health_status: null,
            },
          ],
          earliest_renewal_date: '2024-06-10',
          renewals_approaching: [
            {
              id: 'a2a0b8a4-4205-43b8-8a5c-01c93650cd45',
              name: 'ACME Corp',
              customer_renewal_date: '2024-06-10',
            },
          ],
        },
      ],
    }
  }
  return { entries: [] };
}
