import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import Select from 'react-select';
import {
  changeDirection,
  percentChangeFormat,
  numToLocaleString,
} from '../../components/util/helpers';

class CustomizedDot extends React.Component {
  render() {
    const { cx, cy, value, max, min } = this.props;

    if (value === max || value === min) {
      return (
        <circle
          cx={cx}
          cy={cy }
          r={2}
          stroke={minMaxColor(value, max)}
          strokeWidth={2}
          fill={minMaxColor(value, max)}
        />
      );
    }
    return false;
  }
};

class VolumeStatsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { volumeData, selectedTimeParameter, title } = this.props;

    return (
      <div className="card">
        <div className="header call-stats">
          <div className="title">{title}</div>
        </div>
        <div className="content call-stats">
          <div className="call-count">{numToLocaleString(volumeData.total_volume)}</div>
          <div className={changeClassName(volumeData.total_volume_percent_change)}>
            {percentChangeFormat(volumeData.total_volume_percent_change)}
          </div>
          <LineChart
            width={300}
            height={100}
            data={volumeData.total_volume_history}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            <Line
              type="monotone"
              dataKey="count"
              stroke="#82ca9d"
              dot={<CustomizedDot max={volumeData.max_count} min={volumeData.min_count}/>}
            />
            <ReferenceLine y={4000} label="Average" stroke="#ccc" strokeDasharray="3 3" />
          </LineChart>
        </div>
        {volumeData.volume_data_by_country && (
          <Fragment>
            <div className="header call-stats">
              <div className="title">Most Volume by Country</div>
            </div>
            <div className="content call-volume-by-country">
              <table>
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>Volume</th>
                    <th>LatestWeek Volume</th>
                    <th>PriorWeek Volume</th>
                    <th>Change</th>
                    <th>% Change</th>
                    <th>{`Volume Trend ${selectedTimeParameter.label}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {volumeData.volume_data_by_country.map(entry =>
                    <tr key={entry.country.name}>
                      <td>
                        <div className="country-name">
                          <div className={`flag-icon flag-icon-${formattedCountryCode(entry.country.code)}`}></div>
                          <div>{entry.country.name}</div>
                        </div>
                      </td>
                      <td>{numToLocaleString(entry.volume_count)}</td>
                      <td>{numToLocaleString(entry.current_week)}</td>
                      <td>{numToLocaleString(entry.previous_week)}</td>
                      <td>{numToLocaleString(entry.current_week - (entry.previous_week || 0))}</td>
                      <td className={changeClassName(entry.percent_change)}>
                        {percentChangeFormat(entry.percent_change)}
                      </td>
                      <td className="country-volume-history">
                        <LineChart
                          width={200}
                          height={50}
                          data={entry.volume_history}
                          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        >
                          <Line
                            type="linear"
                            dataKey="count"
                            stroke="#82ca9d"
                            dot={<CustomizedDot max={entry.max_count} min={entry.min_count}/>}
                          />
                          <ReferenceLine label="Average" stroke="#ccc" strokeDasharray="3 3" />
                        </LineChart>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

VolumeStatsCard.propTypes = {
};

export default VolumeStatsCard;

const minMaxColor = (value, max) =>
  value === max ? 'green' : 'red';

const changeClassName = percentChange =>
  `percent-change ${changeDirection(percentChange)}`;

const formattedCountryCode = code =>
  (code || '').toLowerCase();
