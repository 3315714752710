export async function login({ user }) {
  const url = '/api/users/sessions'

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    body: JSON.stringify({ user }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export function logoutCurrentUser({ user }) {
  const url = `/api/users/sessions/${user.id}`

  fetch(url, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    window.location.href = data.location;
  });
}
