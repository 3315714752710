import React from 'react';
import BaseSelector from '../base_selector';
import BaseRadioButtons from '../base_radio_buttons';


const options = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false,
  }
];

// const QuickWinSelector = ({
//   onChange,
//   selectedOption,
//   defaultValue,
//   name,
//   className,
//   styleOptions,
// }) => {
//   return (
//     <BaseSelector
//       name={name}
//       options={options}
//       defaultValue={defaultValue}
//       value={selectedOption}
//       onChange={onChange}
//       className={className}
//       styleOptions={styleOptions}
//     />
//   );
// };

const QuickWinSelector = ({
  onChange,
  defaultValue,
  name,
  className,
  styleOptions,
}) => {
  return (
    <BaseRadioButtons
      name={name}
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      className={className}
      styleOptions={styleOptions}
    />
  );
};

export default QuickWinSelector;
