import {connect} from 'react-redux';
import HorizontalNavigation from './horizontal_navigation';
import withRouter from '../../../util/with_router';
import {
  createWorkspace,
  fetchWorkspaces,
} from '../../../actions/workspace_actions';

import { openModal } from '../../../actions/modal_actions';
import {
  canHaveMultipleWorkspaces,
  featuresEnabled,
  isCurrentUserAccountAdmin,
  isCurrentUserPlatformAdmin,
  isCurrentUserWorkspaceManager,
  isEducationOrganizationPersona,
  isEnterpriseAccount,
} from '../../../reducers/selectors';
import { getCookieByName } from '../../util/helpers';

const msp = (state, ownProps) => {
  const { entities } = state;
  const { match } = ownProps;

  return {
    currentUser: entities.currentUser,
    workspaceId: match?.params.workspaceId,
    workspaces: entities.workspaces,
    isCurrentUserAccountAdmin: isCurrentUserAccountAdmin({ entities }),
    isCurrentUserWorkspaceManager: isCurrentUserWorkspaceManager({ entities }),
    isCurrentUserPlatformAdmin: isCurrentUserPlatformAdmin({ entities }),
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
    isEnterpriseAccount: isEnterpriseAccount({ entities }),
    canHaveMultipleWorkspaces: canHaveMultipleWorkspaces({ entities }),
    defaultWorkspaceId: entities.organization?.default_workspace_id,
    featuresEnabled: featuresEnabled({ entities }),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    openModal: (modal, props) => dispatch(openModal(modal, props)),
    fetchWorkspaces: () => dispatch(fetchWorkspaces({ organizationId })),
  };
};

export default withRouter(connect(msp, mdp)(HorizontalNavigation));
