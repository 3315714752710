import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class PlanIsNotConfirmedModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleContactUs, subject, onClose } = this.props;

    return (
      <div className="confirm-modal choose-plan error custom-ui">
        <div className="mb-5">
          <i className="bi bi-x-circle"></i>
        </div>
        <div className="header mb-4">
          Account Type Error!
        </div>
        <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
          <div className="subtitle confirmed-plan">
            <div>
              There was an error updating your account to match the successfully
              purchased chosen plan. Please refresh the page to try again. If
              you see this error again, please contact us. A notification has
              been sent to our support team who will be in contact with you.
            </div>
            <div className="mt-3">Thank you for your patience!</div>
          </div>
        </div>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-contact-us-button"
            onClick={() => {
              handleContactUs(subject);
              onClose();
            }}
          >
            Contact Us
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            Back to plans
          </button>
        </div>
      </div>
    );
  }
}

PlanIsNotConfirmedModal.propTypes = {
  handleContactUs: PropTypes.func,
  subject: PropTypes.string,
  onClose: PropTypes.func,
};

export default PlanIsNotConfirmedModal;
