export const RECEIVE_USERS = 'RECEIVE_USERS';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_CURRENT_USER_INVITATIONS = 'RECEIVE_CURRENT_USER_INVITATIONS';
import * as userAPI from '../services/user_api';

export const receiveUsers = (users) => {
  return {
    type: RECEIVE_USERS,
    users
  };
};

export const receiveCurrentUser = user => {
  return {
    type: RECEIVE_CURRENT_USER,
    user
  };
};

export const receiveCurrentUserInvitations = invitation => {
  return {
    type: RECEIVE_CURRENT_USER_INVITATIONS,
    invitation
  };
};

export const fetchUsers = ({ organizationId }) => (dispatch) => {
  return userAPI.fetchUsers({ organizationId }).then(res => dispatch(receiveUsers(res)));
};

export const fetchCurrentUser = ({ organizationId }) => (dispatch) => {
  return userAPI.getCurrentUser({ organizationId }).then(res =>
    dispatch(receiveCurrentUser(res)),
  );
};

export const fetchCurrentUserInvitations = ({ organizationId, id, page, pageSize }) => (dispatch) => {
  return userAPI.getCurrentUserInvitations({ organizationId, id, page, pageSize }).then(res =>
    dispatch(receiveCurrentUserInvitations(res)),
  );
};
