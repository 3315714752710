import React from 'react';
import Select from 'react-select';

const Selector = props => <Select aria-label="Select" {...props} />;

const BaseSelector = ({
  onChange,
  options,
  selectedOption,
  defaultValue,
  name,
  className,
  isClearable = true,
  styleOptions = {},
}) => {
  const defaultOption = options.filter(
    option => option.value === defaultValue,
  )[0]

  return (
    <Selector
      name={name}
      options={options}
      defaultValue={defaultOption}
      value={selectedOption}
      onChange={onChange}
      className={className}
      isClearable={isClearable}
      styles={{
        control: (provided, state) => ({
          ...provided,
          // boxShadow: 'none',
          //border: !state.isFocused && 'none',
          borderColor: '#a6a6a6',
          '&:hover': {
            backgroundColor: state.isFocused ? 'none' : '#e6e6e6',
          }
        }),
        option: (provided, state) => ({
           ...provided,
           backgroundColor: state.isFocused && 'white',
           color: 'black'
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          // display: !state.isFocused && 'none',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          // display: !state.isFocused && 'none',
        }),
        ...styleOptions,
      }}
    />
  );
};

export default BaseSelector;
