import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class AboutPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.props;
    return (
      <div className="about-page-drawer channels">
        <div className="content-container">
          <div className="header">
            About this page
          </div>
          <div className="content">
            <div className="section">
              <div className="subheader">Purpose:</div>
              <div className="section-content">
                Manage workspaces. Workspaces represent your organization.
                For most organizations, a single workspace will suffice. But for
                organizations with many autonomous divisions, such as
                subsidiaries, we recommend upgrading to create multiple
                workspaces that represent your complex structure.
              </div>
            </div>
            <div className="section">
              <div className="subheader">To get started:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Create a workspace
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Add workspace information
                      <ul>
                        <li className="list-item first-sublist-item">
                          We recommend naming your workspace after your
                          organization, subsidiary, or division.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div className="underline subsection-header mb-2">
                  Add members to workspaces to grant them Customer Requests access
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Click into the workspace
                    </li>
                    <li className="list-item">
                      Enter the email of team member to add them as a member.
                      <ul>
                        <li className="list-item first-sublist-item">
                          Team members must already have a user account. Be
                          sure to add them in users
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutPageDrawer;
