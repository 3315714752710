import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import BeeImage from 'images/bee.png';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currentLocale, currentUser } = this.props;
    return (
      <div className='main contactimmed-main'>
        <HorizontalNavigation
          currentUser={currentUser}
          currentLocale={currentLocale}
        />
        <div className="error-404-page">
          <h1 className="title">404 - Page not found</h1>
          <div className="subtitle">
            <h1>Uh oh.</h1>
          </div>
          <div className="container">
            <div className="message">
              <p>
                The page you requested could not be found. Is there any chance
                you were looking for our home page?
              </p>
              <div className="return-to">
                <p><a href={`/#/${currentLocale}`}>Home Page</a></p>
              </div>
              <p>
                You can also check the <span className="nav-bar">Navigation Bar</span>
                {' '} and filter some items by keyword to quickly find what you
                were looking for.
              </p>
            </div>
            <div className="image">
              <img
                src={BeeImage}
                alt="ContactImmed Not Found"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NotFound.propTypes = {};

export default NotFound;
