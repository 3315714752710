import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class ConfirmSwapPlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      handleChoosePlan,
      onClose,
      planToChoose,
    } = this.props;

    return (
      <div className="confirm-modal choose-plan custom-ui">
        <div className="mb-5">
          <i className="bi bi-arrow-left-right"></i>
        </div>
        <div className="header mb-4">
          Swap Subscription?
        </div>
        <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
          <div className="subtitle">
            <div>Are you sure you want to swap your subscription?</div>
          </div>
        </div>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-contact-us-button"
            onClick={() => {
              handleChoosePlan(planToChoose);
              onClose();
            }}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            Back to plans
          </button>
        </div>
      </div>
    );
  }
}

ConfirmSwapPlanModal.propTypes = {
  handleChoosePlan: PropTypes.func,
  planToChoose: PropTypes.string,
  onClose: PropTypes.func,
};

export default ConfirmSwapPlanModal;
