import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class WizardTeamCreationStepHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isEducationOrganizationPersona } = this.props;

    if (isEducationOrganizationPersona) {
      return (
        <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
          Teams are where users are grouped within a workspace. Members can be
          added to teams based on their areas of responsibility and departments.
          For example, there can be teams for custodial staff, food servivce,
          and the library.
        </div>
      );
    }

    return (
      <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
        Teams are where users can be grouped together. Members can be added to
        teams based on their areas of responsibility and the parts of the
        product they oversee.
      </div>
    );
  }
}

WizardTeamCreationStepHeader.propTypes = {
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
};

export default WizardTeamCreationStepHeader;
