import React from 'react';
import BaseSelector from '../base_selector';


const options = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false,
  }
];

const HelpWantedSelector = ({
  onChange,
  selectedOption,
  defaultValue,
  name,
  className,
  styleOptions,
}) => {
  return (
    <BaseSelector
      name={name}
      options={options}
      defaultValue={defaultValue}
      value={selectedOption}
      onChange={onChange}
      className={className}
      styleOptions={styleOptions}
    />
  );
};

export default HelpWantedSelector;
