import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchCurrentUserInvitations } from '../../../actions/user_actions';

const msp = (state) => {
  return {
    currentUser: state.entities.currentUser,
    invitations: state.entities.currentUser.invitations.entries || [],
    pages: state.entities.currentUser.invitations.pages || 1,
    invitationsCount: state.entities.currentUser.invitations.count || 0,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const { id } = currentUser;
  const organizationId = currentUser?.organization_id;

  return {
    fetchInvitations: ({ page, pageSize, search }) =>
      dispatch(fetchCurrentUserInvitations({
        organizationId,
        id,
        page,
        pageSize
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
