import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Breadcrumb from '../../../components/common/Breadcrumb';
import AddUserPane from './AddUserPane';
import { removeUser, getOrganizationUsers } from '../../../services/organization';

import {
  debounce,
  permittedRolesList,
  toastifyConfiguration,
} from '../../../components/util/helpers';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

const breadcrumbItems = ({ currentLocale, organizationId }) => [
  {
    label: 'Organizations',
    path: `/#/${currentLocale}/intranet/organizations`,
    active: false,
  },
  {
    label: 'Organizations users',
    path: `/#/${currentLocale}/intranet/organizations/${organizationId}`,
    active: true,
  }
];

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: props.organizationUsers?.total_pages || 0,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedItem: {},
      search: '',
      items: props.organizationUsers?.entries || [],
      itemsCount: props.organizationsUsers?.total_count || 0,
    };
    this.handleOnClickAddItem = this.handleOnClickAddItem.bind(this);
    this.onAddItemPaneClose = this.onAddItemPaneClose.bind(this);
    this.handleOnClickEditItem = this.handleOnClickEditItem.bind(this);
    this.handleOnClickRemoveItem = this.handleOnClickRemoveItem.bind(this);
    this.handleItemsSearch = this.handleItemsSearch.bind(this);
    this.handleOrganizationsFilterFetch = this.handleOrganizationsFilterFetch.bind(this);
    this.handleGetPaginatedItems = this.handleGetPaginatedItems.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
    const { organizationId } = this.props;
    const response =  await getOrganizationUsers({ organizationId });

    if (response) {
      this.setState({ items: response.entries, pages: response.total_pages, totalCount: response.total_count, loading: false })
    }
  }

  handleItemsSearch = event => {
    const input = event.target.value
    this.handleOrganizationsFilterFetch(input);
  }

  handleOrganizationsFilterFetch = async input => {
    event && event.preventDefault();
    const { organizationId } = this.props;

    const filteredOrganizationUsers = await getOrganizationUsers({
      organizationId,
      search: input,
    });

    if (filteredOrganizationUsers) {
      this.setState({ items: filteredOrganizationUsers.entries || [], search: input });
    }
  }

  handleGetPaginatedItems = async (page, pageSize) => {
    event && event.preventDefault();
    const { search } = this.state;
    const { organizationId } = this.props;

    const response = await getOrganizationUsers({
      organizationId,
      page: page + 1,
      page_size: pageSize,
      search,
    });

    response
      ? this.setState({ items: response.entries, pages: response.total_pages, itemsCount: response.total_count, loading: false })
      : this.setState({ items: [], pages: 0, loading: false });
  }

  handleOnClickAddItem = () => {
    this.setState({ isPaneOpen: true, isEditMode: false });
  }

  onAddItemPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditItem = organization => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedItem: organization });
  }

  handleConfirmDelete = async (organization, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveItem}
            subtitle="You want to delete this organization?"
            itemToRemove={organization}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveItem = async (user, event) => {
    event && event.preventDefault();
    const { organizationId } = this.props;

    const response = await removeUser({
      organization_id: organizationId,
      user_id: user.id,

    });

    if (!(response || {}).error) {
      await this.handleGetPaginatedItems(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('User removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the user. Please try again.', toastifyConfiguration({}));
    }
  }

  render() {
    const { currentLocale, organizationId } = this.props;
    const { isAddMode, isEditMode, selectedItem, items, itemsCount } = this.state;
    const columns = [
      {
        id: 'user',
        Header: 'User',
        sortable: false,
        accessor: u => u.first_name ? `${u.first_name} ${u.last_name}` : '',
      }, {
        accessor: 'email',
        Header: 'Email',
        sortable: false,
      }, {
        accessor: 'job_title',
        Header: 'Job title',
        sortable: false,
      }, {
        id: 'last_sign_in_at',
        Header: 'Last activity',
        sortable: false,
        accessor: u =>
          <span className='user-last-login'>{u.last_sign_in_at_was ? moment(u.last_sign_in_at_was).utc().format('YYYY-MM-DD, h:mm:ss a') : 'no activity'}</span> // Custom cell components!
      }, {
        id: 'created_at',
        Header: 'Created at',
        sortable: false,
        accessor: u =>
          <span className='user-created-at'>{moment(u.created_at).utc().format('YYYY-MM-DD')}</span>
      }, {
        id: 'roles',
        Header: 'Roles',
        sortable: false,
        accessor: u => <span className='user-roles'>{u.roles.map(r => r.name).join(', ')}</span>
      }, {
        id: 'edit',
        Header: '',
        sortable: false,
        accessor: u => <span className='edit-organization' onClick={this.handleOnClickEditItem.bind(this, u)}>edit</span>,
      },
      {
        id: 'delete',
        Header: '',
        sortable: false,
        accessor: u => <span className='remove-organization' onClick={this.handleConfirmDelete.bind(this, u)}>delete</span>,
      }
    ];

    return (
      <div className="organizations intranet" ref={ref => this.el = ref}>
        <div className="container-fluid px-0">
          <Breadcrumb
            breadcrumbItems={breadcrumbItems({ currentLocale, organizationId })}
          />
          <div className="header">
            <div className="left-float">
              <div className="organization-count">{pluralize('Total organization user', itemsCount, true)}</div>
              <div className="organization-search">
                <input
                  type="text"
                  onChange={this.handleItemsSearch}
                  placeholder="search organization users" />
              </div>
            </div>
            <div className="right-float">
              <div className="add-organization">
                <Button handleClick={this.handleOnClickAddItem} label="Add Organization User" classes="primary small" />
              </div>
            </div>
          </div>
          <ReactTable
            data={items}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            loading={this.state.loading}
            pages={this.state.pages}
            columns={columns}
            manual
            onFetchData={(state, instance) => {
              this.setState({ loading: true });
              this.handleGetPaginatedItems(state.page, state.pageSize);
              // this.handleGetPaginatedItems(state.page, state.pageSize, state.sorted, state.filtered)
            }}
          />
          <SlidingPane
            className='add-organization-pane'
            overlayClassName='sliding-pane-overlay'
            isOpen={ this.state.isPaneOpen }
            title={isEditMode ? 'Edit Organization': 'Add New Organization'}
            width='60%'
            subtitle=''
            onRequestClose={this.onAddItemPaneClose}>
              <AddUserPane
                handleGetPaginatedUsers={this.handleGetPaginatedItems}
                onAddUserPaneClose={this.onAddItemPaneClose}
                isEditMode={isEditMode}
                selectedItem={selectedItem}
                organizationId={organizationId}
                currentLocale={currentLocale}
                permittedRoles={permittedRolesList}
                // For now, ContactImmed admin shouldn't manage a customer's
                // workspaces
                workspacesAvailable={[]}
                canAddWorkspaces={false}
              />
          </SlidingPane>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
