import {
  RECEIVE_WORKSPACES,
  RECEIVE_WORKSPACE,
} from '../actions/workspace_actions';

const workspaceReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_WORKSPACES:
      return action.workspaces;
    case RECEIVE_WORKSPACE:
      return [
        ...state,
        {...action.payload.workspace }
      ];
    default:
      return state;
  };
};

export default workspaceReducer;
