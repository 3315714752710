import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';

const msp = (state, ownProps) => {
  const { entities } = state;

  return {
    currentUser: entities.currentUser,
    organizationId: ownProps.match.params.organizationId,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  // const organizationId = currentUser?.organization_id;

  return {};
};

export default withRouter(connect(msp, mdp)(Content));
