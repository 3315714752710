import { connect } from 'react-redux';
import React from 'react';
import { openModal, closeModal } from '../../actions/modal_actions';
import WorkspaceForm from './workspace_form';
import { createWorkspace } from '../../actions/workspace_actions';

const mapStateToProps = ({errors}) => {
  return {
    errors: errors && errors.session,
    formType: 'new_workspace',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const { organization_id } = currentUser;

  return {
    processForm: ({ workspace }) => dispatch(
      createWorkspace({ organizationId: organization_id, workspace })
    ),
    closeModal: () => dispatch(closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceForm);
