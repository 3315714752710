import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class PlanCanceledSuccessfullyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleContactUs, response, onClose } = this.props;

    const header = response?.plan_already_canceled
      ? 'This plan has already been canceled. Please contact us.'
      : response?.plan_subscription_does_not_exist
      ? `We've detected the subscription does not exist. Please contact us.`
      : response?.plan_canceled_successfully
      ? 'This plan was canceled successfully.'
      : 'There seems to be an issue canceling this plan. Please contact us.';

    return (
      <div className="confirm-modal choose-plan custom-ui">
        <div className="mb-5">
          <i className="bi bi-x-circle initial"></i>
        </div>
        <div className="header mb-4">
          Plan Cancelation
        </div>
        <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
          <div className="subtitle cancel-enterprise-plan">
            <div>{header}</div>
          </div>
        </div>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-contact-us-button"
            onClick={() => {
              handleContactUs(header);
              onClose();
            }}
          >
            Contact Us
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            Back to plans
          </button>
        </div>
      </div>
    );
  }
}

PlanCanceledSuccessfullyModal.propTypes = {
  handleContactUs: PropTypes.func,
  onClose: PropTypes.func,
};

export default PlanCanceledSuccessfullyModal;
