import { connect } from 'react-redux';
import AccessManagement from './access_management';
import withRouter from '../../../util/with_router';

const msp = (state, ownProps) => {
  return {
    currentUser: state.entities.currentUser,
  };
};

const mdp = (dispatch, ownProps) => {
  return {};
};

export default withRouter(connect(msp, mdp)(AccessManagement));
