import React from 'react';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';
import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class PricingPlans extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUser, currentUserOrganization, currentLocale } = this.props;

    return (
      <div className="main contactimmed-main">
        <HorizontalNavigation
          currentUser={currentUser}
          currentLocale={currentLocale}
        />
        <ContentContainer
          currentLocale={currentLocale}
          currentUser={currentUser}
          currentUserOrganization={currentUserOrganization}
        />
      </div>
    );
  }
}

export default withRouter(PricingPlans);
