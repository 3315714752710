import React from 'react';
import withRouter from '../../util/with_router';

class WorkspaceForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newWorkspaceName: '',
    };
    this.handleCreateNewWorkspace = this.handleCreateNewWorkspace.bind(this);
  }

  handleCreateNewWorkspace(e) {
    const workspace = {
      name: this.state.newWorkspaceName,
    };

    this.props.processForm({ workspace });
    this.props.closeModal();
  }

  update(field) {
    return (e) => this.setState({
      [field]: e.target.value
    });
  };

  render() {
    const { isEditMode, onClose } = this.props;

    return (
      <div className="confirm-modal custom-ui create-workspace-form">
        {isEditMode ? (
          <div className="form-header header mb-4">
            Edit the <span className="emphasis">Workspace</span>
          </div>
        ) : (
          <div className="form-header header mb-4">
            Create a new <span className="emphasis">Workspace</span>
          </div>
        )}
        <form onSubmit={this.handleCreateNewWorkspace}>
          <input
            type="text"
            value={this.state.newWorkspaceName}
            onChange={this.update('newWorkspaceName')}
            placeholder='# e.g. Company Workspace'/>
          <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
            <input
              type="submit"
              value="Create"
              className="py-3 mb-3 confirm-submit-button"
            />
            <button
              type="button"
              className="btn py-3 close-button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(WorkspaceForm);
