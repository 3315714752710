import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      icon,
      label,
      handleClick,
      style,
      disabled = false,
      type = 'button',
    } = this.props;
    const className = `button ${classes}`

    return (
      <button
        type={type}
        className={className}
        onClick={handleClick}
        style={style}
        disabled={disabled}
      >
        {icon} {label}
      </button>
    );
  }
}

Button.propTypes = {
};

Button.defaultProps = {
 type: 'secondary'
};

export default Button;
