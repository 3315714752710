import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


class ConfirmDeleteMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      handleRemoveItem,
      onClose,
      itemToRemove,
      itemsToRemove,
      isMultipleDeletion,
      event,
      subtitle,
      confirmButtonText,
    } = this.props;
    return (
      <div className="confirm-modal confirm-delete-message custom-ui">
        <div className="mb-5">
          <i className="bi bi-x-circle"></i>
        </div>
        <div className="header mb-4">
          Are you sure?
        </div>
        {subtitle && (
          <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
            <div className="subtitle">
              {subtitle}
            </div>
          </div>
        )}
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-delete-button"
            onClick={() => {
              if (isMultipleDeletion) {
                handleRemoveItem(itemsToRemove, event)
              } else {
                handleRemoveItem(itemToRemove, event);
              }
              onClose();
            }}
          >
            {confirmButtonText}
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    );
  }
}

ConfirmDeleteMessage.propTypes = {
  handleRemoveItem: PropTypes.func,
  itemToRemove: PropTypes.shape({}),
  itemsToRemove: PropTypes.arrayOf(PropTypes.string),
  isMultipleDeletion: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  event: PropTypes.shape({}),
  onClose: PropTypes.func,
};

ConfirmDeleteMessage.defaultProps = {
 confirmButtonText: 'Yes',
 isMultipleDeletion: false,
};

export default ConfirmDeleteMessage;
