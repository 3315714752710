export async function fetchTwoFactorSettingsData() {
  const url = `/settings/two_factor_settings/new.json`

  return $.ajax({
    url: url,
  });
}

export async function fetchTwoFactorSettingsBackupCodes() {
  const url = `/settings/two_factor_settings/edit.json`

  return $.ajax({
    url: url,
  });
}

export async function enableTwoFactorAuth({ code, password }) {
  const url = `/settings/two_factor_settings.json`

  return $.ajax({
    url,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    data: { two_fa: { code, password } }
  });
}
