import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import {
  ellipsis,
  numToLocaleString,
  statusBadge,
  statusMapping,
} from '../../../components/util/helpers';
import currencyCodesHash from '../../../components/util/currency_codes_hash.json';
import { fetchWorkspaceOpportunities } from '../../../services/stats_api';
import { customerRequestsByDealValue } from '../util/demo_data';

class OpportunityValueBySizeCard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      opportunities: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    const response = useDemoData
      ? customerRequestsByDealValue({ persona: 'tech' })
      : await fetchWorkspaceOpportunities({
          workspaceId,
          organizationId,
        });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, opportunities: response.entries })
      } else {
        this.setState({ isLoading: false, isError: true });
      }

    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentLocale,
      currentUser,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      useDemoData,
      workspaceId,
    } = this.props;

    const WIDGET_TITLE = 'Customer requests by biggest deal value';
    const { opportunities, isLoading } = this.state;
    const data = opportunities;
    const isEmptyState = opportunities && !opportunities.length;

    if (isLoading) {
      return (
        <Card className="opportunity-value-by-size-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="opportunity-value-by-size-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState
                message="No active customer requests with deal value information"
                currentLocale={currentLocale}
              />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="opportunity-value-by-size-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="column-header ps-4 pt-4">
                      Deal value
                    </TableCell>
                    <TableCell className="column-header ps-4 pt-4">
                      Status
                    </TableCell>
                    <TableCell className="column-header pe-4 pt-4">Request</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow
                      key={row.channel_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        className="opportunity-value-amount dashboard-table-cell ps-4 py-3"
                      >
                        {
                          row.opportunity_value &&
                          `${handleCurrencySymbol(row.opportunity_value_currency)}${numToLocaleString(row.opportunity_value)}`
                        }
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-status ps-3 py-3">
                        <div className={`status pill badge ${statusBadge(row.status)} d-flex justify-content-center align-items-center`}>
                          {statusMapping(row.status)}
                        </div>
                      </TableCell>
                      <TableCell className="dashboard-table-cell channel-path ps-3 pe-4 py-3">
                        {useDemoData ? (
                          <abbr title={row.channel_name}>
                            {
                              row.channel_name &&
                              `${ellipsis({ string: row.channel_name, maxLength: 100 })}`
                            }
                          </abbr>
                        ) : (
                          <a
                            href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${row.channel_id}`}
                            target="_blank"
                          >
                            <abbr title={row.channel_name}>
                              {
                                row.channel_name &&
                                `${ellipsis({ string: row.channel_name, maxLength: 100 })}`
                              }
                            </abbr>
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

OpportunityValueBySizeCard.propTypes = {};

export default OpportunityValueBySizeCard;

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Requests by customer value:
    </p>
    <p className="tooltip-paragraph">
      By using the <b>opportunity value</b> field when managing a request, you
      let delivery teams know how much deal value a request has. Allow delivery
      teams to support sales in closing and keeping deals.
    </p>
    <p className="tooltip-paragraph">
      The greater the opportunity value, the more likely it is to get addressed.
      But be careful not to inflate value. One billion dollars for one request
      may be hard for your teammates to believe 😉.
    </p>
  </div>
);

const handleCurrencySymbol = currency =>
  currency ? currencyCodesHash[currency] : '';
