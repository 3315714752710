import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchOrganizationTeam } from '../../../actions/organization_actions';

const msp = (state, ownProps) => {
  return {
    currentUser: state.entities.currentUser,
    team: state.entities.organization.team,
    teamId: ownProps.match.params.teamId,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchTeam: ({ teamId }) =>
      dispatch(fetchOrganizationTeam({ organizationId, teamId })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
