import { paramsToQueryString } from '../services/util/helpers';

export async function goToCheckout({
  organizationId,
  product,
  planPeriod,
}) {
  const url = `/api/organizations/${organizationId}/payments`;

  return $.ajax({
    url: url,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({ payment: { product, plan_period: planPeriod } }),
  });
}

export async function handleSuccessfulPayment({ organizationId }) {
  const url =
    `/api/organizations/${organizationId}/payments/handle_successful_payment`;

  return $.ajax({
    url: url,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'PUT',
    contentType: 'application/json',
  });
}

export async function cancelSubscription({
  organizationId,
  product,
}) {
  const url = `/api/organizations/${organizationId}/payments/${product}`;

  return $.ajax({
    url: url,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'DELETE',
    contentType: 'application/json',
  });
}

export async function swapSubscription({
  organizationId,
  product,
  planPeriod,
}) {
  const url = `/api/organizations/${organizationId}/payments/${product}`;

  return $.ajax({
    url: url,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'PUT',
    contentType: 'application/json',
    data: JSON.stringify({ payment: { product, plan_period: planPeriod } }),
  });
}


export async function contactUsForEnterprise({
  organizationId,
  inquirySubject,
}) {
  const url = `/api/organizations/${organizationId}/payments/contact_us_for_enterprise_plan`;

  return $.ajax({
    url: url,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({ payment: { inquiry_subject: inquirySubject } }),
  });
}

export async function contactUs({
  organizationId,
  inquirySubject,
}) {
  const url = `/api/organizations/${organizationId}/payments/contact_us`;

  return $.ajax({
    url: url,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({ payment: { inquiry_subject: inquirySubject } }),
  });
}
