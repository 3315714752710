import React from 'react';

import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class Users extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUser, permittedRoles, currentLocale } = this.props;

    return (
      <ContentContainer
        currentUser={currentUser}
        organizationUsers={[]}
        organizationTeams={[]}
        permittedRoles={permittedRoles}
        currentLocale={currentLocale}
      />
    );
  }
}

export default withRouter(Users);
