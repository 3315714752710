import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchOrganizationCustomerTags } from '../../../actions/organization_actions';
import { isCurrentUserAccountAdmin, isCurrentUserWorkspaceManager } from '../../../reducers/selectors';

const msp = (state, ownProps) => {
  const { entities } = state;
  return {
    currentUser: entities.currentUser,
    tags: entities.organization.customerTags,
    pages: entities.organization.pages || 1,
    tagsCount: entities.organization.customerTagsCount || 0,
    isCurrentUserAccountAdmin: isCurrentUserAccountAdmin({ entities }),
    isCurrentUserWorkspaceManager: isCurrentUserWorkspaceManager({ entities }),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchCustomerTags: ({ page, pageSize, search, sort }) =>
      dispatch(fetchOrganizationCustomerTags({ organizationId, page, pageSize, search, sort })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
