import {
  RECEIVE_CHANNEL_FAVORITES,
} from '../actions/channel_actions';

const channelFavoritesReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_CHANNEL_FAVORITES:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  };
};

export default channelFavoritesReducer;
