import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';

import AddCustomerTagPane from '../../../components/pane/AddCustomerTagPane';
import {
  getCustomerTagsCsv,
  removeTag,
  removeTags,
  updateTag,
} from '../../../services/organization_api';
import {
  sleep,
  statusBadge,
  statusMapping,
  toastifyConfiguration,
} from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import AboutPageDrawer from '../../AboutDrawers/CustomerTagsSettingsPage/AboutPageDrawer';
import TableComponent from '../../../components/common/TableComponent';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedTag: {},
      search: '',
      tags: [],
      tagsCount: 0,
      isAboutPageDrawerOpen: false,
      isCsvActionInProgress: false,
      selection: [],
    };
    this.handleOnClickAddCustomerTag = this.handleOnClickAddCustomerTag.bind(this);
    this.onAddCustomerTagPaneClose = this.onAddCustomerTagPaneClose.bind(this);
    this.handleOnClickEditCustomerTag = this.handleOnClickEditCustomerTag.bind(this);
    this.handleOnClickRemoveCustomerTag = this.handleOnClickRemoveCustomerTag.bind(this);
    this.handleCustomerTagsSearch = this.handleCustomerTagsSearch.bind(this);
    this.handleCustomerTagsFilterFetch = this.handleCustomerTagsFilterFetch.bind(this);
    this.handleGetPaginatedCustomerTags = this.handleGetPaginatedCustomerTags.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleConfirmDisable = this.handleConfirmDisable.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
    this.handleOnDisableTag = this.handleOnDisableTag.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleOnClickRemoveMultipleCustomerTags = this.handleOnClickRemoveMultpleCustomerTags.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
  }

  debounceSearch = debounce(input => this.handleCustomerTagsFilterFetch(input), 500);

  handleCustomerTagsSearch = event => {
    event && event.preventDefault();
    const { search } = this.state;
    const input = event?.target?.value;

    // Note: validate no previous "search" value exists, to enable clear search
    if (!input && !search) {
      return;
    }

    this.debounceSearch(input);
  }

  handleCustomerTagsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredCustomerTags = await this.props.fetchCustomerTags({ search: input });
    if (filteredCustomerTags) {
      this.setState({ tags: filteredCustomerTags.entries || [], search: input });
    }
  }

  handleGetPaginatedCustomerTags = async (page, pageSize, sorted) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchCustomerTags({
      page: page + 1,
      pageSize: pageSize,
      search,
      sort: sorted,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddCustomerTag = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedTag: {} });
  }

  onAddCustomerTagPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditCustomerTag = tag => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedTag: tag });
  }

  handleConfirmDelete = async (tag, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveCustomerTag}
            subtitle="You want to delete this tag?"
            itemToRemove={tag}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmDisable = async (tag, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnDisableTag}
            subtitle="You want to disable this tag?"
            itemToRemove={tag}
            event={event}
            onClose={onClose}
            confirmButtonText={'Yes, disable it!'}
          />
        );
      }
    });
  }

  handleOnClickRemoveCustomerTag = async (tag, event) => {
    const { currentUser } = this.props;
    const response = await removeTag({
      organizationId: currentUser?.organization_id,
      tagId: tag.id,
    });
    if (!(response || {}).error && !(response || {}).dependency) {
      await this.handleGetPaginatedCustomerTags(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Tag removed successfully.', toastifyConfiguration({}));
    } else if ((response || {}).dependency) {
      toast.warning(`Cannot remove tags in use by resources. Please try disabling the tag instead.`, toastifyConfiguration({}));
    } else {
      toast.error('Error removing the tag. Please try again.', toastifyConfiguration({}));
    }
  }

  handleOnClickRemoveMultpleCustomerTags = async (tagIds, event) => {
    const { currentUser } = this.props;

    const response = await removeTags({
      organizationId: currentUser?.organization_id,
      tagIds,
    });

    if (!(response || {}).error && !(response || {}).dependency) {
      await this.handleGetPaginatedCustomerTags(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success(
        'Customer tag(s) removed successfully. Tags that are in use by resources cannot be removed',
        toastifyConfiguration({}),
      );
    } else if ((response || {}).dependency) {
      toast.warning(`Cannot remove tags in use by resources. Please try disabling the tag instead.`, toastifyConfiguration({}));
    } else {
      toast.error('Error removing the tag. Please try again.', toastifyConfiguration({}));
    }
  }

  handleOnDisableTag = async (tag, event) => {
    const { currentUser } = this.props;
    const response = await updateTag({
      organizationId: currentUser?.organization_id,
      tagId: tag.id,
      disabled: true,
    });

    if (!(tag || {}).error) {
      await this.handleGetPaginatedCustomerTags(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success(`Tag, ${tag.name}, disabled successfully!`, toastifyConfiguration({}));
    } else {
      toast.error(`Error disabling tag ${tag.name}. Please try again.`, toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getCustomerTagsCsv({ organizationId: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  handleGetTableProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleGetTbodyProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleOnFetchData({ state }) {
    this.setState({ loading: true });
    this.handleGetPaginatedCustomerTags(state.page, state.pageSize, state.sorted);
  }

  handleSelection({ selection }) {
    this.setState({ selection });
  }

  render() {
    let {
      currentUser,
      currentLocale,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      pages,
      tags,
      tagsCount,
    } = this.props;

    let {
      isAddMode,
      isCsvActionInProgress,
      isEditMode,
      selectedTag,
    } = this.state;

    const columns = [{
      accessor: 'name',
      Header: 'Customer tag name',
    }, {
      id: 'created_at',
      Header: 'Created at',
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'customer_health_status',
      Header: 'Customer health status',
      accessor: t => (
        <div className={`status pill pt-2 pb-2 badge ${statusBadge(t.customer_health_status)} ${handleNeutralStatusClass(t.customer_health_status)} d-flex align-items-center justify-content-center w-75`}>
          {statusMapping(t.customer_health_status) || 'null'}
        </div>
      )
    }, {
      id: 'updated_at',
      Header: 'Updated at',
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'customer_renewal_date',
      Header: 'Customer renewal date',
      accessor: t => <span>{t.customer_renewal_date ? moment(t.customer_renewal_date).utc().format('YYYY-MM-DD') : 'N/A'}</span>
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: t => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditCustomerTag.bind(this, t)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, t)}>
            <i className="bi bi-trash3"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDisable.bind(this, t)}>
            <i className="bi bi-sign-do-not-enter"></i>
          </span>
        </div>
      )
    }];

    return (
      <div className="settings organization-workspaces customer-tags container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Settings</div>
          <div className="subheader">
            <div className="page">Customer Tags</div>
            <div
              className="pro-tip-button ms-2"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="row mb-4 resource-metadata">
            <div className="col-md-4 column-width">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="h5">Customer tags</div>
                    <div className="d-flex">
                      <i className="bi bi-person-arms-up me-1"></i>
                      <div className="card-count">{tagsCount}</div>
                    </div>
                  </div>
                  <div>
                    Customers who are opening the requests
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 add-resource-column-width customer">
              <div className="card">
                <div className="card-body d-flex justify-content-center align-items-center">
                  <div onClick={this.handleOnClickAddCustomerTag} className="add-resource-action">
                    + Add customer
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-actions-and-export d-flex justify-content-between">
            <div className="search">
              <input
                type="text"
                className="form-control search-input"
                onChange={this.handleCustomerTagsSearch}
                placeholder="Search teams"
              />
            </div>
            <div className="ms-2 button-actions">
              {isCsvActionInProgress ? (
                <button className="export export-csv" type="button" disabled>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  <span>Exporting...</span>
                </button>
              ) : (
                <Button
                  handleClick={() => {
                    this.setState({ isCsvActionInProgress: true });
                    this.handleCsvExport();
                  }}
                  label="Export CSV"
                  classes="export export-csv"
                />
              )}
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <TableComponent
              columns={columns}
              data={tags}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              deleteConfirmationSubtitle="You want to remove the customer tag(s)?"
              loading={this.state.loading}
              manual
              pages={pages}
              handleOnFetchData={this.handleOnFetchData}
              handleSelection={this.handleSelection}
              handleGetTableProps={this.handleGetTableProps}
              handleGetTbodyProps={this.handleGetTbodyProps}
              handleOnClickRemoveMultipleItems={this.handleOnClickRemoveMultpleCustomerTags}
              multipleDeletionEnabled={isCurrentUserAccountAdmin || isCurrentUserWorkspaceManager}
            />
            <SlidingPane
              className='add-tag-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isPaneOpen }
              title={isEditMode ? 'Edit customer' : 'Create new customer'}
              width='60%'
              subtitle=''
              onRequestClose={this.onAddCustomerTagPaneClose}>
                <AddCustomerTagPane
                  handleGetPaginatedCustomerTags={this.handleGetPaginatedCustomerTags}
                  currentUser={currentUser}
                  onAddCustomerTagPaneClose={this.onAddCustomerTagPaneClose}
                  isEditMode={isEditMode}
                  selectedTag={selectedTag}
                />
            </SlidingPane>
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings teams-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;

const handleNeutralStatusClass = status =>
  status === 'neutral' ? 'text-dark' : '';
