import React from "react"

import { useLocation, useParams, useNavigate } from 'react-router-dom';

const withRouter = Component => props => {
  const location = useLocation();
  const navigate = useNavigate();
  const match = { params: useParams() };

  return (
    <Component
      {...props}
      location={location}
      /*
        NOTE:
        This replaces useHistory in previous version of react-router-dom.
        Instead of history.push('/route'), do navigate('/route')
      */
      navigate={navigate}
      match={match}
    />
  );
};

export default withRouter;
