import React from 'react';
import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class TwoFactorSettingsBackupCodes extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUser, currentUserOrganization, currentLocale } = this.props;
    const { name, isSsoEnabled } = currentUserOrganization || {}

    return (
      <div className='main'>
        <ContentContainer
          currentUser={currentUser}
          currentLocale={currentLocale}
        />
      </div>
    );
  }
}

export default withRouter(TwoFactorSettingsBackupCodes);
