import { paramsToQueryString } from '../services/util/helpers';

export async function getAuditLogs({
  user,
  event_type,
  item_type,
  start_date,
  end_date,
  page,
  page_size,
  sort,
}) {
  const params = { user, event_type, item_type, start_date, end_date, page, page_size, sort };
  const url = !paramsToQueryString(params) ? 'audit_logs.json' :  `audit_logs.json?${paramsToQueryString(params)}`
  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getAuditLogsCsv({
  user,
  event_type,
  item_type,
  start_date,
  end_date,
}) {
  const params = { user, event_type, item_type, start_date, end_date };
  const url = !paramsToQueryString(params) ? '/audit_logs.csv' :  `/audit_logs.csv?${paramsToQueryString(params)}`
  const response = await fetch('/audit_logs/download', {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = url;
  });
  return response;
}
