import React from 'react';

import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class Teams extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUser, currentLocale, isAccountAdmin } = this.props;

    return (
      <ContentContainer
        currentUser={currentUser}
        currentLocale={currentLocale}
        isAccountAdmin={isAccountAdmin}
      />
    );
  }
}

export default withRouter(Teams);
