import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../common/Tooltip';


class MaterialUiTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      titleContent,
      maxwidth = 400,
    } = this.props;

    return (
      <Tooltip titleContent={titleContent} maxwidth={maxwidth}>
        <span className="icon-container">
          <i className="bi bi-question-circle"></i>
        </span>
      </Tooltip>
    );
  }
}

MaterialUiTooltip.propTypes = {};

export default MaterialUiTooltip;
